import styled from "styled-components";

export const StyledEventCalendar = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  border-collapse: collapse;

  @media print {
    break-inside: avoid;
  }
`;
