import styled from "styled-components";

import { deviceWidths, StyleFontMedium, ThemeColors } from "../../styles/theme";

export const cgHeaderHeightTablet = `121px`;
export const cgHeaderHeightDesktop = `132px`;
export const titleHeroHeightTablet = `103px`;
export const titleHeroHeightDesktop = `160px`;

export const StyledBaseLayout = styled.div`
  box-sizing: border-box;
  ${StyleFontMedium}
  color: ${ThemeColors.CGBlack};
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const StyledCGHeader = styled.div`
  background-color: ${ThemeColors.White};
  width: 100%;
  height: ${cgHeaderHeightTablet};
  display: block;
  padding: 22px 0;

  @media screen and ${deviceWidths.desktop} {
    height: ${cgHeaderHeightDesktop};
    padding: 34px 0 22px;
  }
`;

export const StyledCGHeaderInner = styled.div`
  display: grid;
  align-items: end;
`;

export const StyledTitleHero = styled.div`
  color: ${ThemeColors.White};
  background-color: ${ThemeColors.Sapphire};
  width: 100%;
  height: ${titleHeroHeightTablet};
  padding: 20px 0;

  h5 {
    text-transform: uppercase;
  }

  @media screen and ${deviceWidths.desktop} {
    height: ${titleHeroHeightDesktop};
    padding: 40px 0;
  }
`;

export const StyledTitleHeroInner = styled.div`
  display: grid;
  align-items: start;
  justify-items: start;

  @media screen and ${deviceWidths.desktop} {
    grid-template-columns: 4fr 1fr;
  }
`;

export const HeroLeft = styled.div``;
export const HeroRight = styled.div`
  @media screen and ${deviceWidths.desktop} {
    justify-self: right;
    text-align: right;
  }
`;
