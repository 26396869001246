export const STORE_KEY_ROUTE_PATH = 'CGAP_RoutePath';
export const STORE_KEY_APP_STATE = 'CGAP_AppState';


export function saveToLocalStorage(key: string, stringData: string): void {
    if(window?.localStorage) {
        window.localStorage.setItem(key, stringData);
    }
}

export function getFromLocalStorage(key: string): string | null {
    if (window?.localStorage) {
        return window.localStorage.getItem(key);
    }

    return null;
}

export function clearLocalStorage(): void {
    if (window?.localStorage) {
        window.localStorage.removeItem(STORE_KEY_ROUTE_PATH);
        window.localStorage.removeItem(STORE_KEY_APP_STATE);
    }
}


