import { CGAPConfig } from "../types/annual-planner";
import axios from "axios";
import { CGCConfig } from "../types/common";

function getNicerConfigLoadingError(reason: string) {
  const baseReason = "We couldn't load the config file";

  if (reason) {
    return baseReason + ": " + reason;
  }

  return baseReason;
}

export function loadConfigJson(
  filename: string
): Promise<CGAPConfig | CGCConfig> {
  return axios.get(`${window.location.href}/${filename}.json`).then(
    (response) => response.data,
    (error) => {
      // Attempt to make a useful error message
      if (error.response) {
        switch (error.response.status) {
          case 401:
          case 403:
            throw getNicerConfigLoadingError(
              "The server would not allow us the config file."
            );
          case 402:
            throw getNicerConfigLoadingError(
              "The server wants money for the config file."
            );
          case 404:
            throw getNicerConfigLoadingError(
              "The server could not find the config file, please check the developer console for path."
            );
        }
      }

      return getNicerConfigLoadingError(
        "The server had a problem giving us the config file, please check the developer console."
      );
    }
  );
}

export function loadConfig(): Promise<CGAPConfig> {
  return new Promise((resolve, reject) => {
    let config: CGAPConfig;

    loadConfigJson("config-annual_planner").then(
      (apConfig) => {
        config = apConfig as CGAPConfig;

        loadConfigJson("config-common").then(
          (commonConfig) => {
            config.common = commonConfig as CGCConfig;
            resolve(config);
          },
          (err) => reject(err)
        );
      },
      (err) => reject(err)
    );
  });
}
