import { FormatNumberOptions } from "react-intl";

export const percentOptionFraction: FormatNumberOptions = {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

export const percentOptionWhole: FormatNumberOptions = {
    style: "percent",
    maximumFractionDigits: 0,
};

export const currencyOption: FormatNumberOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export const currencyOptionWithCents: FormatNumberOptions = {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
};

export const percentFractionFormatter = new Intl.NumberFormat("en-US", percentOptionFraction);
export const percentWholeFormatter = new Intl.NumberFormat("en-US", percentOptionWhole);
export const currencyFormatter = new Intl.NumberFormat("en-US", currencyOption);
export const currencyFormatterWithCents = new Intl.NumberFormat("en-US", currencyOptionWithCents);
export const numberFormatter = new Intl.NumberFormat("en-US");

