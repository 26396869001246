import {CGAPClientGroupOption, CGAPPlanOptions} from "../../../../types/annual-planner";

export enum EventTypesEnabledOption {
    none = "",
    hosted = "hosted",
    sponsored = "sponsored",
    both = "both"
}

export const disabledInputsInitialState = {
    groupSegment: true,
    eventType: true,
    budget: true,
    previousYear: true
}

export enum PlanStateActionType {
    SET_BUDGET = 'SET_BUDGET',
    SET_CLIENT_GROUP_OPTIONS = 'SET_CLIENT_GROUP_OPTIONS',
    SET_EVENT_TYPES_ENABLED = 'SET_EVENT_TYPES_ENABLED',
    SET_LOADING = 'SET_LOADING',
}

export interface SetClientGroupAction {
    type: typeof PlanStateActionType.SET_CLIENT_GROUP_OPTIONS;
    payload: {
        clientGroup: CGAPClientGroupOption;
        selectedGroupOptionValue: string;
    };
}

export interface SetBudgetAction {
    type: typeof PlanStateActionType.SET_BUDGET;
    payload: {
        key: string,
        value: number,
    };
}

export interface SetEventTypesEnabled {
    type: typeof PlanStateActionType.SET_EVENT_TYPES_ENABLED;
    payload: EventTypesEnabledOption;
}

export interface SetLoadingAction {
    type: typeof PlanStateActionType.SET_LOADING;
    payload: boolean;
}

export type PlanStateActions =
    | SetBudgetAction
    | SetClientGroupAction
    | SetEventTypesEnabled
    | SetLoadingAction;

export type DisabledPlanInputs = Record<string, boolean>;

export interface CGAPNewPlanState {
    isLoading: boolean;
    planOptions: CGAPPlanOptions;
    selectedGroupOptionValue: string;
    selectedEventTypesOptionValue: EventTypesEnabledOption;
    disabledInputs: DisabledPlanInputs;
}
