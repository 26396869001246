import React, { ReactElement, useContext, useState } from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { ActionRibbonBottomStyle } from "./style";
import { clearLocalStorage } from "../../util/local-storage";
import ConfirmationModal from "../confirmation-modal";
import {
  PrimaryButtonWithIcon,
  SecondaryButtonWithIcon,
} from "../../styles/buttons";
import { useIntl } from "react-intl";
import { planningContext } from "../../views/planning/context";

type ActionRibbonBottomProps = {
  prop?: unknown;
};

const ActionRibbonBottom = ({}: ActionRibbonBottomProps): ReactElement => {
  const intl = useIntl();
  const { showSummary } = useContext(planningContext);
  const [confirmStartOver, setConfirmStartOver] = useState(false);

  return (
    <ActionRibbonBottomStyle>
      <SecondaryButtonWithIcon
        className="actionButton"
        onClick={() => {
          setConfirmStartOver(true);
        }}
      >
        {intl.formatMessage({ id: "planning.startOver" })}
        <RotateLeftIcon />
      </SecondaryButtonWithIcon>
      <PrimaryButtonWithIcon
        onClick={() => {
          showSummary();
        }}
      >
        {intl.formatMessage({ id: "annualBudget" })}
        <ArrowForwardIcon />
      </PrimaryButtonWithIcon>
      <ConfirmationModal
        isOpen={confirmStartOver}
        title={intl.formatMessage({ id: "startOverModal.title" })}
        bodyCopy={intl.formatMessage({ id: "startOverModal.description" })}
        confirmText={intl.formatMessage({
          id: "startOverModal.actions.confirm",
        })}
        cancelText={intl.formatMessage({ id: "startOverModal.actions.cancel" })}
        closeModalWithDisposition={(shouldStartOver) => {
          setConfirmStartOver(false);
          if (shouldStartOver) {
            clearLocalStorage();
            if (window?.location) {
              window.location.reload();
            }
          }
        }}
      />
    </ActionRibbonBottomStyle>
  );
};

export default ActionRibbonBottom;
