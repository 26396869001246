import {CGAPConfig} from "./types/annual-planner";

export const budgetSourcesOptions = [
  {label: "CI POD", value: "CI POD"},
  {label: "RP POD", value: "RP POD"},
  {label: "Client Group Forums", value: "Client Group Forums"},
  {label: "Client Group", value: "Client Group"},
];

export const monthsKeys = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const attendeesValues = [10, 50, 100, 200, 500, 1000, 2000];

export const tierNames = ["Tier 1", "Tier 2", "Tier 3"];
export const tierOptions = [
  { value: 1, label: "Tier 1" },
  { value: 2, label: "Tier 2" },
  { value: 1, label: "Tier 3" },
];

export enum EventFormat {
  InPerson = "inPerson",
  Virtual = "virtual",
  Webinar = "webinar",
}

export enum EventType {
  hosted = "hosted",
  sponsored = "sponsored",
}

export enum CGAPRoutes {
  // SUMMARY_BY_TIER = '/summary-by-tier',
  // DETAIL_BY_TIER = '/detail-by-tier',
  EVENTS_BY_TIER = "/events-by-tier",
  EVENTS_BY_CALENDAR = "/events-by-calendar",
  CAPACITY_VIEW = "/capacity-view",
  SUMMARY_VIEW = "/summary-view",
}

export enum CGAPPlannerView {
  EVENTS_BY_TIER,
  EVENTS_BY_CALENDAR,
}

export const blankConfig: CGAPConfig = {
  clientGroups: [],
  team: {
    monthlyHoursCapacity: [],
  },
  tiers: {
    "tier1": {
      "monthlyHours": [
        192,
        160,
        176,
        112
      ],
      "planningWeeks": 14
    },
    "tier2": {
      "monthlyHours": [
        70,
        90,
        60
      ],
      "planningWeeks": 10
    },
    "tier3": {
      "monthlyHours": [
        80,
        100
      ],
      "planningWeeks": 8
    }
  },
}
