import _cloneDeep from "lodash.clonedeep";
import _findIndex from "lodash.findindex";
import add from "date-fns/add";
import { AppActionType, CGAPAppState, GCAPAppStateActions } from "./types";
import {
  CGAPEvent,
  CGAPEventFieldType,
  CGAPPlanOptions,
  getEventSummaryInitial,
} from "../types/annual-planner";
import { getEventsSummary } from "../util/formulas";
import { saveToLocalStorage, STORE_KEY_APP_STATE } from "../util/local-storage";
import { v4 as uuidv4 } from "uuid";
import { EventFormat, EventType } from "../constants";

export function makeEvent(
  eventDetails: Record<string, CGAPEventFieldType> = {},
  planOptions: CGAPPlanOptions | undefined
): CGAPEvent {

  let defaultType: EventType = EventType.hosted;

  if(planOptions) {
    const enabledEventKeys = Object
        .keys(planOptions.eventTypes)
        .filter((key) => planOptions.eventTypes[key]);

    if (enabledEventKeys.length > 0) {
      defaultType = enabledEventKeys[0] as EventType;
    }
  }

  return Object.assign(
    {
      id: "",
      title: "",
      tier: 1,
      budgetSource: "",
      clientGroup: "",
      clientSegment: "",
      locationId: "LAO",
      locationDisplay: "Los Angeles",
      date: new Date(),
      dayCount: 1,
      format: EventFormat.InPerson,
      type: defaultType,
      peopleCount: 100,
      addons: [],
      cost: {
        total: 0,
        overrideMethodology: false
      }
    },
    eventDetails
  );
}

export function getMonthAfterEvent(event?: CGAPEvent): number {
  if (!event) {
    return 0;
  }

  return add(event.date, { months: 1 }).getMonth();
}

export function reducer(
  state: CGAPAppState,
  action: GCAPAppStateActions
): CGAPAppState {
  let newState = _cloneDeep(state);
  let updateSummary = false;

  switch (action.type) {
    case AppActionType.IMPORT_STATE:
      newState = _cloneDeep(action.payload);
      newState.events.map((event) => {
        event.date = new Date(event.date);
        return event;
      });
      updateSummary = true;
      break;
    case AppActionType.INIT_FROM_LANDING:
      newState.planOptions = _cloneDeep(action.payload.planOptions);
      newState.events = _cloneDeep(action.payload.events);
      updateSummary = true;
      break;
    case AppActionType.SET_CONFIG:
      newState.config = _cloneDeep(action.payload);
      break;
    case AppActionType.SET_ERROR:
      newState.error = action.payload;
      newState.isError = newState.error?.length > 0;
      break;
    case AppActionType.RESET_ERROR:
      newState.error = "";
      newState.isError = false;
      break;
    case AppActionType.SET_LOADING:
      newState.isLoading = action.payload;
      break;
    case AppActionType.RESET_EVENTS:
      newState.events = [];
      newState.eventsSummary = getEventSummaryInitial();
      break;
    case AppActionType.SET_EVENTS:
      newState.events = _cloneDeep(action.payload);
      updateSummary = true;
      break;
    case AppActionType.SET_PLANNING_VIEW:
      newState.planningView = action.payload;
      break;
    case AppActionType.ADD_EVENT:
      const newEvent = _cloneDeep(action.payload);

      if (newEvent.id && newEvent.id.length) {
        console.warn(
          `[store] Ignoring ADD_EVENT with existing ID ${action.payload.id}`
        );
        return state;
      }

      newEvent.id = uuidv4();
      newState.events.push(newEvent);
      updateSummary = true;
      break;
    case AppActionType.UPDATE_EVENT:
      if (newState.events.length) {
        const eventIndex = _findIndex(newState.events, {
          id: action.payload.id,
        });

        if (eventIndex >= 0) {
          newState.events[eventIndex] = _cloneDeep(action.payload);
        } else {
          console.warn(
            `[store] Could not find event with id ${action.payload.id} to update`
          );
        }

        updateSummary = true;
      }
      break;
    case AppActionType.DELETE_EVENT:
      if (newState.events.length) {
        const eventIndex = _findIndex(newState.events, {
          id: action.payload.id,
        });

        if (eventIndex >= 0) {
          newState.events.splice(eventIndex, 1);
        } else {
          console.warn(
            `[store] Could not find event with id ${action.payload.id} to remove`
          );
        }

        updateSummary = true;
      }
      break;
  }

  if (updateSummary) {
    // The below should *always* use newState to use any new vals updated in this cycle
    newState.eventsSummary = getEventsSummary(
      newState.config,
      newState.planOptions,
      newState.events,
      newState.planningYear
    );
  }

  if (action.type !== AppActionType.IMPORT_STATE) {
    saveToLocalStorage(STORE_KEY_APP_STATE, JSON.stringify(newState));
  }

  return newState;
}
