import {
  CGAPConfig,
  CGAPCostWithShare,
  CGAPCount,
  CGAPEffortCapacityUtilization,
  CGAPEvent,
  CGAPEventsSummary,
  CGAPPlanOptions,
  CGAPTierConfig,
  CGAPTierCount,
  getEventSummaryInitial,
  getInitialCostWithShare,
  getInitialCostWithShareAndType,
} from "../types/annual-planner";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import _set from "lodash.set";
import sub from "date-fns/sub";
import { CGAPEventError } from "../store/types";
import { getAvailableClientSegments } from "./index";
import { getTierAddonsConfig, getTierCosts, getTierKey } from "./config";
import {
  CGCAnnualCostInflation,
  CGCAddonsConfig,
  CGCInPersonCosts,
  CGCVirtualCosts,
} from "../types/common";
import { EventFormat, EventType } from "../constants";

export function getEventEffortWithShare(
  tierConfig: CGAPTierConfig,
  date: Date
): CGAPCostWithShare[] {
  const hours = [
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
    getInitialCostWithShare(),
  ];

  const planningMonths = Math.ceil(tierConfig.planningWeeks / 4);
  const monthEffort = [...tierConfig.monthlyHours];

  for (let pm = 0; pm < planningMonths; pm++) {
    const planningDate = sub(date, { months: pm });
    const effort = monthEffort.pop();

    if (effort && planningDate.getFullYear() === date.getFullYear()) {
      hours[planningDate.getMonth()].cost = effort;
    }
  }

  return hours;
}

export function calcAnnualCostInflation(
  costInflation: CGCAnnualCostInflation | undefined,
  basisCost: number,
  eventDate: Date
): number {
  if (!costInflation) {
    return basisCost;
  }

  const yearDelta = eventDate.getFullYear() - costInflation.basisYear;
  const eventRate = Math.pow(1 + costInflation.rate, yearDelta);

  return basisCost * eventRate;
}

export function getMonthlyCapacity(
  config: CGAPConfig,
  planningYear: number,
  groupCapacityShare = 1
): number[] {
  let configMonthlyHours: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const { monthlyHoursCapacity } = config?.team;

  if (monthlyHoursCapacity) {
    configMonthlyHours = monthlyHoursCapacity.map((hours) =>
      Math.floor(hours * groupCapacityShare)
    );
  }

  return configMonthlyHours;
}

export function getUtilizationForEffort(
  capacity: number,
  effort: number
): CGAPEffortCapacityUtilization {
  let utilization;

  if (capacity === 0 && effort >= 0) {
    utilization = effort / 0.001; // effectively zero, but not zero
  } else if (effort === 0) {
    utilization = 0;
  } else {
    utilization = effort / capacity;
  }

  return {
    capacity,
    effort,
    utilization,
  };
}

export function getCostForVirtualEvent(
  tierCosts: CGCVirtualCosts,
  event: CGAPEvent
): number {
  const prodCosts = tierCosts.prodCostDay * event.dayCount;
  const streamCosts = tierCosts.costPerPerson * event.peopleCount;
  return prodCosts + streamCosts;
}

export function getCostForInPersonEvent(
  tierCosts: CGCInPersonCosts,
  event: CGAPEvent
): number {
  if (!event.locationId?.length) {
    throw new CGAPEventError("missing locationId", event);
  }

  if (!tierCosts.locations[event.locationId]) {
    throw new Error(`no event data for location "${event.locationId}"`);
  }

  return tierCosts.locations[event.locationId] * event.peopleCount;
}

export function adjustAddonsForTier(
  addonsConfig: CGCAddonsConfig,
  currentAddons: string[]
): string[] {
  const updatedAddons: string[] = [];

  const configKeys = Object.keys(addonsConfig || {});
  if (!configKeys.length) {
    return updatedAddons;
  }

  for (const configKey of configKeys) {
    const addonConfig = addonsConfig[configKey];
    if (addonConfig.isDisabled) {
      if (addonConfig.isEnabledByDefault) {
        updatedAddons.push(addonConfig.id);
      }
    } else if (currentAddons?.indexOf(addonConfig.id) !== -1) {
      updatedAddons.push(addonConfig.id);
    }
  }

  return updatedAddons;
}

/**
 *
 * @param config
 * @param event
 * @param changingTier Indicates the tier changed in this cycle
 * @param applyDefaults Indicates the tier changed in this cycle
 */
export function applyBusinessRulesToEvent(
  config: CGAPConfig,
  event: CGAPEvent,
  changingTier = false,
  applyDefaults = false
): CGAPEvent {
  const adjustedEvent = _cloneDeep(event);
  let addonsConfig: CGCAddonsConfig =
    getTierAddonsConfig(config, adjustedEvent.tier, adjustedEvent.format) || {};
  let addonConfigKeys = Object.keys(addonsConfig);

  if (!adjustedEvent.addons) {
    adjustedEvent.addons = [];
  }

  if (addonConfigKeys?.length) {
    if (!changingTier) {
      // Adjust the tier based on the addons
      adjustedEvent.tier = addonConfigKeys
        .filter((configKey) => adjustedEvent.addons.indexOf(configKey) !== -1)
        .filter((configKey) => addonsConfig[configKey].forcesTier)
        .reduce((newTier, configKey) => {
          return Math.min(
            addonsConfig[configKey].forcesTier || Number.MAX_SAFE_INTEGER,
            newTier
          );
        }, adjustedEvent.tier);

      addonsConfig =
        getTierAddonsConfig(config, adjustedEvent.tier, adjustedEvent.format) ||
        {};
      addonConfigKeys = Object.keys(addonsConfig);
    } else {
      // Remove any keys that, if checked, would force an addon
      const addonsThatForceTier = addonConfigKeys
        .filter((configKey) => adjustedEvent.addons.indexOf(configKey) !== -1)
        .filter((configKey) => addonsConfig[configKey].forcesTier);

      adjustedEvent.addons = adjustedEvent.addons.filter(
        (addonKey) => addonsThatForceTier.indexOf(addonKey) === -1
      );
    }

    // Remove any addons not available
    adjustedEvent.addons = adjustedEvent.addons.filter(
      (addonKey) => addonConfigKeys.indexOf(addonKey) !== -1
    );

    // Only adjust the defaults if the tier changes
    if (applyDefaults) {
      const defaultAddons = addonConfigKeys
        .filter((configKey) => adjustedEvent.addons.indexOf(configKey) === -1)
        .filter((configKey) => addonsConfig[configKey].isEnabledByDefault);

      adjustedEvent.addons = [...adjustedEvent.addons, ...defaultAddons];
    }
  } else {
    adjustedEvent.addons = [];
  }
  return adjustedEvent;
}

export function updateEventCost(
  config: CGAPConfig | undefined,
  event: CGAPEvent
): CGAPEvent {
  const updatedEvent = _cloneDeep(event);

  if (!updatedEvent.cost) {
    updatedEvent.cost = {
      total: 0,
      overrideMethodology: false,
    };
  }

  if (!config) {
    return updatedEvent;
  }

  if (updatedEvent.type === EventType.sponsored) {
    updatedEvent.cost.overrideMethodology = false;
    updatedEvent.cost.total = updatedEvent.cost.sponsored?.flat || 0;
  } else {
    if (updatedEvent.format === EventFormat.Virtual) {
      if (!updatedEvent.cost.overrideMethodology) {
        let { prodCostDay, costPerPerson } = getTierCosts(
          config,
          updatedEvent.tier,
          updatedEvent.format
        ) as CGCVirtualCosts;

        prodCostDay = calcAnnualCostInflation(
          config.common?.costInflation,
          prodCostDay,
          event.date
        );
        costPerPerson = calcAnnualCostInflation(
          config.common?.costInflation,
          costPerPerson,
          event.date
        );

        _set(updatedEvent, "cost.hosted.virtual", {
          productionPerDay: prodCostDay,
          perPerson: costPerPerson,
        });

        if (!updatedEvent?.cost?.hosted?.virtual) {
          updatedEvent.cost.total = 0;
          return updatedEvent;
        }
      }

      if (updatedEvent?.cost?.hosted?.virtual) {
        const {
          productionPerDay,
          perPerson,
        } = updatedEvent.cost.hosted.virtual;
        const seatTotalPerDay = perPerson * updatedEvent.peopleCount;

        updatedEvent.cost.total =
          (productionPerDay + seatTotalPerDay) * updatedEvent.dayCount;
      }
    } else if (updatedEvent.format === EventFormat.InPerson) {
      if (!updatedEvent.cost.overrideMethodology) {
        const defaultTierCosts = getTierCosts(
          config,
          updatedEvent.tier,
          updatedEvent.format
        ) as CGCInPersonCosts;

        const costPerPerson = calcAnnualCostInflation(
          config.common?.costInflation,
          defaultTierCosts.locations[event.locationId],
          event.date
        );

        _set(updatedEvent, "cost.hosted.inPerson", {
          perPerson: costPerPerson,
        });

        if (!updatedEvent?.cost?.hosted?.inPerson) {
          updatedEvent.cost.total = 0;
          return updatedEvent;
        }
      }

      if (updatedEvent?.cost?.hosted?.inPerson) {
        const { perPerson } = updatedEvent.cost.hosted.inPerson;
        const seatTotalPerDay = perPerson * updatedEvent.peopleCount;

        updatedEvent.cost.total = seatTotalPerDay * updatedEvent.dayCount;
      }
    }

    if (event.addons?.length) {
      const inPersonOrVirtualType =
        updatedEvent.format === EventFormat.InPerson
          ? EventFormat.InPerson
          : EventFormat.Virtual;

      const addonsConfig: CGCAddonsConfig =
        getTierAddonsConfig(config, updatedEvent.tier, inPersonOrVirtualType) ||
        {};

      updatedEvent.cost.total = event.addons.reduce((newTotal, addonId) => {
        if (addonsConfig?.[addonId]) {
          return newTotal + addonsConfig[addonId].cost;
        }

        return newTotal;
      }, updatedEvent.cost.total);
    }
  }

  return updatedEvent;
}

export function updateCountTotals(count: CGAPCount): CGAPCount {
  const formats = [
    EventFormat.InPerson.toString(),
    EventFormat.Virtual.toString(),
  ];
  const tiers = ["tier1", "tier2", "tier3"];
  const types = [EventType.hosted.toString(), EventType.sponsored.toString()];

  const rootCount: CGAPCount = _cloneDeep(count);
  rootCount.total = 0;

  for (const format of formats) {
    rootCount.groups[format].total = 0;

    for (const tier of tiers) {
      rootCount.groups[format].groups[tier].total = 0;

      for (const type of types) {
        rootCount.groups[format].groups[tier].total +=
          rootCount.groups[format].groups[tier].groups[type].total;
      }

      rootCount.groups[format].total +=
        rootCount.groups[format].groups[tier].total;
    }

    rootCount.total += rootCount.groups[format].total;
  }

  return rootCount;
}

export function safeDivide(numerator: number, divisor: number): number {
  if (divisor === 0) {
    return 0;
  }

  return numerator / divisor;
}

/**
 * Derives the totals and other calculations for the events across the year.
 *
 * @param config
 * @param planOptions
 * @param events
 * @param planningYear

 */
export function getEventsSummary(
  config: CGAPConfig,
  planOptions: CGAPPlanOptions,
  events: CGAPEvent[],
  planningYear: number
): CGAPEventsSummary {
  const summary = getEventSummaryInitial();
  const availableClientSegments = getAvailableClientSegments(
    config,
    planOptions.clientGroup
  );

  summary.clientGroupTotal.groups = availableClientSegments.reduce(
    (newGroupMap, group) => {
      newGroupMap[group] = getInitialCostWithShareAndType();
      return newGroupMap;
    },
    {}
  );

  summary.events = events.map((event) => {
    // TODO: this should already be set when the event was built
    // const calcdEvent = applyBusinessRulesToEvent(config, event);

    const calcdEvent = _cloneDeep(event);
    const tierKey = getTierKey(calcdEvent.tier);
    const tierConfig: CGAPTierConfig =
      event.format === EventFormat.Webinar
        ? config.tiers.webinar
        : config.tiers[tierKey];

    // This is the hack part of this: type of webinar lets us grab the tier config above, it's otherwise a virtual event
    const inPersonOrVirtualType =
      calcdEvent.format === EventFormat.InPerson
        ? EventFormat.InPerson
        : EventFormat.Virtual;

    const formatKey = inPersonOrVirtualType.toString();
    const typeKey = event.type.toString();

    const typeKeySelector = `groups[${formatKey}].groups[${tierKey}].groups[${typeKey}].total`;
    let typeKeyCount = _get(summary.count, typeKeySelector);

    typeKeyCount++;
    _set(summary.count, typeKeySelector, typeKeyCount);

    //-----------------------------| Calculate cost
    _set(
      summary.cost,
      typeKeySelector,
      parseFloat(_get(summary.cost, typeKeySelector, 0)) + calcdEvent.cost.total
    );
    summary.costByType[typeKey] += calcdEvent.cost.total;

    const { clientSegment } = calcdEvent;
    if (clientSegment?.length) {
      if (!summary.clientGroupTotal.groups.hasOwnProperty(clientSegment)) {
        summary.clientGroupTotal.groups[
          clientSegment
        ] = getInitialCostWithShareAndType();
      }

      summary.clientGroupTotal.groups[clientSegment][calcdEvent.type].cost +=
        calcdEvent.cost.total;
    } else {
      summary.clientGroupTotal.ungrouped[calcdEvent.type].cost +=
        calcdEvent.cost.total;
    }

    summary.tierCostTotals[tierKey].total += calcdEvent.cost.total;
    summary.tierCostTotals[tierKey][formatKey] += calcdEvent.cost.total;

    let tierKeyCost = _get(summary.cost, typeKeySelector);

    if (!typeKeyCount) {
      tierKeyCost = 0;
    }

    typeKeyCount++;

    _set(summary.cost, typeKeySelector, tierKeyCost);

    // ------------- end cost update

    //-----------------------------| Calculate effort
    if (calcdEvent.type !== EventType.sponsored) {
      calcdEvent.effort = getEventEffortWithShare(tierConfig, calcdEvent.date);

      summary.monthlyEffort = summary.monthlyEffort.map(
        (currentTotal, monthIdx) => {
          if (calcdEvent.effort?.[monthIdx]) {
            currentTotal.effort += calcdEvent.effort[monthIdx].cost;
          }

          return currentTotal;
        }
      );
    }

    return calcdEvent;
  });

  summary.count = updateCountTotals(summary.count);
  summary.cost = updateCountTotals(summary.cost);

  for (const tierNum of [1, 2, 3]) {
    const tierCount: CGAPTierCount = summary.tierCountTotals[`tier${tierNum}`];
    const tierCost: CGAPTierCount = summary.tierCostTotals[`tier${tierNum}`];

    summary.tierCountTotals.total.total += tierCount.total;
    summary.tierCountTotals.total.inPerson += tierCount.inPerson;
    summary.tierCountTotals.total.virtual += tierCount.virtual;

    summary.tierCostTotals.total.total += tierCost.total;
    summary.tierCostTotals.total.inPerson += tierCost.inPerson;
    summary.tierCostTotals.total.virtual += tierCost.virtual;
  }

  if (planOptions.budget) {
    const {
      hosted: hostedBudget,
      sponsored: sponsoredBudget,
    } = planOptions.budget;

    summary.clientGroupTotal.ungrouped.hosted.share = safeDivide(
      summary.clientGroupTotal.ungrouped.hosted.cost,
        hostedBudget
    );

    summary.clientGroupTotal.ungrouped.sponsored.share = safeDivide(
      summary.clientGroupTotal.ungrouped.sponsored.cost,
        sponsoredBudget
    );
    for (const clientGroupKey of Object.keys(summary.clientGroupTotal.groups)) {
      summary.clientGroupTotal.groups[clientGroupKey].hosted.share = safeDivide(
        summary.clientGroupTotal.groups[clientGroupKey].hosted.cost,
        hostedBudget
      );
      summary.clientGroupTotal.groups[clientGroupKey].sponsored.share = safeDivide(
        summary.clientGroupTotal.groups[clientGroupKey].sponsored.cost,
          sponsoredBudget
      );
    }
  }
  //-----------------------------| Calculate the monthly effort %
  const monthlyCapacity = getMonthlyCapacity(
    config,
    planningYear,
    planOptions.clientGroup?.capacityShare
  );

  summary.monthlyEffort = monthlyCapacity.map(
    (capacity, monthIdx): CGAPEffortCapacityUtilization => {
      return getUtilizationForEffort(
        capacity,
        summary.monthlyEffort[monthIdx].effort
      );
    }
  );

  return summary;
}
