import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import _get from "lodash.get";
import { FormControl, MenuItem, Select } from "@material-ui/core";

import { StyledNewPlan } from "./style";
import LandingActionBox from "../landing-action-box";
import {
  CGAPClientGroupOption,
  CGAPConfigClientGroup,
  CGAPPlanOptions,
} from "../../../../types/annual-planner";

import { store } from "../../../../store";
import { CGCSelectOption } from "../../../../types/common";
import {
  getAvailableBaseYears,
  getEventMixAndBaseYear,
} from "../../../../util/previous-year";

import { AppActionType } from "../../../../store/types";
import {
  StyledMenuItemChild,
  StyledMenuItemParent,
  useCGSelectStyles,
} from "../../../../styles/cg-material-ui";
import CurrencyTextInput from "../../../currency-text-input";
import { planStateReducer } from "./reducer";
import {
  disabledInputsInitialState,
  EventTypesEnabledOption,
  PlanStateActionType,
} from "./types";

const planOptionsDefault: CGAPPlanOptions = {
  clientGroup: null,
  budget: null,
  baseYear: null,
  baseYearEventCount: 0,
  eventTypes: {
    hosted: false,
    sponsored: false,
  },
};

const NewPlan = (): ReactElement => {
  const { state, dispatch } = useContext(store);
  const intl = useIntl();
  const classes = useCGSelectStyles();

  const [newPlanState, dispatchNewPlanState] = useReducer(planStateReducer, {
    isLoading: state?.isLoading || true,
    planOptions: planOptionsDefault,
    selectedGroupOptionValue: "",
    selectedEventTypesOptionValue: EventTypesEnabledOption.none,
    disabledInputs: disabledInputsInitialState,
  });

  const [clientGroupOptions, setClientGroupOptions] = useState<
    CGAPClientGroupOption[]
  >([]);

  const newMixOption = {
    label: intl.formatMessage({
      id: "landing.newEventMix",
    }),
    value: new Date().getFullYear().toString(),
  };

  const [previousYearOptions, setPreviousYearOptions] = useState<
    CGCSelectOption[]
  >([newMixOption]);

  useEffect(() => {
    dispatchNewPlanState({
      type: PlanStateActionType.SET_LOADING,
      payload: state?.isLoading ?? true,
    });
  }, [state?.isLoading]);

  useEffect(() => {
    const eventDataYears = getAvailableBaseYears();

    if (eventDataYears?.length) {
      const previousYearsMix = eventDataYears
        .sort()
        .reverse()
        .map((year) => ({
          label: intl.formatMessage(
            {
              id: "landing.yearEventMix",
            },
            { year: year }
          ),
          value: year,
        }));

      setPreviousYearOptions([...previousYearsMix, newMixOption]);
    }
  }, []);

  useEffect(() => {
    let newClientGroupOptions: CGAPClientGroupOption[] = [];
    if (state?.config?.clientGroups?.length) {
      const groupCount = state.config.clientGroups.length;

      for (let i = 0; i < groupCount; i++) {
        const clientGroup: CGAPConfigClientGroup = state.config.clientGroups[i];
        const clientGroupOption = {
          budget: clientGroup.budget,
          label: intl.formatMessage(
            { id: "landing.groupOptionLabel" },
            { group: clientGroup.label }
          ),
          groupFilter: clientGroup.label,
          capacityShare: clientGroup.capacityShare,
          segmentFilter: null,
        };

        const segmentOptions =
          clientGroup?.segments?.map((segment) => {
            return {
              budget: clientGroup.budget,
              label: intl.formatMessage(
                { id: "landing.segmentOptionLabel" },
                { segment: segment }
              ),
              capacityShare: clientGroup.capacityShare,
              groupFilter: clientGroup.label,
              segmentFilter: segment,
            };
          }) || [];

        newClientGroupOptions = [
          ...newClientGroupOptions,
          clientGroupOption,
          ...segmentOptions,
        ];
      }
    }

    setClientGroupOptions(newClientGroupOptions);
  }, [state?.config?.clientGroups]);

  const loadMixAndStart = (previousYear: string) => {
    const cgoIndex = parseInt(newPlanState.selectedGroupOptionValue, 10);
    const clientGroupOption: CGAPClientGroupOption =
      clientGroupOptions[cgoIndex];

    const planningYear = state?.planningYear || 2021;

    const [events, baseYear] = getEventMixAndBaseYear(
      previousYear,
      planningYear,
      clientGroupOption,
      state?.config
    );

    const newPlanOptions = {
      ...newPlanState.planOptions,
      baseYear,
      baseYearEventCount: events?.length || 0,
    };

    if (newPlanOptions.budget) {
      newPlanOptions.budget.total =
        (newPlanOptions.budget.sponsored || 0) +
        (newPlanOptions.budget.hosted || 0);
    }

    dispatch({
      type: AppActionType.INIT_FROM_LANDING,
      payload: {
        planOptions: newPlanOptions,
        events,
      },
    });
  };

  const handleGroupChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValueString = event.target.value as string;

    const clientGroupOption: CGAPClientGroupOption = _get(
      clientGroupOptions,
      `[${newValueString}]`
    );

    dispatchNewPlanState({
      type: PlanStateActionType.SET_CLIENT_GROUP_OPTIONS,
      payload: {
        clientGroup: clientGroupOption,
        selectedGroupOptionValue: newValueString,
      },
    });
  };

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    dispatchNewPlanState({
      type: PlanStateActionType.SET_EVENT_TYPES_ENABLED,
      payload: event.target.value as EventTypesEnabledOption,
    });
  };

  const handlePreviousYearChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    loadMixAndStart(event.target.value as string);
  };

  return (
    <StyledNewPlan>
      <LandingActionBox
        actionsI18nPrefix="landing.new-plan.select-group-segment"
        disabled={state?.isLoading}
      >
        <FormControl className={classes.formControl}>
          <Select
            value={newPlanState.selectedGroupOptionValue}
            className={classes.selectEmpty}
            onChange={handleGroupChange}
            disabled={state?.isLoading}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <FormattedMessage id="selectOne" />
            </MenuItem>
            {!!clientGroupOptions?.length &&
              clientGroupOptions.map((option, idx) => {
                // This creates the "header" style select options that can also be selected
                const RenderedMenuItem = option.segmentFilter
                  ? StyledMenuItemChild
                  : StyledMenuItemParent;

                return (
                  <RenderedMenuItem key={idx} value={idx.toString()}>
                    {option.label}
                  </RenderedMenuItem>
                );
              })}
          </Select>
        </FormControl>
      </LandingActionBox>
      <LandingActionBox
        actionsI18nPrefix="landing.new-plan.select-event-type"
        disabled={newPlanState.disabledInputs.eventType}
      >
        <FormControl className={classes.formControl}>
          <Select
            value={newPlanState.selectedEventTypesOptionValue}
            className={classes.selectEmpty}
            onChange={handleTypeChange}
            disabled={newPlanState.disabledInputs.eventType}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <FormattedMessage id="selectOne" />
            </MenuItem>
            <MenuItem value={EventTypesEnabledOption.hosted}>
              <FormattedMessage id="EventTypesEnabledOption.hosted" />
            </MenuItem>
            <MenuItem value={EventTypesEnabledOption.sponsored}>
              <FormattedMessage id="EventTypesEnabledOption.sponsored" />
            </MenuItem>
            <MenuItem value={EventTypesEnabledOption.both}>
              <FormattedMessage id="EventTypesEnabledOption.both" />
            </MenuItem>
          </Select>
        </FormControl>
      </LandingActionBox>
      <LandingActionBox
        actionsI18nPrefix="landing.new-plan.indicate-budget"
        disabled={newPlanState.disabledInputs.budget}
      >
        {newPlanState.planOptions.eventTypes.hosted && (
          <CurrencyTextInput
            label={intl.formatMessage({ id: "enterHostedBudgetLimit" })}
            plainvalue={newPlanState.planOptions.budget?.hosted || 0}
            fullWidth
            size="small"
            disabled={newPlanState.disabledInputs.budget}
            onChange={(e) => {
              dispatchNewPlanState({
                type: PlanStateActionType.SET_BUDGET,
                payload: {
                  key: "hosted",
                  value: parseInt(e.target.value, 10),
                },
              });
            }}
          />
        )}
        {newPlanState.planOptions.eventTypes.sponsored && (
          <CurrencyTextInput
            label={intl.formatMessage({ id: "enterSponsoredBudgetLimit" })}
            plainvalue={newPlanState.planOptions.budget?.sponsored || 0}
            fullWidth
            size="small"
            disabled={newPlanState.disabledInputs.budget}
            onChange={(e) => {
              dispatchNewPlanState({
                type: PlanStateActionType.SET_BUDGET,
                payload: {
                  key: "sponsored",
                  value: parseInt(e.target.value, 10),
                },
              });
            }}
          />
        )}
      </LandingActionBox>
      <LandingActionBox
        actionsI18nPrefix="landing.new-plan.select-how"
        disabled={newPlanState.disabledInputs.previousYear}
      >
        <FormControl className={classes.formControl}>
          <Select
            value=""
            onChange={handlePreviousYearChange}
            className={classes.selectEmpty}
            disabled={newPlanState.disabledInputs.previousYear}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <FormattedMessage id="selectOne" />
            </MenuItem>
            {!!previousYearOptions?.length &&
              previousYearOptions.map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {/*<Select*/}
        {/*  onChange={(event) => {*/}
        {/*    loadMixAndStart(event.value);*/}
        {/*  }}*/}
        {/*  isDisabled={state?.isLoading || boxThreeDisabled}*/}
        {/*  menuPlacement="top"*/}
        {/*  options={previousYearOptions}*/}
        {/*  placeholder={intl.formatMessage({*/}
        {/*    id: "selectOne",*/}
        {/*    defaultMessage: "Select One",*/}
        {/*  })}*/}
        {/*  styles={reactSelectStyles}*/}
        {/*/>*/}
      </LandingActionBox>
    </StyledNewPlan>
  );
};

export default NewPlan;
