import React, {
  ReactElement,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { CenterModal } from "react-spring-modal";
import "react-spring-modal/dist/index.css";
import _set from "lodash.set";
import _cloneDeep from "lodash.clonedeep";
import { useIntl } from "react-intl";
import DateFnsUtils from "@date-io/date-fns";
import { FormControlLabel, TextField } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { store } from "../../store";
import { blankConfig, EventFormat, EventType } from "../../constants";
import { CGAPEventFieldType } from "../../types/annual-planner";
import {
  simpleSelectOptionsMap,
  useLocalizedLists,
} from "../../util/localized-options";
import { AppActionType } from "../../store/types";
import { CGCheckbox } from "../material/cg-checkbox";
import EventFieldSelect from "./components/event-field-select";
import {
  StyledEditEventModal,
  StyledFormElement,
  StyledFormDisclaimer,
  StyledAddons,
  StyledFullHeightColumn,
  StyledModalContent,
  StyledAddon,
  StyledAddonDisclaimer,
  StyledAddonDisclaimers,
  StyledEditModalActions,
  StyledEditModalAction,
} from "./style";
import {
  CancelButtonWithIcon,
  ConfirmButtonWithIcon,
} from "../../styles/buttons";
import { StyledModalHeader } from "../../styles/modals";
import {
  CGAPEventFormState,
  CGAPEventModalState,
  EventFormActionType,
  GCAPEventFormStateActions,
  getInitialEventFormState,
} from "./types";
import { useAvailableClientSegments } from "../../util/hooks";
import { updateEventReducer } from "./reducer";
import { isAddonSelected } from "../../util/events";
import GroupBox from "./components/group-box";
import { BudgetPlanningContext } from "./context";
import BudgetPlanning from "./components/budget-planning";

interface RenderedAddons {
  hasAddons: boolean;
  controls: ReactElement[];
  disclaimers: ReactElement[];
}

interface EventModalProps extends CGAPEventModalState {
  setEventModalState: (newState: CGAPEventModalState) => void;
  closeModalWithDisposition?: (disposition: boolean | Error) => void; // true -> success, false -> cancelled, Error -> failure
}

function initialRenderedAddons(): RenderedAddons {
  return {
    hasAddons: false,
    controls: [],
    disclaimers: [],
  };
}

const EventModal = ({
  isAdding,
  isVisible,
  sourceEvent,
  setEventModalState,
  closeModalWithDisposition,
}: EventModalProps): ReactElement => {
  const { state, dispatch } = useContext(store);
  const intl = useIntl();
  const localizedLists = useLocalizedLists();
  const clientSegments = useAvailableClientSegments();
  const [renderedAddons, setRenderedAddons] = useState<RenderedAddons>(() =>
    initialRenderedAddons()
  );
  const [isEventTypeDisabled, setIsEventTypeDisabled] = useState(true);

  /*
   * This is the main reducer for the event form. It's here inside the FC so that
   * it has access directly to the config without having to pass it through.
   */
  const eventFormStateReducer = (
    formState: CGAPEventFormState,
    action: GCAPEventFormStateActions
  ): CGAPEventFormState => {
    const config = state?.config || blankConfig;
    let newEventFormState: CGAPEventFormState;

    switch (action.type) {
      case EventFormActionType.UPDATE_EVENT:
        return updateEventReducer(config, formState, action.payload, intl);
      case EventFormActionType.RESET:
        newEventFormState = getInitialEventFormState();

        // don't munge the CSOs on reset
        newEventFormState.clientSegmentOptions = _cloneDeep(
          formState.clientSegmentOptions
        );
        return newEventFormState;
      case EventFormActionType.UPDATE_CLIENT_SEGMENT_OPTS:
        newEventFormState = _cloneDeep(formState);
        newEventFormState.clientSegmentOptions = _cloneDeep(action.payload);
        return newEventFormState;
    }

    // default to returning the current state
    return formState;
  };

  const [eventFormState, eventFormDispatch] = useReducer(
    eventFormStateReducer,
    getInitialEventFormState()
  );

  useEffect(() => {
    eventFormDispatch({
      type: EventFormActionType.UPDATE_CLIENT_SEGMENT_OPTS,
      payload: clientSegments.map(simpleSelectOptionsMap),
    });
  }, [clientSegments]);

  useEffect(() => {
    localizedLists.updateWebinarEnabled(eventFormState?.event?.tier === 3);
  }, [eventFormState?.event?.tier]);

  useEffect(() => {
    localizedLists.updateAttendees(eventFormState?.event?.peopleCount);
  }, [eventFormState?.event?.peopleCount]);

  useEffect(() => {
    const updatedAddons = initialRenderedAddons();

    if (!!eventFormState.addonOptions) {
      updatedAddons.hasAddons = true;
      eventFormState.addonOptions.forEach((addonOption, key) => {
        updatedAddons.controls.push(
          <StyledAddon key={key}>
            <FormControlLabel
              disabled={addonOption.isDisabled}
              control={
                <CGCheckbox
                  disabled={addonOption.isDisabled}
                  checked={isAddonSelected(
                    eventFormState?.event,
                    addonOption.value
                  )}
                  onChange={(event) => {
                    updateAddon(addonOption.value, event.target.checked);
                  }}
                />
              }
              label={addonOption.label}
            />
          </StyledAddon>
        );
        updatedAddons.disclaimers.push(
          <StyledAddonDisclaimer>
            {addonOption.disclaimer}
          </StyledAddonDisclaimer>
        );
      });
    }

    setRenderedAddons(updatedAddons);
  }, [eventFormState.addonOptions]);

  useEffect(() => {
    if (eventFormState?.tierConfig) {
      switch (eventFormState.event?.format) {
        case EventFormat.Virtual:
          localizedLists.updateDaysConfig(
            eventFormState.tierConfig.virtual.days
          );
          break;
        case EventFormat.Webinar:
          if (eventFormState.tierConfig.webinar) {
            localizedLists.updateDaysConfig(
              eventFormState.tierConfig.webinar.days
            );
          } else {
            localizedLists.updateDaysConfig(
              eventFormState.tierConfig.virtual.days
            );
          }
          break;
        default:
          localizedLists.updateDaysConfig(
            eventFormState.tierConfig.inPerson.days
          );
      }
    }
  }, [
    eventFormState.tierConfig?.inPerson?.days,
    eventFormState.tierConfig?.virtual?.days,
    eventFormState.tierConfig?.webinar?.days,
  ]);

  useEffect(() => {
    eventFormDispatch({
      type: EventFormActionType.UPDATE_EVENT,
      payload: sourceEvent || undefined,
    });

    return () => {
      eventFormDispatch({
        type: EventFormActionType.RESET,
      });
    };
  }, [sourceEvent]);

  useEffect(() => {
    if (state?.planOptions?.eventTypes) {
      let isDisabled = true;

      const enabledEventKeys = Object.keys(state.planOptions.eventTypes).filter(
        (key) => state.planOptions.eventTypes[key]
      );

      if (enabledEventKeys.length > 1) {
        isDisabled = false;
      }

      setIsEventTypeDisabled(isDisabled);
    }
  }, [state?.planOptions?.eventTypes]);

  const updateEventField = (
    fieldName: string,
    newValue: CGAPEventFieldType
  ): void => {
    if (eventFormState?.event && state?.config) {
      const updatingEvent = _cloneDeep(eventFormState.event);
      _set(updatingEvent, fieldName, newValue);
      eventFormDispatch({
        type: EventFormActionType.UPDATE_EVENT,
        payload: updatingEvent,
      });
    }
  };

  const updateAddon = (addon: string, isChecked: boolean): void => {
    const addonIndex: number =
      eventFormState?.event?.addons?.indexOf(addon) ?? -1;
    const isCurrentlyChecked = addonIndex !== -1;

    if (isChecked !== isCurrentlyChecked) {
      const updatingEvent = _cloneDeep(eventFormState.event);

      if (isCurrentlyChecked) {
        updatingEvent?.addons.splice(addonIndex, 1);
      } else {
        updatingEvent?.addons.push(addon);
      }

      eventFormDispatch({
        type: EventFormActionType.UPDATE_EVENT,
        payload: updatingEvent,
      });
    }
  };

  const doUpdateEvent = () => {
    dispatch({
      type: isAdding ? AppActionType.ADD_EVENT : AppActionType.UPDATE_EVENT,
      payload: eventFormState.event,
    });

    if (typeof closeModalWithDisposition === "function") {
      closeModalWithDisposition(false);
    }
    setEventModalState({
      isVisible: false,
      isAdding: false,
    });
  };

  const handleDateChange = (date: Date | null) => {
    // setSelectedDate(date);
    console.info("Date changed to", date);
    if (eventFormState?.event && state?.config) {
      const updatingEvent = _cloneDeep(eventFormState.event);

      if (date) {
        updatingEvent.date = date;

        eventFormDispatch({
          type: EventFormActionType.UPDATE_EVENT,
          payload: updatingEvent,
        });
      }
    }
  };

  const doCancel = () => {
    if (typeof closeModalWithDisposition === "function") {
      closeModalWithDisposition(false);
    }
    setEventModalState({
      isVisible: false,
      isAdding: false,
    });
  };

  if (!eventFormState.event) {
    return <></>;
  }

  const titleId = isAdding ? "eventModal.title.add" : "eventModal.title.edit";
  const shouldShowCitiesDropdown =
    eventFormState.event?.type === EventType.hosted &&
    eventFormState.event?.format === EventFormat.InPerson;
  const shouldShowCitiesField =
    eventFormState.event?.type === EventType.sponsored &&
    eventFormState.event?.format === EventFormat.InPerson;

  return (
    <CenterModal
      isOpen={isVisible}
      onRequestClose={() => {
        doCancel();
      }}
    >
      <BudgetPlanningContext
        value={{
          eventFormState,
          eventFormDispatch,
          updateEventField,
          localizedLists,
        }}
      >
        <StyledEditEventModal>
          <StyledModalHeader>
            {intl.formatMessage({ id: titleId })}
          </StyledModalHeader>

          <StyledModalContent>
            <StyledFullHeightColumn>
              <GroupBox title="EVENT DETAILS">
                <StyledFormElement data-comment="event name">
                  <TextField
                    label={intl.formatMessage({ id: "eventName" })}
                    defaultValue={eventFormState.event.title}
                    required={true}
                    fullWidth
                    size="small"
                    onChange={(e) => {
                      updateEventField("title", e.target.value + "");
                    }}
                  />
                </StyledFormElement>
                <StyledFormElement data-comment="Type">
                  <EventFieldSelect
                    id="edit-modal-type"
                    fieldName="type"
                    placeholderIntlId="eventField.type"
                    isDisabled={isEventTypeDisabled}
                    options={localizedLists.eventTypeOptions}
                    fieldValue={eventFormState.event.type}
                    updateEventField={updateEventField}
                  />
                </StyledFormElement>
                <StyledFormElement data-comment="Tier">
                  <EventFieldSelect
                    id="edit-modal-tier"
                    fieldName="tier"
                    placeholderIntlId="tier"
                    options={localizedLists.tierNamesOptions}
                    fieldValue={eventFormState.event.tier.toString()}
                    valueConverter={(value: string) => parseInt(value)}
                    updateEventField={updateEventField}
                  />
                  {!!eventFormState.tierDisclaimer.length && (
                    <StyledFormDisclaimer>
                      {eventFormState.tierDisclaimer}
                    </StyledFormDisclaimer>
                  )}
                </StyledFormElement>
                <StyledFormElement data-comment="Format">
                  <EventFieldSelect
                    id="edit-modal-type"
                    fieldName="format"
                    placeholderIntlId="eventField.format"
                    options={localizedLists.eventFormatOptions}
                    fieldValue={eventFormState.event.format}
                    updateEventField={updateEventField}
                  />
                </StyledFormElement>
                {shouldShowCitiesDropdown && (
                  <StyledFormElement data-comment="location">
                    <EventFieldSelect
                      id="edit-modal-location"
                      fieldName="locationId"
                      placeholderIntlId="city"
                      options={eventFormState.cityOptions}
                      menuPlacement="top"
                      fieldValue={eventFormState.event.locationId}
                      updateEventField={(
                        fieldName: string,
                        newValue: CGAPEventFieldType
                      ) => {
                        updateEventField("locationId", newValue);
                        updateEventField(
                          "locationDisplay",
                          intl.formatMessage({ id: `location.${newValue}` })
                        );
                      }}
                    />
                  </StyledFormElement>
                )}
                {shouldShowCitiesField && (
                  <StyledFormElement data-comment="location">
                    <TextField
                      label={intl.formatMessage({ id: "city" })}
                      defaultValue={eventFormState.event.locationDisplay}
                      required={true}
                      fullWidth
                      size="small"
                      onChange={(e) => {
                        updateEventField("locationId", "");
                        updateEventField(
                          "locationDisplay",
                          e.target.value + ""
                        );
                      }}
                    />
                  </StyledFormElement>
                )}
                <StyledFormElement data-comment="Client Segment">
                  <EventFieldSelect
                    id="edit-modal-client-segment"
                    fieldName="clientSegment"
                    placeholderIntlId="clientSegment"
                    options={eventFormState.clientSegmentOptions}
                    fieldValue={eventFormState.event.clientSegment}
                    updateEventField={updateEventField}
                  />
                </StyledFormElement>
                {renderedAddons.hasAddons && (
                  <>
                    <StyledAddons>{renderedAddons.controls}</StyledAddons>
                    <StyledAddonDisclaimers>
                      {renderedAddons.disclaimers}
                    </StyledAddonDisclaimers>
                  </>
                )}
              </GroupBox>
            </StyledFullHeightColumn>
            <StyledFullHeightColumn>
              <GroupBox title="CAPACITY PLANNING">
                <StyledFormElement data-comment="date">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      fullWidth
                      autoOk={true}
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="none"
                      label="Event Date"
                      value={eventFormState.event.date}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </StyledFormElement>
              </GroupBox>
              <BudgetPlanning />
            </StyledFullHeightColumn>
          </StyledModalContent>
          <StyledEditModalActions>
            <StyledEditModalAction>
              <CancelButtonWithIcon onClick={doCancel}>
                {intl.formatMessage({ id: "eventModal.actions.cancel" })}
                <ArrowForwardIcon />
              </CancelButtonWithIcon>
            </StyledEditModalAction>
            <StyledEditModalAction>
              <ConfirmButtonWithIcon
                onClick={doUpdateEvent}
                disabled={!eventFormState.canSubmit}
              >
                {intl.formatMessage({ id: "eventModal.actions.save" })}
                <ArrowForwardIcon />
              </ConfirmButtonWithIcon>
            </StyledEditModalAction>
          </StyledEditModalActions>
        </StyledEditEventModal>
      </BudgetPlanningContext>
    </CenterModal>
  );
};

export default EventModal;
