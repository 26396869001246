import styled from "styled-components";
import {deviceWidths, ThemeColors} from "../../styles/theme";

export const StyledLandingWizard = styled.div`
  background-color: ${ThemeColors.GrayE5};
  padding: 3rem 3rem 0 3rem;
  overflow: hidden;
  max-width: 95%;
  
  @media screen and ${deviceWidths.max} {
    max-width: 1410px;
  }
`;

export const StyledLandingContent = styled.div`
`;

export const StyledLandingActions = styled.div`
  padding-top: 5px;

  @media screen and ${deviceWidths.desktop} {
    padding: 1rem 0;
    height: 3rem;
  }
`;
