import { createContext } from "react";
import {
  CGAPEventModalState,
  makeModalState,
} from "../../components/event-modal/types";
import { CGAPPlanningViewFilters } from "../../types/annual-planner";

export interface CGAPPlanningState {
  eventModalState: CGAPEventModalState;
  setEventModalState: (newState: CGAPEventModalState) => void;
  summaryModalVisible: boolean;
  setSummaryModalVisible: (newState: boolean) => void;
  showSummary: () => void;
  viewFilters: CGAPPlanningViewFilters;
  updateViewFilters: (key: "hosted" | "sponsored", value: boolean) => void;
}

export const planningContext = createContext<CGAPPlanningState>({
  eventModalState: makeModalState(),
  setEventModalState: () => {
    /* noop */
  },
  summaryModalVisible: false,
  setSummaryModalVisible: () => {
    /* noop */
  },
  showSummary: () => {
    /* noop */
  },
  viewFilters: {
    hosted: false,
    sponsored: false,
    all: false,
  },
  updateViewFilters: () => {
    /* noop */
  },
});

const { Provider } = planningContext;
export { Provider as PlanningContext };
