import styled, { css } from "styled-components";
import {deviceWidths, StyleFontMedium, ThemeColors} from "./theme";

export const BaseButtonStyles = css`
  ${StyleFontMedium}
  padding: 16px 5px;
  width: 175px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 12px;
  margin: 15px 0 5px 0;
  
  @media screen and ${deviceWidths.desktop} {
    width: 210px;
    padding: 1.25em;
    font-size: 14px;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;
export const PrimaryButtonStyles = css`
  color: ${ThemeColors.White};
  background-color: ${ThemeColors.Ocean};
`;

export const SecondaryButtonStyles = css`
  color: ${ThemeColors.Black};
  background-color: ${ThemeColors.GrayE5};
`;

export const ButtonWithIconStyles = css`
  display: grid;
  grid-template-columns: 1fr 24px;
  grid-gap: 5px;
  align-items: center;
  justify-items: start;
  text-transform: uppercase;
`;

export const PrimaryButton = styled.button`
  ${BaseButtonStyles}
  ${PrimaryButtonStyles}
`;

export const PrimaryButtonWithIcon = styled.button`
  ${BaseButtonStyles}
  ${PrimaryButtonStyles}
  ${ButtonWithIconStyles}
`;

export const SecondaryButton = styled.button`
  ${BaseButtonStyles}
  ${SecondaryButtonStyles}
`;

export const SecondaryButtonWithIcon = styled.button`
  ${BaseButtonStyles}
  ${SecondaryButtonStyles}
  ${ButtonWithIconStyles}
`;

export interface ToggleButtonProps {
  isActive: boolean;
}

export const ToggleButton = styled.button<ToggleButtonProps>`
  ${BaseButtonStyles}

  ${(props) => {
    if (props.isActive) {
      return PrimaryButtonStyles;
    }

    return SecondaryButtonStyles;
  }}
`;

export const ConfirmButtonWithIcon = styled.button`
  ${BaseButtonStyles}
  ${ButtonWithIconStyles}  
  color: ${ThemeColors.White};
  background-color: ${ThemeColors.Sapphire};
  width: 100%;
`;

export const CancelButtonWithIcon = styled.button`
  ${BaseButtonStyles}
  ${ButtonWithIconStyles}  
  color: ${ThemeColors.White};
  background-color: ${ThemeColors.GrayB2};
  width: 100%;
`;

export const OutlineButtonWithIcon = styled.button`
  ${BaseButtonStyles}
  ${ButtonWithIconStyles}  
  color: ${ThemeColors.Ocean};
  background-color: transparent;
  border: 1px solid ${ThemeColors.Ocean};
  width: 100%;
`;

export interface FlatButtonProps {
  size?: number;
}

export const FlatButton = styled.button<FlatButtonProps>`
  color: ${ThemeColors.Ocean};
  padding: 0;
  margin: 0 0.5em;
  cursor: pointer;
  display: inline;
  background: transparent;
  border: none;
  width: auto;

  ${StyleFontMedium}

  font-size: ${(props) => (props.size ? props.size : 14)}px;

  &:disabled {
    opacity: 0.2;
    color: ${ThemeColors.CGBlack};
    cursor: not-allowed;
  }
`;
