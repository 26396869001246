import styled from "styled-components";
import { TextInputStyles } from "../../styles/inputs";
import { deviceWidths, StyleH6Header } from "../../styles/theme";

export interface StyledLabeledBoxProps {
  fullwidth?: boolean;
}

export const StyledLabeledBox = styled.div<StyledLabeledBoxProps>`
  display: inline-block;
  margin: 0.25rem;
  width: ${(props) => (props.fullwidth ? "100%" : "10em")};

  @media screen and ${deviceWidths.desktop} {
    width: ${(props) => (props.fullwidth ? "100%" : "15em")};
  }
`;

export const StyledHeader = styled(StyleH6Header)`
  margin: 0;
  line-height: 32px;
`;

export const StyledContent = styled.div`
  ${TextInputStyles};
  font-size: 20px;
  letter-spacing: 0.5px;
  border-bottom-width: 2px;
`;
