import styled from "styled-components";

export const StyledNewPlan = styled.div`
  display: grid;
  grid-gap: 15px;

  @media screen and (min-width: 1274px) {
    grid-gap: 1.5rem;
    grid-template-columns: repeat(4, minmax(250px, auto));
  }
  
  // For when inputs are stacked
  .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 15px;
  }
`;
