import styled from "styled-components";
import {StyledModal} from "../../styles/modals";
import {deviceWidths, StyleFontBook, StyleFontRoman, ThemeColors} from "../../styles/theme";
import { CGCDisableable } from "../../types/common";

export const StyledEditEventModal = styled(StyledModal)`
  width: 95%;

  @media screen and ${deviceWidths.tablet} {
    width: 85%;
  }

  @media screen and ${deviceWidths.desktop} {
    width: 1000px;
  }
`;

export const StyledModalContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;

  .MuiInputLabel-formControl:not(.MuiInputLabel-shrink) {
    transform: translate(0, 20px) scale(1);
  }
  
  .MuiInputBase-input,
  .MuiInputLabel-root,
  .MuiFormControlLabel-label {
    font-size: 14px; 
  }
  
  .MuiInputBase-input {
    padding: 2px 0;
  } 
  
  .MuiInputAdornment-positionEnd {
    margin-top: -8px;
  }
`;

export const StyledFullHeightColumn = styled.div`
  grid-auto-rows: 1fr;
  background-color: ${ThemeColors.GrayF7};
`;

export interface GridColumnProps {
  count: number;
}

export const StyledGridColumns = styled.div<GridColumnProps>`
  display: grid;
  grid-template-columns: repeat(2, ${(props) => props.count}fr);
  grid-gap: 1rem;
`;

export const StyledFormElement = styled.div`
  margin: 0 0 1.75rem;
  color: ${ThemeColors.CGBlack};
  
  &:last-child {
    margin: 0;
  }
`;

export const StyledFormControlWithLabel = styled.div<CGCDisableable>`
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const StyledAddons = styled(StyledFormElement)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

export const StyledAddon = styled.div`
//  the controls add a -15px margin as how its wrappers do it
  margin: 0 15px;
`;

export const StyledAddonDisclaimers = styled(StyledFormElement)``;

export const StyledAddonDisclaimer = styled.div`
  ${StyleFontBook};
  color: ${ThemeColors.Sapphire};
  font-size: 0.8rem;
`;

export const StyledFormDisclaimer = styled(StyledAddonDisclaimer)`
  margin: 1rem;
`;

export const StyledEditModalActions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, min-content);
  margin: 1rem 0 0 0;
  grid-gap: 1rem;
  align-items: center;
  justify-content: end;
   
`;

export const StyledEditModalAction = styled.div`
  display: inline-block;
`;

export const StyledEventCost = styled.div`
  ${StyleFontRoman};
  margin: 0 0 0.25em 0;
  font-size: 16px;
  font-weight: 500;

  @media screen and ${deviceWidths.desktop} {
    font-size: 24px;
  }
`;
