import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import _cloneDeep from "lodash.clonedeep";
import {attendeesValues as defaultAttendeesValues, EventFormat, EventType, monthsKeys} from "../constants";
import { CGCCostsDays, CGCSelectOption } from "../types/common";

export interface LocalizedListState {
  attendeesOptions: CGCSelectOption[];
  daysOptions: CGCSelectOption[];
  eventFormatOptions: CGCSelectOption[];
  eventTypeOptions: CGCSelectOption[];
  months: string[];
  monthsOptions: CGCSelectOption[];
  tierNames: string[];
  tierNamesOptions: CGCSelectOption[];
  updateDaysConfig: (config: CGCCostsDays) => void;
  updateWebinarEnabled: (isEnabled: boolean) => void;
  updateAttendees: (attendees: number | undefined) => void;
}

export function useLocalizedLists(): LocalizedListState {
  const intl = useIntl();
  const [isWebinarEnabled, setIsWebinarEnabled] = useState(false);
  const [daysConfig, setDaysConfig] = useState<CGCCostsDays>();
  const [customAttendees, setCustomAttendees] = useState<number | undefined>();
  const [localizedLists, setLocalizedLists] = useState<LocalizedListState>({
    attendeesOptions: [],
    daysOptions: [],
    eventFormatOptions: [],
    eventTypeOptions: [],
    months: [],
    monthsOptions: [],
    tierNames: [],
    tierNamesOptions: [],
    updateDaysConfig: (config: CGCCostsDays) => {
      setDaysConfig(config);
    },
    updateWebinarEnabled: (isEnabled: boolean) => {
      setIsWebinarEnabled(isEnabled);
    },
    updateAttendees: (attendees: number | undefined) => {
      if(attendees && !(attendees in defaultAttendeesValues)) {
        setCustomAttendees(attendees);
      } else {
        setCustomAttendees(undefined);
      }
    },
  });

  useEffect(() => {
    const newLists = _cloneDeep(localizedLists);

    // ---- Attendance
    newLists.attendeesOptions = [];
    const attendeesValues = [...defaultAttendeesValues];

    if(customAttendees) {
      attendeesValues.unshift(customAttendees);
    }

    for (const attendeeValue of attendeesValues) {
      newLists.attendeesOptions.push({
        label: intl.formatMessage(
          { id: "eventField.xAttendees" },
          {
            number: attendeeValue,
          }
        ),
        value: String(attendeeValue),
      });
    }

    // ---- Days
    newLists.daysOptions = [];
    if (daysConfig) {
      const minDays = daysConfig.min ?? daysConfig.default;
      const maxDays = daysConfig.max ?? daysConfig.default;

      for (let days = minDays; days <= maxDays; days++) {
        newLists.daysOptions.push({
          label: intl.formatMessage(
            { id: "eventField.xDayEvent" },
            {
              number: days,
            }
          ),
          value: String(days),
        });
      }
    }

    // ---- Tiers
    newLists.tierNames = [];
    newLists.tierNamesOptions = [];
    for (let i = 1; i <= 3; i++) {
      const tierName = intl.formatMessage(
        { id: "tierWithNumber" },
        { number: i }
      );
      newLists.tierNames.push(tierName);
      newLists.tierNamesOptions.push({ label: tierName, value: i.toString() });
    }

    // ---- Event Formats
    newLists.eventFormatOptions = [
      {
        label: intl.formatMessage({ id: "inPerson" }),
        value: EventFormat.InPerson,
      },
      {
        label: intl.formatMessage({ id: "virtual" }),
        value: EventFormat.Virtual,
      }
    ];

    // ---- Event Types
    newLists.eventTypeOptions = [
      {
        label: intl.formatMessage({ id: "eventType.hosted" }),
        value: EventType.hosted,
      },
      {
        label: intl.formatMessage({ id: "eventType.sponsored" }),
        value: EventType.sponsored,
      }
    ];

    if(isWebinarEnabled) {
      newLists.eventFormatOptions.push({
        label: intl.formatMessage({id: "webinar"}),
        value: EventFormat.Webinar,
      });
    }

    // ---- Months
    newLists.months = [];
    newLists.monthsOptions = [];
    for (const [monthIdx, monthKey] of monthsKeys.entries()) {
      const monthName = intl.formatMessage({ id: monthKey });
      newLists.months.push(monthName);
      newLists.monthsOptions.push({
        label: monthName,
        value: monthIdx.toString(),
      });
    }
    setLocalizedLists(newLists);
  }, [intl.locale, daysConfig, customAttendees]);

  return localizedLists;
}

export function simpleSelectOptionsMap(value: string): CGCSelectOption {
  return {
    label: value,
    value,
  };
}
