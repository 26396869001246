import styled from "styled-components";
import { StyleFontHeavy, ThemeColors } from "../../../../styles/theme";

// add to calendar view
// ${StyleGridGap}

const innerPadding = 15;

export const StyledEventCalendarMonth = styled.div`
  color: ${ThemeColors.CGBlack};
  margin: 1rem;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-gap: 2px;

  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
  @media print {
    border: 1px solid black;
    margin: 5px;
    page-break-inside: avoid;
  }
`;

export const StyledMonthHeader = styled.div`
  background-color: ${ThemeColors.GrayF7};
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  ${StyleFontHeavy}
  padding: ${innerPadding}px;
  @media print {
    border-bottom: 1px solid gray;
    padding: 5px;
  }
`;

export const StyledMonthContent = styled.div`
  background-color: ${ThemeColors.GrayE8};
  padding: ${innerPadding}px;
  display: grid;
  grid-auto-rows: 35px;
  grid-gap: 12px;
  min-height: 220px;
  
  @media print {
    padding: 5px;
    grid-gap: 4px;
  }
`;

export const StyledMonthFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2px;
  align-self: end;
  @media print {
    border-top: 1px solid gray
  }
`;

export const StyledMonthFooterCell = styled.div`
  background-color: ${ThemeColors.GrayF7};
  padding: ${innerPadding}px;
  ${StyleFontHeavy}
  font-size: 10px;
  text-transform: uppercase;
  @media print {
    padding: 5px;
  }
`;

export const StyledMonthName = styled.div`
  text-transform: uppercase;
`;
export const StyledCapacity = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  align-items: center;
  @media print {
    display: none;
  }
`;

export const StyledCapacityLabel = styled.div`
  font-size: 8px;
  letter-spacing: 0.57px;
  line-height: 11px;
  text-transform: uppercase;
`;

export interface StyledMonthMetaProps {
  utilization?: number;
  disabled?: boolean;
}

export const StyledCapacityIndicator = styled.div<StyledMonthMetaProps>`
  width: 25px;
  height: 25px;
  border: 4px solid white;
  border-radius: 25px;
  background-color: ${(props) => {
    if (props.disabled) {
      return ThemeColors.Black10;
    } else if (props.utilization) {
      if (props.utilization > 1) {
        return ThemeColors.CapacityRed;
      }
    }

    return ThemeColors.CapacityGreen;
  }}};
`;

const metaPadding = 0.75;
const innerMetaPadding = metaPadding / 2;

export const MonthlyEffortHours = styled.div`
  display: inline-block;
  padding: ${metaPadding}em ${innerMetaPadding}em ${metaPadding}em
    ${metaPadding}em;
`;

export const MonthlyEffortUtiliz = styled.div`
  display: inline-block;
  padding: ${metaPadding}em ${innerMetaPadding}em ${metaPadding}em
    ${metaPadding}em;
`;

export const StyledEvents = styled.div`
  margin: 1rem 0;
`;
