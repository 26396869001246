import React, {ReactElement} from "react";
import {StyledECLabel, StyledECValue, StyledEventCostTotal} from "./style";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {currencyOption} from "../../../../util/formatters";

type EventCostTotalProps = {
    total: number;
};


const EventCostTotal = ({total}: EventCostTotalProps): ReactElement => {

    return (
        <StyledEventCostTotal>
            <StyledECLabel>
                <FormattedMessage id="eventField.eventCostTotal" />
            </StyledECLabel>
            <StyledECValue>
                <FormattedNumber value={total} {...currencyOption} />
            </StyledECValue>
        </StyledEventCostTotal>
    );
};

export default EventCostTotal;
