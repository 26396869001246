import styled from "styled-components";
import {ThemeColors} from "../../../../styles/theme";

const padding = `2rem;`;
export const StyledGroupBox = styled.div`
 &:not(:last-child) {
   margin-bottom: ${padding};
 }
  
  &:last-child {
    height: 100%;
  }
`;

export const StyledGroupTitle = styled.h3`
  padding: 2rem 2rem 1rem;
  background-color: ${ThemeColors.White};
  margin: 0;
`;

export const StyledGroupBoxInner = styled.div`
  padding: ${padding};
`;
