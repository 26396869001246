import styled from "styled-components";
import {deviceWidths, StyleFontBook, ThemeColors} from "../../../../styles/theme";

export const StyledLandingMenu = styled.div`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-rows: 1fr 1fr;
  
  
  @media screen and ${deviceWidths.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1em;
    align-items: center;
    justify-items: start;
    max-width: 980px;
  }
`;

export const LandingDescription = styled.div`
  ${StyleFontBook}
  color: ${ThemeColors.Black};

  font-size: 20px;
  line-height: 30px;
  padding: 1.5rem;

  // @media screen and ${deviceWidths.tablet} {
  //   line-height: 27px;
  // }
  @media screen and ${deviceWidths.desktop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media screen and ${deviceWidths.max} {
    font-size: 20px;
    line-height: 30px;
  }
`;
