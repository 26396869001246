import _cloneDeep from "lodash.clonedeep";
import set from 'date-fns/set';
import {CGAPClientGroupOption, CGAPConfig, CGAPEvent} from "../types/annual-planner";
import previousYearData from "./previous-year-data.json";
import {updateEventCost} from "./formulas";

export function getAvailableBaseYears(): string[] {
  return Object.keys(previousYearData);
}

export function getEventMixAndBaseYear(
  previousYear: string,
  newYear: number,
  clientGroupOption: CGAPClientGroupOption,
  config: CGAPConfig | undefined,
): [CGAPEvent[], string] {
  let newMix: CGAPEvent[];
  let baseYear: string;

  if (
    previousYear?.length &&
    Object.prototype.hasOwnProperty.call(previousYearData, previousYear)
  ) {
    newMix = _cloneDeep(previousYearData[previousYear]);
    baseYear = previousYear;
  } else {
    newMix = [];
    baseYear = "(scratch)";
  }

  if (newMix?.length) {
    if (clientGroupOption) {
      if (clientGroupOption.segmentFilter) {
        baseYear = `${baseYear} ${clientGroupOption.segmentFilter}`;
        newMix = newMix.filter(
          (event) =>
            event.clientGroup === clientGroupOption.groupFilter &&
            event.clientSegment === clientGroupOption.segmentFilter
        );
      } else {
        baseYear = `${baseYear} ${clientGroupOption.groupFilter}`;
        newMix = newMix.filter(
          (event) => event.clientGroup === clientGroupOption.groupFilter
        );
      }
    }

    newMix = newMix.map((event) => {
      const { date } = event;
      event.date = set(new Date(date), { year: newYear });
      return updateEventCost(config, event);
    });
  }

  return [newMix, baseYear];
}
