
import styled from "styled-components";
import {StyleH3Header} from "../../styles/theme";
import {makeTransition} from "../../styles/animations";

export interface LoadingConfigurationProps {
    isVisible: boolean;
}

export const StyledLoadingConfiguration = styled.div<LoadingConfigurationProps>`    
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    opacity: ${(props) => props.isVisible ? 1 : 0};
    ${makeTransition(['opacity'])}
`;
export const StyledLoadingConfigurationWrap = styled.div`
    display: grid;
    align-items: center;
    justify-items: center;
    grid-gap: 1rem;
`;

export const StyledLoadingMessage = styled(StyleH3Header)``;
