import styled from "styled-components";
import landingImage from "../../assets/landing.jpg";
import {
  deviceWidths,
  ThemeColors,
} from "../../styles/theme";
import {
  cgHeaderHeightDesktop,
  cgHeaderHeightTablet,
  titleHeroHeightDesktop,
  titleHeroHeightTablet
} from "../../layouts/base/style";
import { makeTransition } from "../../styles/animations";

export const StyledLandingPage = styled.div`
  color: ${ThemeColors.CGBlack};
  width: 100%;
  min-height: calc(100vh - ${cgHeaderHeightTablet} - ${titleHeroHeightTablet});
  position: relative;
  background: url(${landingImage}) no-repeat left top;
  background-size: cover;
  
  @media screen and ${deviceWidths.desktop} {
    width: 100%;
    min-height: calc(100vh - ${cgHeaderHeightDesktop} - ${titleHeroHeightDesktop});
  }
`;

export interface LandingWizardContainerProps {
  isVisible: boolean;
}

export const LandingWizardContainer = styled.div<LandingWizardContainerProps>`
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  align-items: center;
  justify-items: center;
  opacity: ${(props) => props.isVisible ? 1 : 0};
  ${makeTransition(['opacity'])}
`;
