import React, {ReactElement} from "react";
import {StyledGroupBox, StyledGroupBoxInner, StyledGroupTitle} from "./style";

type FormGroupProps = {
    title: string;
    children?: React.ReactNode;
};


const GroupBox = ({title, children}: FormGroupProps): ReactElement => {
    return (
        <StyledGroupBox>
            <StyledGroupTitle>{title}</StyledGroupTitle>
            <StyledGroupBoxInner>{children}</StyledGroupBoxInner>
        </StyledGroupBox>
    );
};

export default GroupBox;
