import styled from "styled-components";
import { StyledModal, StyledModalActions } from "../../styles/modals";
import { ThemeColors } from "../../styles/theme";

export const StyledSummaryModal = styled(StyledModal)`
  @media screen {
    width: 90vw;
    max-height: 75vh;
    display: grid;
    grid-template-rows: auto 1fr 75px;
  }

  @media print {
    width: 10in;
  }
`;

export const StyledSummaryHero = styled.div``;

export const StyledSummaryContent = styled.div`
  overflow-x: hidden;
  overflow-y: auto;

  padding-right: 35px;

  & > * {
    margin-bottom: 35px;
  }
`;
export const StyledSummaryActions = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  background-color: ${ThemeColors.White};

  @media print {
    display: none;
  }
`;

export const StyledSummaryButtons = styled(StyledModalActions)`
  margin: 1em 0 0 0;
`;
