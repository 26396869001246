import React, { ReactElement, useContext, useEffect, useState } from "react";
import {
  StyledSegmentShareCell,
  StyledSegmentShareHeaderCell,
  StyledSegmentShareHeaderRow,
  StyledSegmentShareRow,
  StyledSegmentShareTable,
  StyledSegmentShareTypeHeaderCell,
} from "./style";
import { currencyOption, percentOptionWhole } from "../../../util/formatters";
import { store } from "../../../store";
import { FormattedNumber, useIntl } from "react-intl";
import {CGAPCostWithShareForTypes} from "../../../types/annual-planner";

type SegmentShareTableProps = {
  prop?: unknown;
};

interface SegmentShare extends CGAPCostWithShareForTypes {
  label: string;
}

const SegmentShareTable = ({}: SegmentShareTableProps): ReactElement => {
  const { state } = useContext(store);
  const intl = useIntl();

  const [segmentTotals, setSegmentTotals] = useState<SegmentShare[]>([]);

  useEffect(() => {
    if (state?.eventsSummary.clientGroupTotal) {
      const newSegmentTotals = Object.keys(
        state?.eventsSummary.clientGroupTotal.groups
      ).map((clientSegment) => ({
        ...state.eventsSummary.clientGroupTotal.groups[clientSegment],
        label: intl.formatMessage(
          { id: "summaryModal.totalXCost" },
          { segmentLabel: clientSegment }
        )
      }));

      newSegmentTotals.push({
        ...state.eventsSummary.clientGroupTotal.ungrouped,
        label: intl.formatMessage({ id: "noSegment" }),
      });

      setSegmentTotals(newSegmentTotals);
    }
  }, [state?.eventsSummary.clientGroupTotal]);

  return (
    <StyledSegmentShareTable>
      <StyledSegmentShareHeaderRow>
        <StyledSegmentShareTypeHeaderCell style={{gridArea: "type1"}}>
          {intl.formatMessage({ id: "hostedEvents" })}
        </StyledSegmentShareTypeHeaderCell>
        <StyledSegmentShareTypeHeaderCell style={{gridArea: "type2"}}>
          {intl.formatMessage({ id: "sponsoredEvents" })}
        </StyledSegmentShareTypeHeaderCell>
        <StyledSegmentShareHeaderCell style={{gridArea: "groupLabel"}}>
          {intl.formatMessage({ id: "clientSegment" })}
        </StyledSegmentShareHeaderCell>
        <StyledSegmentShareHeaderCell style={{gridArea: "type1cost"}}>
          {intl.formatMessage({ id: "costEstimate" })}
        </StyledSegmentShareHeaderCell>
        <StyledSegmentShareHeaderCell style={{gridArea: "type1perc"}}>
          {intl.formatMessage({ id: "percentExpenditure" })}
        </StyledSegmentShareHeaderCell>
        <StyledSegmentShareHeaderCell style={{gridArea: "type2cost"}}>
          {intl.formatMessage({ id: "costEstimate" })}
        </StyledSegmentShareHeaderCell>
        <StyledSegmentShareHeaderCell style={{gridArea: "type2perc"}}>
          {intl.formatMessage({ id: "percentExpenditure" })}
        </StyledSegmentShareHeaderCell>
      </StyledSegmentShareHeaderRow>
      {segmentTotals.length
        ? segmentTotals.map((share, idx) => (
            <StyledSegmentShareRow key={`segment-${idx}`}>
              <StyledSegmentShareCell>{share.label}</StyledSegmentShareCell>
              <StyledSegmentShareCell>
                <FormattedNumber value={share.hosted.cost} {...currencyOption} />
              </StyledSegmentShareCell>
              <StyledSegmentShareCell>
                <FormattedNumber value={share.hosted.share} {...percentOptionWhole} />
              </StyledSegmentShareCell>
              <StyledSegmentShareCell>
                <FormattedNumber value={share.sponsored.cost} {...currencyOption} />
              </StyledSegmentShareCell>
              <StyledSegmentShareCell>
                <FormattedNumber value={share.sponsored.share} {...percentOptionWhole} />
              </StyledSegmentShareCell>
            </StyledSegmentShareRow>
          ))
        : null}
    </StyledSegmentShareTable>
  );
};

export default SegmentShareTable;
