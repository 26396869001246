import React, {ReactElement} from "react";
import {LandingDescription, StyledLandingMenu} from "./style";
import {CGAPLandingWizardStep} from "../../types";
import {FormattedMessage} from "react-intl";
import LandingActionBox from "../landing-action-box";
import { OutlineButtonWithIcon } from "../../../../styles/buttons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

type LandingMenuProps = {
    setStep: (step: CGAPLandingWizardStep) => void;
};


const LandingMenu = ({setStep}: LandingMenuProps): ReactElement => {

    const handleClick = (step: CGAPLandingWizardStep): boolean => {
        setStep(step);
        return false;
    }

    return (
        <StyledLandingMenu>
            <LandingDescription>
                <FormattedMessage id="landing.menu.description"/>
            </LandingDescription>
            <LandingActionBox actionsI18nPrefix="landing.menu.actions">
                <OutlineButtonWithIcon
                    style={{width: "100%"}}
                    onClick={() => handleClick(CGAPLandingWizardStep.loadPreviousPlan)}>
                    <FormattedMessage id="landing.menu.actions.load-previous-plan"/>
                    <ArrowForwardIcon/>
                </OutlineButtonWithIcon>
                <OutlineButtonWithIcon
                    style={{width: "100%"}}
                    onClick={() => handleClick(CGAPLandingWizardStep.newPlan)}>
                    <FormattedMessage id="landing.menu.actions.start-new-plan"/>
                    <ArrowForwardIcon/></OutlineButtonWithIcon>
            </LandingActionBox>
        </StyledLandingMenu>
    );
};

export default LandingMenu;
