import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { StyledPlanningView, StyledPrintView, StyledScreenView } from "./style";
import ActionRibbonTop from "../../components/action-ribbon-top";
import ActionRibbonBottom from "../../components/action-ribbon-bottom";
import EventsByTierView from "../../components/events-by-tier";
import { StyledFloatingContent, StyledSiteMaxWidth } from "../../styles/theme";
import { store } from "../../store";
import {CGAPPlannerView, EventType} from "../../constants";
import EventsByCalendarView from "../../components/events-by-calendar";
import EditEventModal from "../../components/event-modal";
import { PlanningContext } from "./context";
import { CGAPEventModalState } from "../../components/event-modal/types";
import SummaryModal from "../../components/summary-modal";
import CalendarYear from "../../components/events-by-calendar/components/calendar-year";
import SegmentShareTable from "../../components/summary-modal/segment-share-table";
import PressureTestModal from "../../components/pressure-test-modal";
import ConfirmationModal from "../../components/confirmation-modal";
import Scorebox from "../../components/scorebox";
import { CGAPPlanningViewFilters } from "../../types/annual-planner";

type PlanningViewProps = {
  prop?: unknown;
};

function viewFiltersFromEventTypes(eventTypes): CGAPPlanningViewFilters {
  return {
    hosted: eventTypes?.hosted || false,
    sponsored: eventTypes?.sponsored || false,
    all: (eventTypes?.hosted && eventTypes?.sponsored) || false,
  };
}

const PlanningView = ({}: PlanningViewProps): ReactElement => {
  const intl = useIntl();
  const { state } = useContext(store);
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  const [eventModalState, setEventModalState] = useState<CGAPEventModalState>({
    isVisible: false,
    isAdding: false,
  });
  const [showPressureTest, setShowPressureTest] = useState(false);
  const [confirmPressureTest, setConfirmPressureTest] = useState(false);

  const [viewFilters, setViewFilters] = useState<CGAPPlanningViewFilters>(() =>
    viewFiltersFromEventTypes(state?.planOptions?.eventTypes)
  );
  const [userChangedViewFilters, setUserChangedViewFilters] = useState(false);

  useEffect(() => {
    setViewFilters(viewFiltersFromEventTypes(state?.planOptions?.eventTypes));
  }, [state?.planOptions?.eventTypes]);

  const updateViewFilters = (key: "hosted" | "sponsored", value: boolean) => {
    const updatedViewFilters = {
      ...viewFilters,
    };

    updatedViewFilters[key] = value;
    updatedViewFilters.all =
      updatedViewFilters.sponsored && updatedViewFilters.hosted;
    setViewFilters(updatedViewFilters);

    if (!userChangedViewFilters) {
      setUserChangedViewFilters(true);
    }
  };

  const showSummary = () => {
    if (state?.events && state?.events.length > 0) {
      const hasTier1Events =
        state.events.filter((event) => event.tier === 1 && event.type !== EventType.sponsored).length > 0;

      if (hasTier1Events) {
        setConfirmPressureTest(true);
      } else {
        setSummaryModalVisible(true);
      }
    }
  };

  return (
    <PlanningContext
      value={{
        eventModalState,
        setEventModalState,
        summaryModalVisible,
        setSummaryModalVisible,
        showSummary,
        viewFilters,
        updateViewFilters,
      }}
    >
      <StyledPlanningView>
        <StyledScreenView>
          <StyledSiteMaxWidth>
            <ActionRibbonTop />
            {/* I'm seeing this as an animate L, animate R on change bit, but just a note for now */}
            {state?.planningView === CGAPPlannerView.EVENTS_BY_TIER && (
              <EventsByTierView />
            )}
            {state?.planningView === CGAPPlannerView.EVENTS_BY_CALENDAR && (
              <EventsByCalendarView />
            )}
            <Scorebox />
            <ActionRibbonBottom />
          </StyledSiteMaxWidth>
          <EditEventModal
            {...eventModalState}
            setEventModalState={setEventModalState}
          />
          <SummaryModal
            isOpen={summaryModalVisible}
            closeModalWithDisposition={() => {
              setSummaryModalVisible(false);
            }}
          />
          <PressureTestModal
            isOpen={showPressureTest}
            onCtaConfirm={() => {
              setShowPressureTest(false);
              setSummaryModalVisible(true);
            }}
            onCtaGoBack={() => {
              setShowPressureTest(false);
            }}
          />
          <ConfirmationModal
            isOpen={confirmPressureTest}
            title={intl.formatMessage({ id: "pressureTestModal.Title" })}
            bodyCopy={intl.formatMessage({
              id: "pressureTestModal.Description",
            })}
            confirmText={intl.formatMessage({
              id: "pressureTestModal.actions.confirm",
            })}
            cancelText={intl.formatMessage({
              id: "pressureTestModal.actions.cancel",
            })}
            closeModalWithDisposition={(shouldPressureTest) => {
              setConfirmPressureTest(false);
              setShowPressureTest(shouldPressureTest);
            }}
          />
        </StyledScreenView>
        <StyledPrintView>
          <EventsByTierView disableActions={true} />
          <StyledFloatingContent>
            <CalendarYear />
          </StyledFloatingContent>
          <SegmentShareTable />
          <Scorebox />
        </StyledPrintView>
      </StyledPlanningView>
    </PlanningContext>
  );
};

export default PlanningView;
