import React, { ReactElement, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  StyledEventCalendarMonth,
  StyledCapacityIndicator,
  StyledMonthName,
  StyledMonthHeader,
  StyledMonthFooter,
  StyledMonthFooterCell,
  StyledMonthContent,
  StyledCapacity,
  StyledCapacityLabel,
} from "./style";
import { CGAPEvent } from "../../../../types/annual-planner";
import { monthsKeys } from "../../../../constants";
import { CGAPCalendarListItem } from "../../types";
import CalendarEvent from "../calendar-event";
import { store } from "../../../../store";
import {
  numberFormatter,
  percentWholeFormatter,
} from "../../../../util/formatters";
import { planningContext } from "../../../../views/planning/context";

type CGAPCalendarItemProps = {
  calendarData: CGAPCalendarListItem;
};

const EventCalendarMonth = ({
  calendarData,
}: CGAPCalendarItemProps): ReactElement => {
  const { state } = useContext(store);
  const { viewFilters } = useContext(planningContext);
  const intl = useIntl();
  const monthlyEffort =
    state?.eventsSummary?.monthlyEffort[calendarData.monthNumber];
  const isGroupNotSegment =
    state?.planOptions?.clientGroup?.groupFilter &&
    !state?.planOptions?.clientGroup?.segmentFilter;
  const isUtilizationEnabled = viewFilters.hosted && isGroupNotSegment;
  const isCapacityEnabled = viewFilters.hosted;

  return (
    <StyledEventCalendarMonth>
      <StyledMonthHeader>
        <StyledMonthName>
          {monthsKeys[calendarData.monthNumber]}
        </StyledMonthName>
        <StyledCapacity>
          <StyledCapacityLabel>
            {intl.formatMessage({ id: "capacity" })}
          </StyledCapacityLabel>
          <StyledCapacityIndicator
            utilization={monthlyEffort?.utilization}
            disabled={!isUtilizationEnabled}
          />
        </StyledCapacity>
      </StyledMonthHeader>
      <StyledMonthContent>
        {calendarData.events.map((event: CGAPEvent, idx) => (
          <CalendarEvent key={`event-idx-${idx}`} event={event} />
        ))}
      </StyledMonthContent>
      {monthlyEffort && (
        <StyledMonthFooter>
          <StyledMonthFooterCell>
            {isCapacityEnabled && (
              <FormattedMessage
                id="totalHours"
                values={{ hours: numberFormatter.format(monthlyEffort.effort) }}
              />
            )}
            {!isCapacityEnabled && <FormattedMessage id="totalHoursDisabled" />}
          </StyledMonthFooterCell>
          <StyledMonthFooterCell>
            {isUtilizationEnabled && (
              <FormattedMessage
                id="percentage"
                values={{
                  percentage: percentWholeFormatter.format(
                    monthlyEffort.utilization
                  ),
                }}
              />
            )}
            {!isUtilizationEnabled && (
              <FormattedMessage id="percentageDisabled" />
            )}
          </StyledMonthFooterCell>
        </StyledMonthFooter>
      )}
    </StyledEventCalendarMonth>
  );
};

export default EventCalendarMonth;
