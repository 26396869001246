import styled from "styled-components";
import {StyleFontMedium, ThemeColors} from "../../styles/theme";
import {makeTransition} from "../../styles/animations";


interface StyledAlertBarProps {
    isVisible: boolean;
}

export const StyledAlertBar = styled.div<StyledAlertBarProps>`
  ${StyleFontMedium}
  background-color: ${ThemeColors.Sapphire};
  color: ${ThemeColors.White};
  padding: 1rem;
  width: 40vw;
  
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: start;
  grid-gap: 30px;
  
  position: fixed;
  bottom: ${(props) => props.isVisible ? '30px' : '-400px'};
  opacity: ${(props) => props.isVisible ? '1' : '0'};
  left: 50%;
  margin-left: -20vw;
  box-shadow: 0 4px 4px 0 rgba(177, 177, 177, 0.5);
  ${makeTransition(['opacity', 'bottom'], 1)}
`;

export const StyledAlertMessage = styled.div`

`;
