import _get from "lodash.get";
import {CGAPConfig, CGAPTierConfig} from "../types/annual-planner";
import {CGCAddonsConfig, CGCInPersonCosts, CGCTierConfig, CGCVirtualCosts} from "../types/common";
import {EventFormat} from "../constants";

export function getTierKey(tier: number): string {
    if (tier >= 1 && tier <= 3) {
        return `tier${tier}`;
    }

    return "";
}

/**
 * To prevent errors and to move all the changes into one place, this builds the
 * text selector for `_get` using the provided pieces.
 *
 * @param tierNumber 1, 2, 3
 * @param type
 * @param additional dot-notated string describing the rest of the struct that _get should. No leading dot please.
 * @param fromCommon Whether the string should make use of the "common" config field
 */
export function makeTierSelector(tierNumber: number, type?: EventFormat, additional?: string, fromCommon = false): string {
    let tierSelector = `tiers.tier${tierNumber}`;

    if(fromCommon) {
        tierSelector = `common.${tierSelector}`
    }

    if(typeof type !== 'undefined') {
        tierSelector = `${tierSelector}.${type}`;
    }

    if(typeof additional !== 'undefined') {
        tierSelector = `${tierSelector}.${additional}`;
    }

    return tierSelector;
}

export function getTierConfig(config: CGAPConfig, tierNumber: number): CGAPTierConfig | undefined {
    return _get(config, makeTierSelector(tierNumber));
}

export function getCommonTierConfig(config: CGAPConfig, tierNumber: number): CGCTierConfig | undefined {
    return _get(config, makeTierSelector(tierNumber, undefined, undefined, true));
}

export function getTierCosts(config: CGAPConfig, tierNumber: number, type: EventFormat): CGCInPersonCosts | CGCVirtualCosts | undefined {
    return _get(config, makeTierSelector(tierNumber, type, undefined, true));
}

export function getTierCostsDisclaimer(config: CGAPConfig, tierNumber: number, type: EventFormat): string {
    return _get(config, makeTierSelector(tierNumber, type, 'disclaimer', true)) ?? '';
}

export function getTierAddonsConfig(config: CGAPConfig, tierNumber: number, type: EventFormat): CGCAddonsConfig | undefined {
    return _get(config, makeTierSelector(tierNumber, type, 'addons', true));
}
