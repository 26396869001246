import React, { ReactElement, useContext } from "react";
import { useIntl } from "react-intl";
import _set from "lodash.set";
import CurrencyTextInput from "../../../currency-text-input";
import { StyledFormElement, StyledGridColumns } from "../../style";
import { budgetPlanningContext } from "../../context";
import EventCostTotal from "../event-cost-total";
import OverrideCheckbox from "./override-methodology";
import _cloneDeep from "lodash.clonedeep";
import {EventFormActionType} from "../../types";

const BudgetPlanningHostedInPerson = (): ReactElement => {
  const intl = useIntl();
  const { eventFormState, eventFormDispatch } = useContext(budgetPlanningContext);

  return (
    <StyledGridColumns count={2}>
        <StyledFormElement>
            <OverrideCheckbox/>
        </StyledFormElement>
      <StyledFormElement>&nbsp;</StyledFormElement>
      <StyledFormElement>
        <CurrencyTextInput
          label={intl.formatMessage({ id: "eventField.costPerPerson" })}
          plainvalue={eventFormState.event?.cost?.hosted?.inPerson?.perPerson || 0}
          disabled={!eventFormState.event?.cost?.overrideMethodology}
          fullWidth
          size="small"
          onChange={(e) => {
              if (eventFormState?.event) {
                  const updatingEvent = _cloneDeep(eventFormState.event);
                  _set(updatingEvent, 'cost.hosted.inPerson.perPerson', e.target.value);

                  eventFormDispatch({
                      type: EventFormActionType.UPDATE_EVENT,
                      payload: updatingEvent,
                  });
              }
          }}
        />
      </StyledFormElement>
      <StyledFormElement>
        <EventCostTotal total={eventFormState?.event?.cost?.total || 0} />
      </StyledFormElement>
    </StyledGridColumns>
  );
};

export default BudgetPlanningHostedInPerson;
