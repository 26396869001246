import React, {ReactElement} from "react";
import {StyledContent, StyledHeader, StyledLabeledBox, StyledLabeledBoxProps} from "./style";

export type LabeledBoxProps = {
    label: string;
    content: string;
};


const LabeledBox = ({label, content, ...htmlProps}: LabeledBoxProps & StyledLabeledBoxProps): ReactElement => {

    return (
        <StyledLabeledBox {...htmlProps}>
            <StyledHeader>{label}</StyledHeader>
            <StyledContent>{content}</StyledContent>
        </StyledLabeledBox>
    );
};

export default LabeledBox;
