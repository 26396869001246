import React, {ReactElement} from "react";
import {StyledLAB, StyledLABContent, StyledLABInstructions, StyledLABTitle} from "./style";
import {FormattedMessage} from "react-intl";
import {CGCDisableable} from "../../../../types/common";

interface LandingActionBoxProps extends CGCDisableable {
    actionsI18nPrefix: string;
    children?: React.ReactNode;
}

const LandingActionBox = ({actionsI18nPrefix, children, disabled}: LandingActionBoxProps): ReactElement => {

    return (
        <StyledLAB disabled={disabled}>
            <StyledLABTitle>
                <FormattedMessage id={`${actionsI18nPrefix}.title`}/>
            </StyledLABTitle>
            <StyledLABInstructions>
                <FormattedMessage id={`${actionsI18nPrefix}.instruction`}/>
            </StyledLABInstructions>
            <StyledLABContent>
                {children}
            </StyledLABContent>
        </StyledLAB>
    );
};

export default LandingActionBox;
