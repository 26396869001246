import {CGCConfig, CGCSelectOption} from "./common"
import {EventFormat, EventType} from "../constants";

export interface CheckboxOption extends CGCSelectOption {
  disclaimer?: string;
  isDisabled?: boolean;
}

export interface CGAPPlanningViewFilters {
  hosted: boolean;
  sponsored: boolean;
  all: boolean;
}

export interface CGAPTierConfig {
  monthlyHours: number[];
  planningWeeks: number;
}

export interface CGAPBudgets {
  hosted: number;
  sponsored: number;
  total?: number;
}

export interface CGAPEventTypesEnabled {
  hosted: boolean;
  sponsored: boolean;
}

export interface CGAPConfigClientGroup {
  budget: CGAPBudgets;
  capacityShare: number;
  label: string;
  segments: string[];
}

export interface CGAPClientGroupOption {
  budget: CGAPBudgets;
  capacityShare: number;
  label: string;
  groupFilter: string;
  segmentFilter: string | null;
}

export interface CGAPPlanOptions {
  budget: CGAPBudgets | null;
  clientGroup: CGAPClientGroupOption | null;
  baseYear: string | null;
  baseYearEventCount: number;
  eventTypes: CGAPEventTypesEnabled;
}

export interface CGAPConfig {
  clientGroups: CGAPConfigClientGroup[];
  team: {
    monthlyHoursCapacity: number[];
  };
  tiers: Record<string, CGAPTierConfig>;
  common?: CGCConfig;
}

export interface CGAPEventHostedInPersonCost {
  perPerson: number;
}

export interface CGAPEventHostedVirtualCost {
  perPerson: number;
  productionPerDay: number;
}

export interface CGAPEventHostedCosts {
  inPerson?: CGAPEventHostedInPersonCost;
  virtual?: CGAPEventHostedVirtualCost;
}

export interface CGAPEventSponsoredCosts {
  flat: number;
}

export interface CGAPEventCost {
  total: number;
  overrideMethodology: boolean;
  hosted?: CGAPEventHostedCosts;
  sponsored?: CGAPEventSponsoredCosts;
}

export interface CGAPEvent {
  tier: number;
  title: string;
  date: Date;
  peopleCount: number;
  budgetSource: string;
  clientGroup: string;
  clientSegment: string;
  dayCount: number;
  type: EventType;
  format: EventFormat;
  locationId: string;
  locationDisplay: string;
  // -- items below are derived
  id?: string; // should be uuid if defined
  addons: string[];
  effort?: CGAPCostWithShare[];
  cost: CGAPEventCost;
}

export type CGAPEventFieldType = string | number | boolean;

export interface CGAPCapacityRenderedEventTotals {
  capacity: number;
  effort: number;
  utilization: number;
  utilizationClass: string;
}
export interface CGAPCapacityRenderedEvents {
  events: CGAPEvent[];
  totals: CGAPCapacityRenderedEventTotals[];
}

export interface CGAPCostWithShare {
  cost: number; // e.g. ddddd.dd
  share: number; // will be 0..1
}

export interface CGAPCostWithShareForTypes {
  hosted: CGAPCostWithShare;
  sponsored: CGAPCostWithShare;
}

export function getInitialCostWithShare(): CGAPCostWithShare {
  return {
    cost: 0,
    share: 0,
  };
}

export function getInitialCostWithShareAndType(): CGAPCostWithShareForTypes {
  const initialCostWithShare = getInitialCostWithShare();
  return {
    hosted: {
      ...initialCostWithShare,
    },
    sponsored: {
      ...initialCostWithShare,
    }
  };
}

export interface CGAPTierCount {
  inPerson: number;
  virtual: number;
  total: number;
}

export function getInitialTierCount(): CGAPTierCount {
  return {
    inPerson: 0,
    virtual: 0,
    total: 0,
  };
}

export interface CGAPEffortCapacityUtilization {
  effort: number;
  capacity: number;
  utilization: number;
}

export function getInitialEffortCapacityUtilization(): CGAPEffortCapacityUtilization {
  return {
    effort: 0,
    capacity: 0,
    utilization: 0,
  };
}

export type CGAPCount = {
  total: number;
  groups: Record<string, CGAPCount>;
}

export interface CGAPEventsSummary {
  count: CGAPCount;
  cost: CGAPCount;
  costByType: CGAPBudgets;
  monthlyEffort: CGAPEffortCapacityUtilization[];
  clientGroupTotal: {
    groups: {
      [key: string]: CGAPCostWithShareForTypes;
    };
    ungrouped: CGAPCostWithShareForTypes;
  };
  events: CGAPEvent[];
  eventTypeTotal: Record<string, CGAPCostWithShare>;
  recommendations: string[];
  tierCountTotals: Record<string, CGAPTierCount>;
  tierCostTotals: Record<string, CGAPTierCount>;
}

export interface CGAPGroupAndSegmentOption {
  group: string;
  segment: string;
  label: string;
  id: string;
}

export interface CGAPIsVisible {
  isVisible: boolean;
}

export function makeBlankCount(): CGAPCount {
  return {
    total: 0,
    groups: {},
  }
}

export function makeInitialCount(): CGAPCount {
  const formats = [EventFormat.InPerson.toString(), EventFormat.Virtual.toString()];
  const tiers = ['tier1', 'tier2', 'tier3'];
  const types = [EventType.hosted.toString(), EventType.sponsored.toString()];

  const rootCount: CGAPCount = makeBlankCount();

  for(const format of formats) {
    rootCount.groups[format] = makeBlankCount();

    for(const tier of tiers) {
      rootCount.groups[format].groups[tier] = makeBlankCount();

      for (const type of types) {
        rootCount.groups[format].groups[tier].groups[type] = makeBlankCount();
      }
    }
  }

  return rootCount;
}

export function getEventSummaryInitial(): CGAPEventsSummary {
  return {
    count: makeInitialCount(),
    cost: makeInitialCount(),
    costByType: {
      hosted: 0,
      sponsored: 0,
    },
    events: [],
    monthlyEffort: [
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
      getInitialEffortCapacityUtilization(),
    ],
    clientGroupTotal: {
      groups: {},
      ungrouped: getInitialCostWithShareAndType(),
    },
    eventTypeTotal: {
      inPerson: getInitialCostWithShare(),
      virtual: getInitialCostWithShare(),
      webinar: getInitialCostWithShare(),
    },
    recommendations: [],
    tierCountTotals: {
      tier1: getInitialTierCount(),
      tier2: getInitialTierCount(),
      tier3: getInitialTierCount(),
      total: getInitialTierCount(),
    },
    tierCostTotals: {
      tier1: getInitialTierCount(),
      tier2: getInitialTierCount(),
      tier3: getInitialTierCount(),
      total: getInitialTierCount(),
    },
  };
}
