import React, {ReactElement} from "react";
import {StyledLoadPlan} from "./style";
import LandingActionBox from "../landing-action-box";

type LoadPlanProps = {
    prop?: unknown;
};


const LoadPlan = ({}: LoadPlanProps): ReactElement => {
    return (
        <StyledLoadPlan>
            <LandingActionBox actionsI18nPrefix="landing.load-previous-plan">
                TODO: show a loader here
            </LandingActionBox>
        </StyledLoadPlan>
    );
};

export default LoadPlan;
