import styled, { css } from "styled-components";

export enum ThemeColors {
  // Primary / Global
  Sapphire = "#005F9E",
  Ocean = "#009cdc",
  Raspberry = "#b42573",
  Turquoise = "#00aea9",

  // Secondary
  DarkSapphire = "#00294b",
  DarkTurquoise1 = "#004c46",
  DarkTurquoise2 = "#008e77",
  DarkRaspberry1 = "#762157",
  DarkRaspberry2 = "#532a45",
  LightOcean = "#7bd0e2",
  Neutral1 = "#e3e1dc",
  Neutral2 = "#d5d0ca",
  Neutral3 = "#beb7b3",
  Neutral4 = "#aca39a",
  Neutral5 = "#7d726d",
  Neutral6 = "#63544f",
  Neutral7 = "#554742",
  White = "rgba(255, 255, 255, 1)",
  White80 = "rgba(255, 255, 255, 0.8)",
  White50 = "rgba(255, 255, 255, 0.5)",
  White30 = "rgba(255, 255, 255, 0.3)",
  White10 = "rgba(255, 255, 255, 0.1)",
  White03 = "rgba(255, 255, 255, 0.03)",
  Black = "rgba(0, 0, 0, 1)",
  Black80 = "rgba(0, 0, 0, 0.8)",
  Black50 = "rgba(0, 0, 0, 0.5)",
  Black30 = "rgba(0, 0, 0, 0.3)",
  Black10 = "rgba(0, 0, 0, 0.1)",
  Black03 = "rgba(0, 0, 0, 0.03)",
  Gray66 = "#666666",
  GrayB2 = "#b2b2b2",
  GrayE5 = "#e5e5e5",
  GrayE8 = "#E8E8E8",
  GrayF7 = "#F7F7F7",
  Mustard = "#AD8C52",

  // Text
  // Black50 = "#7f7f7f",
  CGBlack = "#333333",
  AlertRed = "#d91943",

  // Capacity
  CapacityGreen = "#259900",
  CapacityRed = "#990000",
  CapacityYellow = "#998500",
}

/*
L / Desktop / 1440px - 1025px
M / Tablet / 1024px to 768px
S / Mobile / 767px to 320px
 */

export const height = {
  desktop: "570px",
  tablet: "570px",
  mobile: "570px",
  mobileMax: "950px",
};

export const width = {
  max: "1440px",
  desktop: "1025px",
  tablet: "768px",
  mobile: "767px",
  min: "320px",
};

export const deviceWidths = {
  max: `(min-width: ${width.max})`,
  desktop: `(min-width: ${width.desktop})`,
  tablet: `(min-width: ${width.tablet})`,
  mobile: `(max-width: ${width.mobile})`,
};

export const deviceHeights = {
  desktop: `(min-height: ${height.desktop})`,
  tablet: `(min-height: ${height.tablet})`,
  mobile: `(min-height: ${height.mobile})`,
};

export const PAGE_LR_PADDING = 60;
export const PageLRPadding = css`
  padding-left: ${PAGE_LR_PADDING}px;
  padding-right: ${PAGE_LR_PADDING}px;
`;

export const StyleFontBook = css`
  font-family: "Avenir Book", Arial, sans-serif;
  letter-spacing: 0.8px;
  line-height: 1.2em;
  font-weight: normal;
`;

export const StyleFontRoman = css`
  font-family: "Avenir", Arial, sans-serif;
  font-size: 14px;
  line-height: 1.25em;
  font-weight: normal;
`;

export const StyleFontMedium = css`
  font-family: Avenir Medium, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: normal;
  letter-spacing: 0.35px;
`;

export const StyleFontHeavy = css`
  font-family: Avenir Heavy, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2em;
  font-weight: normal;
`;

export const StyleGridGap = css`
  grid-gap: 2px;

  @media screen and ${deviceWidths.tablet} {
    grid-gap: 15px;
  }
`;

export const StyleGridHeaderUnderline = css`
  border-bottom: 2px solid black;
`;

export const StyleGridHeaderText = css`
  ${StyleFontHeavy}
  text-transform: uppercase;
  color: ${ThemeColors.Black50};
`;

export const StyleRowAlternateColors = css`
  &:nth-child(even) {
    background-color: ${ThemeColors.Black03};
  }
`;

export const StyleSiteMaxWidth = css`
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  width: device-width;

  @media screen and ${deviceWidths.tablet} {
    width: ${width.tablet};
  }

  @media screen and ${deviceWidths.desktop} {
    width: ${width.desktop};
  }

  @media screen and ${deviceWidths.max} {
    width: ${width.max};
  }
`;

export const StyledSiteMaxWidth = styled.div`
  ${StyleSiteMaxWidth}
`;

export const StyledFloatingContent = styled.div`
  background: ${ThemeColors.White};
  border: 1px solid ${ThemeColors.GrayE5};
  box-shadow: 0 4px 4px 0 rgba(177, 177, 177, 0.5);
  page-break-after: always;
  @media print {
    box-shadow: none;
    page-break-after: auto;
    margin-bottom: 50px;
    @page {
      size: auto;
      margin: 20px;
    }
  }
`;

export const StyledH1Header = styled.h1`
  ${StyleFontBook}
  margin: 0 0 0.25em 0;
  font-size: 20px;
  letter-spacing: 1px;

  @media screen and ${deviceWidths.tablet} {
    font-size: 30px;
    letter-spacing: 2px;
  }

  @media screen and ${deviceWidths.desktop} {
    font-size: 40px;
  }
`;

export const StyleH2Header = styled.h2`
  ${StyleFontRoman}
  font-weight: 500;
  margin: 0 0 0.25em 0;

  font-size: 18px;
  line-height: 22px;

  @media screen and ${deviceWidths.desktop} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const StyleH3Header = styled.h3`
  ${StyleFontRoman}
  margin: 0 0 0.25em 0;
  font-size: 16px;

  @media screen and ${deviceWidths.desktop} {
    font-size: 24px;
  }
`;

export const StyleH4Header = styled.h4`
  ${StyleFontBook}
  margin: 0 0 0.25em 0;
  font-size: 12px;

  @media screen and ${deviceWidths.desktop} {
    font-size: 16px;
  }
`;

export const StyleH5Header = styled.h5`
  ${StyleFontHeavy}

  text-transform: uppercase;
  margin: 0 0 0.25em 0;

  font-size: 11px;
  line-height: 17px;

  @media screen and ${deviceWidths.desktop} {
    font-size: 14px;
    line-height: 19px;
  }
`;

export const StyleH6Header = styled.h6`
  ${StyleFontHeavy}
  font-size: 10px;
  text-transform: uppercase;
  margin: 0 0 0.25em 0;
`;
