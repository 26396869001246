import React, {ReactElement, useContext} from "react";
import {StyledEventChip, StyledTierCell, StyledContentCell, StyledEventDate, StyledEventName} from "./style";
import {CGAPEvent} from "../../../../types/annual-planner";
import {planningContext} from "../../../../views/planning/context";
import {FormattedDate} from "react-intl";

type CalendarEventProps = {
    event: CGAPEvent;
};


const CalendarEvent = ({event}: CalendarEventProps): ReactElement => {
    const {setEventModalState} = useContext(planningContext);
    const handleOnClick = () => {
        setEventModalState({isVisible: true, isAdding: false, sourceEvent: event});
    };

    return (
        <StyledEventChip onClick={handleOnClick}>
            <StyledTierCell tier={event.tier}>{event.tier}</StyledTierCell>
            <StyledContentCell>
                <StyledEventDate>
                    <FormattedDate value={event.date} month="numeric" day="numeric"/>
                </StyledEventDate>
                <StyledEventName>
                    {event.title}
                </StyledEventName>
            </StyledContentCell>
        </StyledEventChip>
    );
};

export default CalendarEvent;
