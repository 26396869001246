import React, { ReactElement, useContext, useEffect, useState } from "react";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import { store } from "../../store";
import PeopleIcon from "@material-ui/icons/People";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import { CGAPEvent, CGAPEventFieldType } from "../../types/annual-planner";
import {
  StyledEventsByTierView,
  TierGrid,
  TierGridActionColEnd,
  TierGridActionColStart,
  TierGridActionRow,
  TierGridCol,
  TierGridColumnTitle,
  TierGridHeader,
  TierGridRow,
  TierGridRowEmpty,
  TierGridRowEmptyText,
  TierGroup,
  TierGroupHeader,
} from "./style";
import ConfirmationModal from "../confirmation-modal";
import { AppActionType } from "../../store/types";
import { makeEvent } from "../../store/reducer";
import { FlatButton } from "../../styles/buttons";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { planningContext } from "../../views/planning/context";
import { isAddonSelected } from "../../util/events";
import { EventFormat, EventType } from "../../constants";

interface EBTColumnSchema {
  field: string;
  formatter?: (value) => JSX.Element | string;
  value?: (event: CGAPEvent) => unknown;
}

interface EBTVProps {
  disableActions?: boolean;
}

const initialTieredState = {
  1: [],
  2: [],
  3: [],
};

const EventsByTierView = ({
  disableActions = false,
}: EBTVProps): ReactElement => {
  const { state, dispatch } = useContext(store);
  const intl = useIntl();
  const { setEventModalState, viewFilters } = useContext(planningContext);

  const [tieredEvents, setTieredEvents] = useState<Record<number, CGAPEvent[]>>(
    () => _cloneDeep(initialTieredState)
  );
  const [confirmDeleteEvent, setConfirmDeleteEvent] = useState<
    CGAPEvent | undefined
  >();

  useEffect(() => {
    const newTieredEvents = _cloneDeep(initialTieredState);

    if (state?.eventsSummary?.events?.length) {
      let eventsSource = state.eventsSummary.events;

      if (!viewFilters.all) {
        eventsSource = state.eventsSummary.events.filter(
          (event) => viewFilters[event.type]
        );
      }

      eventsSource = eventsSource?.sort((a, b) => {
        if(a.date < b.date) {
          return -1;
        } else if (a.date > b.date) {
          return 1;
        }

        return 0;
      });

      for (let i = 1; i <= 3; i++) {
        newTieredEvents[i] = eventsSource.filter((event) => event.tier === i);
      }
    }

    setTieredEvents(newTieredEvents);
  }, [state?.eventsSummary?.events, viewFilters]);

  // const totalCost = state?.eventsSummary?.estimatedCost.cost || 0;
  // const budget = state?.budget || 0;
  // const isOverBudget = budget - totalCost < 0;

  const getBooleanDisplayValue = (value: CGAPEventFieldType) =>
    !!value ? "Yes" : "No";

  const columnsSchema: EBTColumnSchema[] = [
    {
      field: "tier",
      formatter: (value: CGAPEventFieldType) => value.toString(),
    },
    { field: "clientSegment" },
    { field: "title" },
    {
      field: "peopleCount",
      // eslint-disable-next-line react/display-name
      formatter: (value) => <FormattedNumber value={value as number} />,
    },
    {
      field: "format",
      formatter: (value) => {
        if (value) {
          return intl.formatMessage({ id: value });
        }

        return "n/a";
      },
    },
    {
      field: "dayCount",
    },
    {
      field: "locationId",
      value: (event: CGAPEvent) =>
        event.format === EventFormat.InPerson ? event.locationDisplay : "-",
    },
    {
      field: "date",
      // eslint-disable-next-line react/display-name
      formatter: (value) => (
        <FormattedDate
          value={value}
          year="2-digit"
          month="2-digit"
          day="2-digit"
        />
      ),
    },
    {
      field: "cost",
      // eslint-disable-next-line react/display-name
      formatter: (value) => (
        <FormattedNumber
          value={value.total}
          style="currency"
          currency="USD"
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      ),
    },
    {
      field: "hasCustomVideo",
      value: (event: CGAPEvent) => ({
        hasVideo: isAddonSelected(event, "video"),
        hasSpeaker: isAddonSelected(event, "speaker"),
      }),
      formatter: (value) =>
        getBooleanDisplayValue(value.hasVideo) +
        "/" +
        getBooleanDisplayValue(value.hasSpeaker),
    },
    {
      field: "hostedSponsored",
      value: (event: CGAPEvent) => {
        switch (event.type) {
          case EventType.sponsored:
            return "Sponsored";
          case EventType.hosted:
            return "Hosted";
        }

        return "Unknown";
      },
    },
    {
      field: "@actions",
      // eslint-disable-next-line react/display-name
      value: (event) => {
        if (disableActions) {
          return null;
        }

        return (
          <>
            <FlatButton
              onClick={() => {
                setEventModalState({
                  isVisible: true,
                  isAdding: false,
                  sourceEvent: event,
                });
              }}
            >
              <EditIcon fontSize="small" />
            </FlatButton>
            <FlatButton
              onClick={() => {
                setConfirmDeleteEvent(event);
              }}
            >
              <DeleteIcon fontSize="small" />
            </FlatButton>
          </>
        );
      },
    },
  ];

  const renderTierHeaderColumn = (schema: EBTColumnSchema, idx: number) => {
    let headerTitle: string;

    if (schema.field === "peopleCount") {
      return (
        <TierGridHeader key={idx}>
          <TierGridColumnTitle>
            <PeopleIcon />
          </TierGridColumnTitle>
        </TierGridHeader>
      );
    }

    if (schema.field === "@actions") {
      if (disableActions) {
        return null;
      }

      return (
        <TierGridHeader key={idx}>
          <TierGridColumnTitle>
            <EditIcon fontSize="small" />
          </TierGridColumnTitle>
        </TierGridHeader>
      );
    }

    if (schema.field === "hasCustomVideo") {
      headerTitle =
        intl.formatMessage({ id: `eventField.hasCustomVideo` }) +
        " / " +
        intl.formatMessage({ id: `eventField.hasGuestSpeaker` });
    } else {
      headerTitle = intl.formatMessage({ id: `eventField.${schema.field}` });
    }

    return (
      <TierGridHeader key={idx}>
        <TierGridColumnTitle>{headerTitle}</TierGridColumnTitle>
      </TierGridHeader>
    );
  };

  const renderTierDataColumn = (
    schema: EBTColumnSchema,
    event: CGAPEvent,
    idx: number
  ) => {
    let columnValue;

    if (typeof schema.value === "function") {
      columnValue = schema.value(event);

      if (columnValue === null) {
        return null;
      }
    } else {
      columnValue = _get(event, schema.field);
    }

    if (typeof schema.formatter === "function") {
      return (
        <TierGridCol key={idx}>{schema.formatter(columnValue)}</TierGridCol>
      );
    } else {
      return <TierGridCol key={idx}>{columnValue}</TierGridCol>;
    }
  };

  return (
    <StyledEventsByTierView>
      <TierGrid>
        <TierGroupHeader>
          <TierGridRow isHidden={disableActions}>
            {columnsSchema.map(renderTierHeaderColumn)}
          </TierGridRow>
        </TierGroupHeader>
        {[1, 2, 3].map((tierNumber) => (
          <TierGroup key={`tiergroup-${tierNumber}`}>
            {tieredEvents?.[tierNumber]?.length ? (
              tieredEvents[tierNumber].map((event, idx) => (
                <TierGridRow key={`tieridx-${idx}`} isHidden={disableActions}>
                  {columnsSchema.map((schema, idx) =>
                    renderTierDataColumn(schema, event, idx)
                  )}
                </TierGridRow>
              ))
            ) : (
              <TierGridRowEmpty>
                <TierGridRowEmptyText>
                  <FormattedMessage
                    id="eventsByTier.noEvents"
                    description="When there are no events to display for a tier in events by tier" // Description should be a string literal
                    defaultMessage="No Tier {tierNumber} events" // Message should be a string literal
                    values={{
                      tierNumber: tierNumber,
                    }}
                  />
                </TierGridRowEmptyText>
              </TierGridRowEmpty>
            )}
            <TierGridActionRow isHidden={disableActions}>
              <TierGridActionColStart>
                <FlatButton
                  onClick={() => {
                    setEventModalState({
                      isVisible: true,
                      isAdding: true,
                      sourceEvent: makeEvent(
                        { tier: tierNumber },
                        state?.planOptions
                      ),
                    });
                  }}
                >
                  {intl.formatMessage({ id: "addNewEvent" })}
                </FlatButton>
              </TierGridActionColStart>
              <TierGridActionColEnd>&nbsp;</TierGridActionColEnd>
            </TierGridActionRow>
          </TierGroup>
        ))}
      </TierGrid>
      <ConfirmationModal
        isOpen={!!confirmDeleteEvent}
        title={intl.formatMessage({ id: "deleteModal.title" })}
        bodyCopy={intl.formatMessage(
          { id: "deleteModal.description" },
          { eventTitle: confirmDeleteEvent?.title }
        )}
        confirmText={intl.formatMessage({ id: "deleteModal.actions.confirm" })}
        cancelText={intl.formatMessage({ id: "deleteModal.actions.cancel" })}
        closeModalWithDisposition={(shouldDeleteEvent) => {
          if (shouldDeleteEvent) {
            dispatch({
              type: AppActionType.DELETE_EVENT,
              payload: confirmDeleteEvent,
            });
          }
          setConfirmDeleteEvent(undefined);
        }}
      />
    </StyledEventsByTierView>
  );
};

export default EventsByTierView;
