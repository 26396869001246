import _find from "lodash.find";
import {CGAPClientGroupOption, CGAPConfig} from "../types/annual-planner";

export function getAvailableClientSegments(config: CGAPConfig, clientGroup: CGAPClientGroupOption | null): string[] {
    if(!clientGroup) {
        return [];
    }

    let clientGroups: string[] = [];
    if (clientGroup?.groupFilter?.length && config.clientGroups) {
        if (clientGroup.segmentFilter?.length) {
            clientGroups = [clientGroup.segmentFilter];
        } else {
            const clientGroupConfig = _find(config.clientGroups, {
                label: clientGroup.groupFilter,
            });

            if(clientGroupConfig?.segments) {
                clientGroups = [...clientGroupConfig.segments];
            }
        }
    }

    return clientGroups;
}
