
import styled from "styled-components";
import {deviceWidths, StyleFontRoman} from "../../../../styles/theme";

export const StyledEventCostTotal = styled.div`
`;
export const StyledECLabel = styled.div`
  font-size: 0.75rem;
  text-align: right;
`;
export const StyledECValue = styled.div`
  ${StyleFontRoman}
  margin: 0 0 0.25em 0;
  font-size: 16px;
  font-weight: 500;
  text-align: right;

  @media screen and ${deviceWidths.desktop} {
    font-size: 24px;
  }
`;
