import React, { ReactElement, useContext, useEffect, useState } from "react";
import _cloneDeep from "lodash.clonedeep";
import {
  ScoreboxBorder,
  StyledCostColumn,
  StyledCountColumn,
  StyledHeaderRow,
  StyledScorebox,
  StyledScoreboxCell,
  StyledScoreboxRow,
  StyledScoreboxRowLabel,
  StyledScoreboxTable,
  StyledSpacerColumn,
} from "./style";
import { store } from "../../store";
import { CGAPCount, makeInitialCount } from "../../types/annual-planner";
import { currencyOption } from "../../util/formatters";
import { FormattedNumber } from "react-intl";
import { planningContext } from "../../views/planning/context";

type ScoreboxProps = {
  prop?: unknown;
};

interface BudgetEtc {
  cost: number;
  budget: number;
  difference: number;
}

interface ScoreboxState {
  sponsored: BudgetEtc;
  hosted: BudgetEtc;
  total: BudgetEtc;
}

function makeEmptyBudgetEtc(): BudgetEtc {
  return {
    cost: 0,
    budget: 0,
    difference: 0,
  };
}

const Scorebox = ({}: ScoreboxProps): ReactElement => {
  const { state } = useContext(store);
  const { viewFilters } = useContext(planningContext);
  const [sbCosts, setSbCosts] = useState<ScoreboxState>(() => ({
    sponsored: makeEmptyBudgetEtc(),
    hosted: makeEmptyBudgetEtc(),
    total: makeEmptyBudgetEtc(),
  }));

  useEffect(() => {
    const updatedCosts = _cloneDeep(sbCosts);

    updatedCosts.hosted = {
      budget: state?.planOptions?.budget?.hosted || 0,
      cost: state?.eventsSummary?.costByType?.hosted || 0,
      difference: 0,
    };

    updatedCosts.hosted.difference =
      updatedCosts.hosted.budget - updatedCosts.hosted.cost;

    updatedCosts.sponsored = {
      budget: state?.planOptions?.budget?.sponsored || 0,
      cost: state?.eventsSummary?.costByType?.sponsored || 0,
      difference: 0,
    };

    updatedCosts.sponsored.difference =
      updatedCosts.sponsored.budget - updatedCosts.sponsored.cost;

    updatedCosts.total.budget = state?.planOptions?.budget?.total || 0;
    updatedCosts.total.cost =
      updatedCosts.hosted.cost + updatedCosts.sponsored.cost;
    updatedCosts.total.difference =
      updatedCosts.total.budget - updatedCosts.total.cost;

    setSbCosts(updatedCosts);
  }, [
    state?.planOptions?.budget?.sponsored,
    state?.planOptions?.budget?.hosted,
    state?.eventsSummary?.costByType?.sponsored,
    state?.eventsSummary?.costByType?.hosted,
  ]);

  // This is separate b/c tracking the changes in state for the counts will be painful
  let count: CGAPCount;

  if (!state?.eventsSummary?.count) {
    count = makeInitialCount();
  } else {
    count = state.eventsSummary.count;
  }

  return (
    <StyledScorebox>
      <StyledScoreboxTable>
        <thead>
          <StyledHeaderRow>
            <StyledScoreboxRowLabel borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledScoreboxRowLabel>
            <StyledCountColumn colSpan={3} borderColor={ScoreboxBorder.GRAY}>
              In-Person
            </StyledCountColumn>
            <StyledSpacerColumn borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledSpacerColumn>
            <StyledCountColumn colSpan={3} borderColor={ScoreboxBorder.GRAY}>
              Virtual
            </StyledCountColumn>
            <StyledSpacerColumn borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledSpacerColumn>
            <StyledScoreboxCell borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledScoreboxCell>
            <StyledScoreboxCell borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledScoreboxCell>
            <StyledScoreboxCell borderColor={ScoreboxBorder.NONE}>
              &nbsp;
            </StyledScoreboxCell>
          </StyledHeaderRow>
          <StyledHeaderRow>
            <StyledScoreboxRowLabel borderColor={ScoreboxBorder.BLACK}>
              &nbsp;
            </StyledScoreboxRowLabel>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 1
            </StyledCountColumn>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 2
            </StyledCountColumn>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 3
            </StyledCountColumn>
            <StyledSpacerColumn borderColor={ScoreboxBorder.BLACK}>
              &nbsp;
            </StyledSpacerColumn>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 1
            </StyledCountColumn>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 2
            </StyledCountColumn>
            <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
              Tier 3
            </StyledCountColumn>
            <StyledSpacerColumn borderColor={ScoreboxBorder.BLACK}>
              &nbsp;
            </StyledSpacerColumn>
            <StyledScoreboxCell borderColor={ScoreboxBorder.BLACK}>
              Event Cost
            </StyledScoreboxCell>
            <StyledScoreboxCell borderColor={ScoreboxBorder.BLACK}>
              Event Budget
            </StyledScoreboxCell>
            <StyledScoreboxCell borderColor={ScoreboxBorder.BLACK}>
              Difference
            </StyledScoreboxCell>
          </StyledHeaderRow>
        </thead>
        <tbody>
          {viewFilters.sponsored && (
            <StyledScoreboxRow>
              <StyledScoreboxRowLabel>Sponsored Events</StyledScoreboxRowLabel>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier1.groups.sponsored.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier2.groups.sponsored.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier3.groups.sponsored.total}
              </StyledCountColumn>
              <StyledSpacerColumn>&nbsp;</StyledSpacerColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier1.groups.sponsored.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier2.groups.sponsored.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier3.groups.sponsored.total}
              </StyledCountColumn>
              <StyledSpacerColumn>&nbsp;</StyledSpacerColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.sponsored.cost}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.sponsored.budget}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.sponsored.difference}
                  {...currencyOption}
                />
              </StyledCostColumn>
            </StyledScoreboxRow>
          )}
          {viewFilters.hosted && (
            <StyledScoreboxRow>
              <StyledScoreboxRowLabel>Hosted Events</StyledScoreboxRowLabel>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier1.groups.hosted.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier2.groups.hosted.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.inPerson.groups.tier3.groups.hosted.total}
              </StyledCountColumn>
              <StyledSpacerColumn>&nbsp;</StyledSpacerColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier1.groups.hosted.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier2.groups.hosted.total}
              </StyledCountColumn>
              <StyledCountColumn>
                {count.groups.virtual.groups.tier3.groups.hosted.total}
              </StyledCountColumn>
              <StyledSpacerColumn>&nbsp;</StyledSpacerColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.hosted.cost}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.hosted.budget}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn>
                <FormattedNumber
                  value={sbCosts.hosted.difference}
                  {...currencyOption}
                />
              </StyledCostColumn>
            </StyledScoreboxRow>
          )}
          {viewFilters.all && (
            <StyledScoreboxRow>
              <StyledScoreboxRowLabel borderColor={ScoreboxBorder.BLACK}>
                Total
              </StyledScoreboxRowLabel>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.inPerson.groups.tier1.total}
              </StyledCountColumn>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.inPerson.groups.tier2.total}
              </StyledCountColumn>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.inPerson.groups.tier3.total}
              </StyledCountColumn>
              <StyledSpacerColumn borderColor={ScoreboxBorder.BLACK}>
                &nbsp;
              </StyledSpacerColumn>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.virtual.groups.tier1.total}
              </StyledCountColumn>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.virtual.groups.tier2.total}
              </StyledCountColumn>
              <StyledCountColumn borderColor={ScoreboxBorder.BLACK}>
                {count.groups.virtual.groups.tier3.total}
              </StyledCountColumn>
              <StyledSpacerColumn borderColor={ScoreboxBorder.BLACK}>
                &nbsp;
              </StyledSpacerColumn>
              <StyledCostColumn borderColor={ScoreboxBorder.BLACK}>
                <FormattedNumber
                  value={sbCosts.total.cost}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn borderColor={ScoreboxBorder.BLACK}>
                <FormattedNumber
                  value={sbCosts.total.budget}
                  {...currencyOption}
                />
              </StyledCostColumn>
              <StyledCostColumn borderColor={ScoreboxBorder.BLACK}>
                <FormattedNumber
                  value={sbCosts.total.difference}
                  {...currencyOption}
                />
              </StyledCostColumn>
            </StyledScoreboxRow>
          )}
        </tbody>
      </StyledScoreboxTable>
    </StyledScorebox>
  );
};

export default Scorebox;
