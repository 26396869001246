import React, { ReactElement, useContext } from "react";
import _set from "lodash.set";
import { StyledFormElement, StyledGridColumns } from "../../style";
import CurrencyTextInput from "../../../currency-text-input";
import { budgetPlanningContext } from "../../context";
import { useIntl } from "react-intl";
import _cloneDeep from "lodash.clonedeep";
import {EventFormActionType} from "../../types";

const BudgetPlanningSponsored = (): ReactElement => {
  const intl = useIntl();
  const { eventFormState, eventFormDispatch } = useContext(budgetPlanningContext);

  return (
    <StyledGridColumns count={2}>
      <StyledFormElement>
        <CurrencyTextInput
          label={intl.formatMessage({ id: "eventField.costSponsoredFlat" })}
          plainvalue={eventFormState.event?.cost?.sponsored?.flat || 0}
          fullWidth
          size="small"
          onChange={(e) => {
              if (eventFormState?.event) {
                  const updatingEvent = _cloneDeep(eventFormState.event);
                  _set(updatingEvent, 'cost.sponsored.flat', parseInt(e.target.value, 10));

                  eventFormDispatch({
                      type: EventFormActionType.UPDATE_EVENT,
                      payload: updatingEvent,
                  });
              }
          }}
        />
      </StyledFormElement>
    </StyledGridColumns>
  );
};

export default BudgetPlanningSponsored;
