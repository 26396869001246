import styled from "styled-components";

// keep all of the common/reusable styles here
export const AppStyle = styled.div`

`;

export const StyledGridColumn = styled.div`
    padding: 0.5rem;
`;

export const StyledColumnTitle = styled(StyledGridColumn)`
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    padding: 0.5rem;
`;



