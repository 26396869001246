import React, { ReactElement, useContext } from "react";
import _set from "lodash.set";
import { StyledFormElement, StyledGridColumns } from "../../style";
import CurrencyTextInput from "../../../currency-text-input";
import EventCostTotal from "../event-cost-total";
import { budgetPlanningContext } from "../../context";
import { useIntl } from "react-intl";
import OverrideCheckbox from "./override-methodology";
import _cloneDeep from "lodash.clonedeep";
import { EventFormActionType } from "../../types";

const BudgetPlanningHostedVirtual = (): ReactElement => {
  const intl = useIntl();
  const { eventFormState, eventFormDispatch } = useContext(
    budgetPlanningContext
  );

  return (
    <StyledGridColumns count={2}>
      <StyledFormElement>
        <OverrideCheckbox />
      </StyledFormElement>
      <StyledFormElement>&nbsp;</StyledFormElement>
      <StyledFormElement>
        <CurrencyTextInput
          label={intl.formatMessage({
            id: "eventField.costProductionPerDay",
          })}
          plainvalue={
            eventFormState.event?.cost?.hosted?.virtual?.productionPerDay || 0
          }
          fullWidth
          size="small"
          disabled={!eventFormState.event?.cost?.overrideMethodology}
          onChange={(e) => {
            if (eventFormState?.event) {
              const updatingEvent = _cloneDeep(eventFormState.event);
              _set(
                updatingEvent,
                "cost.hosted.virtual.productionPerDay",
                parseInt(e.target.value, 10)
              );

              eventFormDispatch({
                type: EventFormActionType.UPDATE_EVENT,
                payload: updatingEvent,
              });
            }
          }}
        />
      </StyledFormElement>
      <StyledFormElement>
        <CurrencyTextInput
          cents={true}
          label={intl.formatMessage({ id: "eventField.costPerPerson" })}
          plainvalue={eventFormState.event?.cost?.hosted?.virtual?.perPerson || 0}
          fullWidth
          size="small"
          disabled={!eventFormState.event?.cost?.overrideMethodology}
          onChange={(e) => {
            if (eventFormState?.event) {
              const updatingEvent = _cloneDeep(eventFormState.event);
              _set(
                updatingEvent,
                "cost.hosted.virtual.perPerson",
                parseFloat(e.target.value)
              );

              eventFormDispatch({
                type: EventFormActionType.UPDATE_EVENT,
                payload: updatingEvent,
              });
            }
          }}
        />
      </StyledFormElement>
      <StyledFormElement>&nbsp;</StyledFormElement>
      <StyledFormElement>
        <EventCostTotal total={eventFormState?.event?.cost?.total || 0} />
      </StyledFormElement>
    </StyledGridColumns>
  );
};

export default BudgetPlanningHostedVirtual;
