import styled from "styled-components";
import { deviceWidths, StyleGridGap } from "../../styles/theme";

export const StyledActionRibbonTop = styled.div`
  display: grid;
  ${StyleGridGap};
  align-items: center;
  justify-items: start;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "clientGroup ."
    "baseCount currentCount"
    "hostedBudget sponsoredBudget"
    "totalBudget ."
    "tabs tabs"
    "toggles toggles";
  margin: 20px 0;
  
  .MuiFormControlLabel-root {
    margin-right: 2rem;
    
    &:last-of-type {
      margin-right: 0;
    } 
  }

  @media screen and ${deviceWidths.tablet} {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 3fr);
    grid-template-areas:
      "clientGroup baseCount currentCount tabs"
      "hostedBudget sponsoredBudget totalBudget toggles";
    margin: 40px 0;
    
    .MuiTypography-body1 {
      font-size: 0.8rem; 
    }
  }

  @media screen and ${deviceWidths.max} {
    .MuiTypography-body1 {
      font-size: 1rem;
    }    
  }
`;

export const StyledActions = styled.div`
  grid-area: actions;
  justify-self: end;
  display: grid;
  grid-template-columns: 2fr 2fr;
  align-items: start;
  justify-items: start;

  @media screen and ${deviceWidths.tablet} {
    justify-items: end;
  }
`;

export const StyledToggles = styled.div`
  grid-area: toggles;
  justify-self: end;
`;
