import styled from "styled-components";
import { StyledGridColumn } from "../../App.style";
import {
  StyledFloatingContent,
  StyleFontMedium,
  StyleGridHeaderText,
  StyleGridHeaderUnderline,
  StyleH4Header,
  StyleRowAlternateColors,
  ThemeColors,
} from "../../styles/theme";

export const columnPadding = 15;

export const StyledEventsByTierView = styled(StyledFloatingContent)``;

export const TierGrid = styled.div`
  display: grid;
`;

export const TierGroupHeader = styled.div`
  ${StyleGridHeaderUnderline}
`;

export const TierGroup = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid black;
  }
`;

export interface TierGridRowProps {
  isHidden?: boolean;
}

export const TierGridRow = styled.div<TierGridRowProps>`
  display: grid;
  align-items: center;

  grid-template-columns:
    minmax(0, 55px) minmax(100px, 100px) minmax(200px, 1fr)
    minmax(0, 100px) minmax(0, 105px) minmax(0, 55px) minmax(0, 200px) minmax(
      0,
      85px
    )
    minmax(0, 150px) minmax(0, 100px) minmax(80px, 80px) ${(props) => (props.isHidden ? "" : "minmax(0, 55px)")};

  @media print {
    grid-template-columns:
    minmax(0, 55px) minmax(0, 68px) minmax(200px, 300px)
    minmax(0, 100px) minmax(0, 105px) minmax(0, 55px) minmax(0, 200px) minmax(
      0,
      85px
    )
    minmax(70px, 150px) minmax(0, 100px) minmax(100px, 200px) ${(props) => (props.isHidden ? "" : "minmax(0, 55px)")};
    border-bottom: 1px solid lightgray;
  }

  ${StyleRowAlternateColors}

  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
`;

export const TierGridActionRow = styled(TierGridRow)<TierGridRowProps>`
  display: ${(props) => (props.isHidden ? "none" : "grid")};
  grid-template-columns: 1fr 1fr;
`;

export const TierGridActionColStart = styled.div`
  justify-self: start;
  padding: ${columnPadding}px;
`;

export const TierGridActionColEnd = styled.div`
  justify-self: end;
  padding: ${columnPadding}px;
`;

export const TierGridRowEmpty = styled(TierGridRow)`
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  height: 5rem;
  justify-items: center;
  align-items: center;
  background-color: ${ThemeColors.Neutral1};
`;

export const TierGridRowEmptyText = styled(StyleH4Header)`
  color: ${ThemeColors.Black30};
  text-transform: uppercase;
`;

export const TierGridCol = styled(StyledGridColumn)`
  ${StyleFontMedium}
  font-size: 14px;
  color: ${ThemeColors.Black};
  letter-spacing: 0.35px;
  display: grid;
  align-items: center;
  margin-bottom: 3px;
  padding: ${columnPadding}px 0;
  text-align: center;
  @media print {
    padding: 2px 0;
    margin: 0;
  }
`;

export const TierGridHeader = styled(TierGridCol)`
  padding: 0;
  margin: 0;
`;

export const TierGridColumnTitle = styled.div`
  padding: ${columnPadding}px 0;
  ${StyleGridHeaderText}
`;

export const AlignRight = styled.span`
  text-align: right;
  display: inline-block;
  width: 100%;
`;

// interface StyledEventNumberProps {
//   tier: number;
// }
//
// export const StyledBudgetBox = styled.div`
//   justify-self: end;
//   margin: 2em 0;
//   padding: 2em;
//   color: white;
//   width: 250px;
//
//   h4 {
//     margin: 0 0 1rem;
//   }
//   p:last-child {
//     margin-bottom: 0;
//   }
// `;
//
// export const StyledBudgetBoxOk = styled(StyledBudgetBox)`
//   background-color: green;
// `;
//
// export const StyledBudgetBoxOver = styled(StyledBudgetBox)`
//   background-color: darkred;
// `;
