import React, { ReactElement, useEffect, useReducer } from "react";
import { IntlProvider } from "react-intl";
import "./App.css";
import { initialAppState, StateProvider } from "./store";
import BaseLayout from "./layouts/base";
import LandingView from "./views/landing";
import { reducer } from "./store/reducer";
import { loadConfig } from "./services/api-client";
import { AppActionType, CGAPAppState } from "./store/types";
import { getFromLocalStorage, STORE_KEY_APP_STATE } from "./util/local-storage";
import { localeMessages } from "./locale/en-us";
import PlanningView from "./views/planning";
import CGAlertBar from "./components/alert-bar";

function App(): ReactElement {
  const [state, dispatch] = useReducer(reducer, initialAppState);

  useEffect(() => {
    const savedAppStateString = getFromLocalStorage(STORE_KEY_APP_STATE);
    let savedAppState: CGAPAppState | null = null;

    if (savedAppStateString) {
      try {
        savedAppState = JSON.parse(savedAppStateString);

        if (savedAppState !== null) {
          dispatch({
            type: AppActionType.IMPORT_STATE,
            payload: savedAppState,
          });
        }
      } catch {}
    }

    loadConfig()
      .then(
        (response) => {
          dispatch({ type: AppActionType.SET_LOADING, payload: false });
          dispatch({ type: AppActionType.SET_CONFIG, payload: response });
          dispatch({ type: AppActionType.RESET_ERROR });
        },
        () => {
          dispatch({ type: AppActionType.SET_LOADING, payload: false });
          dispatch({ type: AppActionType.SET_ERROR, payload: localeMessages['config.errorLoading'] }); // careful, intl/useIntl isn't available in App
        }
      )
      .catch(() => {
        dispatch({ type: AppActionType.SET_LOADING, payload: false });
        dispatch({ type: AppActionType.SET_ERROR, payload: localeMessages['config.errorLoading'] }); // careful, intl/useIntl isn't available in App
      });
  }, []);

  return (
    <IntlProvider messages={localeMessages} locale="en" defaultLocale="en">
      <StateProvider value={{ state, dispatch }}>
        <BaseLayout>{state?.planOptions?.clientGroup?.groupFilter ? <PlanningView/> : <LandingView/>}</BaseLayout>
        <CGAlertBar message={state.error} onDismiss={() => {
            dispatch({
                type: AppActionType.RESET_ERROR
            });
        }} />
      </StateProvider>
    </IntlProvider>
  );
}

export default App;
