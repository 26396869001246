import React, { ReactElement } from "react";
import {
  StyledSummaryActions, StyledSummaryButtons,
  StyledSummaryContent,
  StyledSummaryHero,
  StyledSummaryModal,
} from "./style";
import { useIntl } from "react-intl";
import { CenterModal } from "react-spring-modal";
import EventsByTierView from "../events-by-tier";
import { StyledFloatingContent } from "../../styles/theme";
import {
  StyledModalDescription,
  StyledModalHeader,
} from "../../styles/modals";
import {
  CancelButtonWithIcon,
  ConfirmButtonWithIcon,
} from "../../styles/buttons";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SegmentShareTable from "./segment-share-table";
import CalendarYear from "../events-by-calendar/components/calendar-year";
import Scorebox from "../scorebox";

type SummaryModalProps = {
  isOpen: boolean;
  closeModalWithDisposition: (disposition: boolean | Error) => void; // true -> success, false -> cancelled, Error -> failure
};

const SummaryModal = ({
  isOpen,
  closeModalWithDisposition,
}: SummaryModalProps): ReactElement => {
  const intl = useIntl();

  const doConfirm = () => {
    if(window?.print) {
      window.print();
    }
  };

  return (
    <CenterModal
      autoFocus={false}
      isOpen={isOpen}
      onRequestClose={() => {
        closeModalWithDisposition(false);
      }}
    >
      <StyledSummaryModal>
        <StyledSummaryHero>
          <StyledModalHeader>
            {intl.formatMessage({ id: "summaryModal.title" })}
          </StyledModalHeader>
          <StyledModalDescription>
            {intl.formatMessage({ id: "summaryModal.description" })}
          </StyledModalDescription>
        </StyledSummaryHero>
        <StyledSummaryContent>
          <EventsByTierView />
          <StyledFloatingContent>
            <CalendarYear />
          </StyledFloatingContent>
          <SegmentShareTable />
          <Scorebox/>
        </StyledSummaryContent>
        <StyledSummaryActions>
          <div>&nbsp;</div>
          <StyledSummaryButtons>
            <CancelButtonWithIcon
              onClick={() => {
                closeModalWithDisposition(false);
              }}
            >
              {intl.formatMessage({ id: "summaryModal.actions.cancel" })}
              <ArrowForwardIcon />
            </CancelButtonWithIcon>
            <ConfirmButtonWithIcon onClick={doConfirm}>
              {intl.formatMessage({ id: "summaryModal.actions.confirm" })}
              <ArrowForwardIcon />
            </ConfirmButtonWithIcon>
          </StyledSummaryButtons>
        </StyledSummaryActions>
      </StyledSummaryModal>
    </CenterModal>
  );
};

export default SummaryModal;
