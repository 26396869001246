import React, { ReactElement, useEffect, useState } from "react";
import { StandardTextFieldProps, TextField } from "@material-ui/core";
import {
  currencyFormatter,
  currencyFormatterWithCents,
} from "../../util/formatters";

export interface CurrencyTextInputProps extends StandardTextFieldProps {
  cents?: boolean;
  plainvalue: number;
}

const CurrencyTextInput = (props: CurrencyTextInputProps): ReactElement => {
  const formatDisplayValue = props.cents
    ? currencyFormatterWithCents.format
    : currencyFormatter.format;
  const [displayedValue, setDisplayedValue] = useState(() =>
    formatDisplayValue(props.plainvalue)
  );
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const valueNumeric = props.plainvalue as number;

    if (!isNaN(valueNumeric)) {
      setDisplayedValue(formatDisplayValue(valueNumeric));
    } else {
      setDisplayedValue("");
    }
  }, [props.plainvalue]);

  const handleChangeWithCurrency = (event) => {
    const newInputValue = event.target.value;
    let newFormattedValue = "";
    let inputReturnValue = false;
    let newNumericValue = 0;

    if (newInputValue) {
      newNumericValue = parseFloat(newInputValue.replace(/[^\d.]*/gi, ""));
      if (!isNaN(newNumericValue)) {
        newFormattedValue = formatDisplayValue(newNumericValue);
        inputReturnValue = true;
      }
    }

    if (props?.onChange) {
      event.target.value = newNumericValue;
      props.onChange(event);
    }

    setDisplayedValue(newFormattedValue);
    return inputReturnValue;
  };

  const onKeyPress = (e) => {
    if (e.keyCode == 13) {
      e.target.blur();
      return false;
    }

    return true;
  };

  return (
    <TextField
      {...props}
      value={isEditing ? undefined : displayedValue}
      onChange={(e) => e.preventDefault()}
      onKeyDown={onKeyPress}
      onBlur={(e) => {
        handleChangeWithCurrency(e);
        setIsEditing(false);
      }}
      onFocus={() => {
        setIsEditing(true);
      }}
    />
  );
};

export default CurrencyTextInput;
