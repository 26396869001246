import { StyleFontRoman, ThemeColors } from "./theme";
import styled, {css} from "styled-components";

export const TextInputStyles = css`
  ${StyleFontRoman}
  font-size: 16px;
  border: none;
  border-bottom: 1px solid ${ThemeColors.CGBlack};
  border-radius: 0;
  outline: none;
  width: 100%;
`;

export const StyledTextInput = styled.input`
  ${TextInputStyles}
    
    // These match the styles for the react-select component
    line-height: 1.75em;
    padding: 2px 8px;
    box-sizing: border-box;
`

export const reactSelectStyles = {
  control: (provided: Record<string, string | number | null>): Record<string, string | number | null> => ({
    ...provided,
    width: "100%",
    border: "none",
    borderBottom: `1px solid ${ThemeColors.CGBlack}`,
    borderRadius: 0,
    outline: "none",
  }),
  indicatorSeparator: (): Record<string, string | number | null> => ({
    display: "none",
  }),
  dropdownIndicator: (): Record<string, string | number | null> => ({
    color: `1px solid ${ThemeColors.CGBlack}`,
  }),

  menu: (provided: Record<string, string | number | null>): Record<string, string | number | null> => ({
    ...provided,
    borderRadius: 0,
    margin: 0
  }),
  option: (provided: Record<string, string | number | null>, { isDisabled, isFocused, isSelected }: Record<string, boolean>): Record<string, string | number | null> => ({
    ...provided,
    color: isSelected ? ThemeColors.Sapphire : ThemeColors.CGBlack,
    cursor: isDisabled ? "not-allowed" : "default",
    backgroundColor: isDisabled
      ? ThemeColors.Sapphire
      : isFocused
      ? ThemeColors.LightOcean
      : ThemeColors.White,
    padding: 20,
  }),
  singleValue: (provided: Record<string, string | number | null>, {isDisabled}: Record<string, boolean>): Record<string, string | number | null> => {
    const opacity = isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
