import styled from "styled-components";
import {StyledFloatingContent, StyleFontRoman, ThemeColors} from "../../styles/theme";

export const StyledEventsByCalendarView = styled(StyledFloatingContent)``;

export const StyledHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: start;
    padding: 22px;
    border-bottom: 2px solid ${ThemeColors.Black};
`;


export const StyledHeaderLeft = styled.div``;

export const StyledHeaderRight = styled.div`
  justify-self: end;
`;

export const StyledHeaderContent = styled.p`
    ${StyleFontRoman}
`;
