import React, { ReactElement } from "react";
import {
  LoadingConfigurationProps,
  StyledLoadingConfiguration,
  StyledLoadingConfigurationWrap,
  StyledLoadingMessage,
} from "./style";
import { CGProgressSpinner } from "../material/cg-progress-spinner";
import { FormattedMessage } from "react-intl";

const LoadingConfiguration = (props: LoadingConfigurationProps): ReactElement => {
  return (
    <StyledLoadingConfiguration {...props}>
      <StyledLoadingConfigurationWrap>
        <StyledLoadingMessage>
          <FormattedMessage id="config.loading" />
        </StyledLoadingMessage>
        <CGProgressSpinner variant={props.isVisible ? 'indeterminate' : 'determinate'}/>
      </StyledLoadingConfigurationWrap>
    </StyledLoadingConfiguration>
  );
};

export default LoadingConfiguration;
