import React, {ReactElement} from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox, {CheckboxProps} from "@material-ui/core/Checkbox";
import {ThemeColors} from "../../styles/theme";

const useStyles = makeStyles({
    root: {
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: 0,
        width: 16,
        height: 16,
        boxShadow: `inset 0 0 0 1px ${ThemeColors.Ocean}, inset 0 -1px 0 ${ThemeColors.Raspberry}`,
        "input:hover ~ &": {
            backgroundColor: ThemeColors.GrayE8,
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: ThemeColors.GrayB2,
        },
    },
    checkedIcon: {
        // color: ThemeColors.Ocean,
        // backgroundColor: '#137cbd',
        // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                `1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='${encodeURIComponent(
                    ThemeColors.Ocean
                )}'/%3E%3C/svg%3E\")`,
            content: '""',
        },
        "input:disabled ~ &:before": {
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                `1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='${encodeURIComponent(
                    ThemeColors.Gray66
                )}'/%3E%3C/svg%3E\")`,
        },
        "input:hover ~ &": {
            backgroundColor: ThemeColors.GrayE8,
        },
    }
});

// Inspired by blueprintjs
export function CGCheckbox(props: CheckboxProps): ReactElement {
    const classes = useStyles();

    return (
        <Checkbox
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}
