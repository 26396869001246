import {
    createStyles,
    makeStyles, MenuItem,
    Theme,
} from "@material-ui/core";
import {ThemeColors} from "./theme";
import {withStyles} from "@material-ui/core/styles";

export const useCGSelectStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: 0,
            minWidth: 120,
            width: "100%",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        selected: {
            color: ThemeColors.White,
            backgroundColor: ThemeColors.Raspberry,
        },
    })
);

export const StyledMenuItemParent = withStyles(() => ({
    root: {
        fontWeight: "bold",
    },
}))(MenuItem);

export const StyledMenuItemChild = withStyles(() => ({
    root: {
        paddingLeft: "3rem",
    },
}))(MenuItem);
