import _cloneDeep from "lodash.clonedeep";
import {
    CGAPNewPlanState,
    disabledInputsInitialState,
    DisabledPlanInputs,
    EventTypesEnabledOption,
    PlanStateActions,
    PlanStateActionType
} from "./types";


/**
 * when planOptions change, use this to get new disabledInputs
 * @param planState
 */
function disabledInputsForState(state: CGAPNewPlanState): DisabledPlanInputs {
    if(state.isLoading) {
        return disabledInputsInitialState;
    }

    const newDisabledInputs = {
        ...disabledInputsInitialState,
        groupSegment: false,
        eventType: !state.selectedGroupOptionValue?.length,
    };

    newDisabledInputs.budget = state.selectedEventTypesOptionValue === EventTypesEnabledOption.none;
    newDisabledInputs.previousYear = newDisabledInputs.budget;

    return newDisabledInputs;
}

export const planStateReducer = (
    planState: CGAPNewPlanState,
    action: PlanStateActions
): CGAPNewPlanState => {
    const updatedPlanState: CGAPNewPlanState = _cloneDeep(planState);
    let isPlanChanged = false;

    switch (action.type) {
        case PlanStateActionType.SET_BUDGET:
            if(updatedPlanState?.planOptions?.budget) {
                if(action.payload.key in updatedPlanState.planOptions.budget) {
                    updatedPlanState.planOptions.budget[action.payload.key] = action.payload.value;
                    isPlanChanged = true;
                }
            }
            break;
        case PlanStateActionType.SET_CLIENT_GROUP_OPTIONS:
            updatedPlanState.planOptions.clientGroup = _cloneDeep(action.payload.clientGroup);
            updatedPlanState.selectedGroupOptionValue = action.payload.selectedGroupOptionValue;

            if(updatedPlanState?.planOptions) {
                // Set a default budget for groups, not segments
                const {clientGroup} = updatedPlanState.planOptions;

                if (clientGroup.groupFilter && !clientGroup.segmentFilter) {
                    updatedPlanState.planOptions.budget = _cloneDeep(clientGroup.budget);
                }
            }
            isPlanChanged = true;
            break;
        case PlanStateActionType.SET_EVENT_TYPES_ENABLED:
            updatedPlanState.planOptions.eventTypes.hosted = action.payload === EventTypesEnabledOption.hosted || action.payload === EventTypesEnabledOption.both;
            updatedPlanState.planOptions.eventTypes.sponsored = action.payload === EventTypesEnabledOption.sponsored || action.payload === EventTypesEnabledOption.both;
            updatedPlanState.selectedEventTypesOptionValue = action.payload;

            isPlanChanged = true;
            break;
        case PlanStateActionType.SET_LOADING:
            updatedPlanState.isLoading = action.payload;
            isPlanChanged = true;
            break;
    }

    // debugger;
    if(isPlanChanged) {
        updatedPlanState.disabledInputs = disabledInputsForState(updatedPlanState)
        return updatedPlanState;
    }

    return planState;
};
