import React, { ReactElement, useContext } from "react";
import {
  StyledEventsByCalendarView,
  StyledHeader,
  StyledHeaderLeft,
  StyledHeaderRight,
} from "./style";

import CalendarYear from "./components/calendar-year";
import { useIntl } from "react-intl";
import { FlatButton } from "../../styles/buttons";
import { StyleH5Header } from "../../styles/theme";
import { makeEvent } from "../../store/reducer";
import { planningContext } from "../../views/planning/context";
import { store } from "../../store";

const EventsByCalendarView = (): ReactElement => {
  const { state } = useContext(store);
  const intl = useIntl();
  const { setEventModalState } = useContext(planningContext);

  return (
    <StyledEventsByCalendarView>
      <StyledHeader>
        <StyledHeaderLeft>
          <StyleH5Header>
            {intl.formatMessage({ id: "calendarView.title" })}
          </StyleH5Header>
          <p>{intl.formatMessage({ id: "calendarView.description" })}</p>
        </StyledHeaderLeft>
        <StyledHeaderRight>
          <FlatButton
            size={16}
            onClick={() => {
              setEventModalState({
                isVisible: true,
                isAdding: true,
                sourceEvent: makeEvent({}, state?.planOptions),
              });
            }}
          >
            {intl.formatMessage({ id: "addNewEvent" })}
          </FlatButton>
        </StyledHeaderRight>
      </StyledHeader>
      <CalendarYear />
    </StyledEventsByCalendarView>
  );
};

export default EventsByCalendarView;
