import React, { ReactElement, useContext, useEffect, useState } from "react";
import { StyledEventCalendar } from "./style";
import { store } from "../../../../store";
import { CGAPCalendarListItem } from "../../types";
import _cloneDeep from "lodash.clonedeep";
import EventCalendarMonth from "../calendar-month";
import { planningContext } from "../../../../views/planning/context";

type EventCalendarProps = {
  prop?: unknown;
};

const emptyCalendarList: CGAPCalendarListItem[] = [];
for (let i = 0; i < 12; i++) {
  emptyCalendarList.push({
    monthNumber: i,
    events: [],
  });
}

const CalendarYear = ({}: EventCalendarProps): ReactElement => {
  const { state } = useContext(store);
  const { viewFilters } = useContext(planningContext);
  const [calendarList, setCalendarList] = useState<CGAPCalendarListItem[]>([]);

  useEffect(() => {
    if (state?.eventsSummary?.events?.length) {
      let eventsSource = state.eventsSummary.events;

      if (!viewFilters.all) {
        eventsSource = state.eventsSummary.events.filter(
          (event) => viewFilters[event.type]
        );
      }

      const reducedEvents = eventsSource.reduce((monthlyEvents, event) => {
        monthlyEvents?.[event.date.getMonth()]?.events?.push(event);
        return monthlyEvents;
      }, _cloneDeep(emptyCalendarList));

      setCalendarList(reducedEvents);
    }
  }, [state?.events, viewFilters]);

  return (
    <StyledEventCalendar>
      {calendarList.length &&
        calendarList.map((calendarData: CGAPCalendarListItem, index) => (
          <EventCalendarMonth
            key={`calendar-item-${index}`}
            calendarData={calendarData}
          />
        ))}
    </StyledEventCalendar>
  );
};

export default CalendarYear;
