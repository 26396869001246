import _cloneDeep from "lodash.clonedeep";
import {
  PTActionType,
  PTDispatchActionTypes,
  PTResponse,
  PTRow,
  PTState,
} from "./types";

export function ptReducer(
  state: PTState,
  action: PTDispatchActionTypes
): PTState {
  let newState = _cloneDeep(state);

  switch (action.type) {
    case PTActionType.INIT_STATE:
      if (action.payload.eventTitles?.length) {
        newState.inAlert = false;
        newState.rows = action.payload.eventTitles.map((title) => {
          const newRow: PTRow = {
            title,
            response: [],
            inAlert: false,
          };

          for (let i = 0; i < action.payload.columns - 1; i++) {
            newRow.response.push(PTResponse.YES);
          }

          return newRow;
        });
      }
      break;
    case PTActionType.SET_STATE:
      newState = _cloneDeep(action.payload);
      break;
    case PTActionType.SET_ROW_RESPONSE:
      try {
        newState.rows[action.payload.row].response[action.payload.col] =
          action.payload.response;
        newState.rows[action.payload.row].inAlert =
          newState.rows[action.payload.row].response.filter(
            (response) => response === PTResponse.NO
          ).length >= 2;
        newState.inAlert =
          newState.rows.filter((response) => response.inAlert).length > 0;
      } catch (e) {
        console.warn(
          `[PTReducer] when updating row ${action.payload.row}, col ${action.payload.col}: `,
          e
        );
      }
      break;
  }

  return newState;
}
