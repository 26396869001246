export enum PTActionType {
    INIT_STATE = "INIT_STATE",
    SET_STATE = "SET_STATE",
    SET_ROW_RESPONSE = "SET_ROW_RESPONSE"
}

export enum PTResponse {
    UNSET,
    YES,
    NO,
    UNUSED
}

export interface PTRow {
    title: string;
    inAlert: boolean;
    response: PTResponse[]
}

export interface PTState {
    rows: PTRow[];
    inAlert: boolean;
}

export interface PTInitStateAction {
    type: typeof PTActionType.INIT_STATE;
    payload: {
        eventTitles: string[];
        columns: number;
    };
}

export interface PTSetStateAction {
    type: typeof PTActionType.SET_STATE;
    payload: PTState;
}

export interface PTSetRowResponseAction {
    type: typeof PTActionType.SET_ROW_RESPONSE;
    payload: {
        row: number;
        col: number;
        response: PTResponse;
    };
}

export type PTDispatchActionTypes =
     | PTInitStateAction
     | PTSetStateAction
     | PTSetRowResponseAction;

export function getInitialPTState(): PTState {
    return {
        rows: [],
        inAlert: false
    }
}

