import styled, { css } from "styled-components";
import {
  deviceWidths,
  StyleFontRoman,
  ThemeColors,
} from "../../styles/theme";

const headerTextTreatments = css`
  ${StyleFontRoman};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;

  @media screen and ${deviceWidths.max} {
    font-size: 15px;
  }
`;

export const StyledScorebox = styled.div`
  margin: 20px 0;

  @media screen and ${deviceWidths.tablet} {
    margin: 40px 0;
  }
`;

export const StyledScoreboxRow = styled.tr`
  ${StyleFontRoman};

  font-weight: bold;

  &:nth-child(even) > td {
    background-color: ${ThemeColors.Black03};
  }
`;
export const StyledScoreboxTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const ScoreboxBorder = {
  NONE: "transparent",
  GRAY: ThemeColors.GrayE5,
  BLACK: ThemeColors.Black,
};

export interface StyledScoreboxCellProps {
  borderColor?: string;
}

export const StyledScoreboxCell = styled.td<StyledScoreboxCellProps>`
  padding: 1rem;
  text-align: center;

  line-height: 1.25rem;
  ${(props) => props.borderColor ? `border-bottom: 1px solid ${props.borderColor};` : null};

  @media screen and ${deviceWidths.max} {
    line-height: 1.5rem;
  }
`;

export const StyledSpacerColumn = styled(StyledScoreboxCell)`
  width: 5px;
`;

export const StyledCountColumn = styled(StyledScoreboxCell)`
  width: 85px;
`;

export const StyledCostColumn = styled(StyledScoreboxCell)`
  text-align: right;
  width: 175px;
`;

export const StyledHeaderRow = styled.tr`
  ${headerTextTreatments};

  > td {
    vertical-align: bottom;
    padding: 5px 0;
  }
`;

export const StyledScoreboxRowLabel = styled(StyledScoreboxCell)`
  text-align: right;
`;
