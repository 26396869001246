import React, {ReactElement, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {
  FormControl, InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { CGAPEventFieldType } from "../../../../types/annual-planner";


import { useCGSelectStyles } from "../../../../styles/cg-material-ui";
import { CGCSelectOption } from "../../../../types/common";

type EventFieldSelectProps = {
  id: string;
  fieldName: string;
  fieldValue: CGAPEventFieldType;
  placeholderIntlId: string;
  updateEventField: (fieldName: string, value: CGAPEventFieldType) => void;
  valueConverter?: (value: string) => CGAPEventFieldType;
  options: CGCSelectOption[];
  isDisabled?: boolean;
  menuPlacement?: string;
};

function renderNoOptions() {
  return  [(
      <MenuItem key="noOptions" value="">
        <FormattedMessage id="noOptionsPlaceholder" />
      </MenuItem>
  )];
}

const EventFieldSelect = ({
  id,
  fieldName,
  fieldValue,
  placeholderIntlId,
  updateEventField,
  valueConverter,
  options,
  isDisabled = false
}: EventFieldSelectProps): ReactElement => {
  const classes = useCGSelectStyles();
  const [renderedOptions, setRenderedOptions] = useState<ReactElement[]>(() => renderNoOptions());

  useEffect(() => {
    if(options?.length) {
      const newROs = options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            {option.label}
          </MenuItem>
      ));

      setRenderedOptions(newROs);
    } else {
      setRenderedOptions(renderNoOptions());
    }

  }, [options])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValueString = event.target.value as string;

    let convertedValue: CGAPEventFieldType = newValueString;

    if (typeof valueConverter === "function") {
      convertedValue = valueConverter(newValueString);
    }

    updateEventField(fieldName, convertedValue);
  };

  const labelId = `${id}-label`

  return (
      <FormControl className={classes.formControl} margin="none">
        <InputLabel id={labelId}>
          <FormattedMessage id={placeholderIntlId}/>
        </InputLabel>
        <Select
            id={id}
            labelId={labelId}
            value={fieldValue}
            className={classes.selectEmpty}
            onChange={handleChange}
            disabled={isDisabled}
            displayEmpty
        >
          {renderedOptions}
        </Select>
      </FormControl>
  );
};

export default EventFieldSelect;
