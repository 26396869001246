import styled from "styled-components";
import {CGCDisableable} from "../../../../types/common";
import {deviceWidths, StyleFontRoman, StyleH6Header, ThemeColors} from "../../../../styles/theme";
import {makeTransition} from "../../../../styles/animations";

export const StyledLAB = styled.div<CGCDisableable>`
  display: grid;
  grid-template-rows: auto 1fr auto;
  padding: 1.5rem;
  background-color: ${ThemeColors.White};
  ${makeTransition(["opacity", "background-color"], 1)}

  ${(props) => {
    if (props.disabled) {
        return `opacity: 0.5; background-color: ${ThemeColors.White50}`;
    }
  }} 
  
  @media screen and ${deviceWidths.desktop} {
    min-height: 250px;
  }
`;

export const StyledLABTitle = styled(StyleH6Header)`
  margin: 0 0 1em 0;

  @media screen and ${deviceWidths.desktop} {
    margin: 0 0 2.5em 0;
  }
`;

export const StyledLABInstructions = styled.div`
  margin: 0 0 1em 0;
  ${StyleFontRoman}
  font-size: 16px;
  line-height: 1.5em;

  @media screen and ${deviceWidths.desktop} {
    font-size: 14px;
    margin: 0 0 2.5em 0;
  }

  @media screen and ${deviceWidths.max} {
    font-size: 16px;
  }
`;
export const StyledLABContent = styled.div``;
