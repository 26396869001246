import React, {ReactElement, useContext} from "react";
import {useIntl} from "react-intl";
import _set from "lodash.set";
import {FormControlLabel} from "@material-ui/core";
import {CGCheckbox} from "../../../material/cg-checkbox";
import {budgetPlanningContext} from "../../context";
import _cloneDeep from "lodash.clonedeep";
import {EventFormActionType} from "../../types";

const OverrideCheckbox = (): ReactElement => {
    const intl = useIntl();
    const {eventFormState, eventFormDispatch} = useContext(budgetPlanningContext);

    return (
        <FormControlLabel
            control={
                <CGCheckbox
                    checked={eventFormState.event?.cost?.overrideMethodology || false}
                    onChange={(event) => {
                        if (eventFormState?.event) {
                            const updatingEvent = _cloneDeep(eventFormState.event);
                            _set(updatingEvent, 'cost.overrideMethodology', event.target.checked);

                            eventFormDispatch({
                                type: EventFormActionType.UPDATE_EVENT,
                                payload: updatingEvent,
                            });
                        }
                    }}
                />
            }
            label={intl.formatMessage({id: "eventField.overrideCost"})}
        />
    );
};

export default OverrideCheckbox;
