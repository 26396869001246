import React, {ReactElement, useContext, useEffect, useState} from "react";
import GroupBox from "../group-box";
import {StyledFormElement, StyledGridColumns} from "../../style";
import EventFieldSelect from "../event-field-select";
import _get from "lodash.get";
import {budgetPlanningContext} from "../../context";
import BudgetPlanningHostedInPerson from "./hosted-inperson";
import {EventFormat, EventType} from "../../../../constants";
import BudgetPlanningHostedVirtual from "./hosted-virtual";
import BudgetPlanningSponsored from "./sponsored";

type BudgetPlanningProps = {
    prop?: unknown;
};

const BudgetPlanning = ({}: BudgetPlanningProps): ReactElement => {
    const {eventFormState, updateEventField, localizedLists } = useContext(budgetPlanningContext);
    const [budgetCostFields, setBudgetCostFields] = useState<ReactElement>(<BudgetPlanningHostedInPerson />);

    useEffect(() => {
        if(eventFormState.event?.type === EventType.sponsored) {
            setBudgetCostFields(<BudgetPlanningSponsored />)
        } else {
            if(eventFormState.event?.format === EventFormat.Virtual ) {
                setBudgetCostFields(<BudgetPlanningHostedVirtual/>)
            } else if(eventFormState.event?.format === EventFormat.Webinar) {
                setBudgetCostFields(<></>)
            } else {
                setBudgetCostFields(<BudgetPlanningHostedInPerson/>)
            }
        }
    }, [
        eventFormState.event?.format,
        eventFormState.event?.type,
    ]);

    return (
        <GroupBox title="BUDGET PLANNING">
            <StyledGridColumns count={2}>
                <StyledFormElement data-comment="Attendees">
                    <EventFieldSelect
                        id="edit-modal-people-count"
                        fieldName="peopleCount"
                        placeholderIntlId="attendees"
                        options={localizedLists.attendeesOptions}
                        fieldValue={eventFormState?.event?.peopleCount.toString() || ''}
                        valueConverter={(value: string) => parseInt(value)}
                        updateEventField={updateEventField}
                    />
                </StyledFormElement>
                <StyledFormElement data-comment="Days">
                    <EventFieldSelect
                        id="edit-modal-day-count"
                        fieldName="dayCount"
                        isDisabled={_get(
                            eventFormState,
                            `tierConfig.${eventFormState.event?.format}.days.isFixed`,
                            true
                        )}
                        placeholderIntlId="days"
                        options={localizedLists.daysOptions}
                        menuPlacement="top"
                        fieldValue={eventFormState.event?.dayCount?.toString() || "1"}
                        valueConverter={(value: string) => parseInt(value)}
                        updateEventField={updateEventField}
                    />
                </StyledFormElement>
            </StyledGridColumns>
            {budgetCostFields}
        </GroupBox>
    );
};

export default BudgetPlanning;
