import React, {ReactElement, useState} from "react";
import {StyledLandingActions, StyledLandingContent, StyledLandingWizard} from "./style";
import {CGAPLandingWizardStep} from "./types";
import LandingMenu from "./components/landing-menu";
import NewPlan from "./components/new-plan";
import LoadPlan from "./components/load-plan";
import { FlatButton } from "../../styles/buttons";
import {FormattedMessage} from "react-intl";

type LandingWizardProps = {
    prop?: unknown;
};


const LandingWizard = ({}: LandingWizardProps): ReactElement => {

    const [step, setStep] = useState<CGAPLandingWizardStep>(CGAPLandingWizardStep.menu);
    let currentStepComponent: ReactElement;

    switch(step) {
        case CGAPLandingWizardStep.loadPreviousPlan:
            currentStepComponent = <LoadPlan />
            break;
        case CGAPLandingWizardStep.newPlan:
            currentStepComponent = <NewPlan />
            break;
        default:
            currentStepComponent = <LandingMenu setStep={setStep}/>
    }

    return (
        <StyledLandingWizard>
            <StyledLandingContent>
                {currentStepComponent}
            </StyledLandingContent>
            <StyledLandingActions>
                {step !== CGAPLandingWizardStep.menu && (
                    <FlatButton onClick={() => { setStep(CGAPLandingWizardStep.menu)}}>
                        <FormattedMessage id="landing.back" />
                    </FlatButton>
                )}
            </StyledLandingActions>
        </StyledLandingWizard>
    );
};

export default LandingWizard;
