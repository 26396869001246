export const localeMessages = {
  // Global items
  addNewEvent: "Add New Event",
  annualBudget: "Annual Budget",
  attendees: "Attendees",
  baseYear: "Annual events for {baseYear}",
  budgetSource: "Budget Source",
  capacity: "Capacity",
  city: "City",
  clientGroup: "Client Group",
  clientSegment: "Client Segment",
  close: "Close",
  cost: "Cost",
  costEstimate: "Cost Estimate",
  customVideo: "Custom Video",
  days: "Days",
  edit: "Edit",
  enterHostedBudgetLimit: "Enter Hosted Budget Limit",
  enterSponsoredBudgetLimit: "Enter Sponsored Budget Limit",
  eventName: "Event Name",
  guestSpeaker: "Guest Speaker",
  hostedEventsBudget: "Hosted Events Budget",
  sponsoredEventsBudget: "Sponsored Events Budget",
  hostedEvents: "Hosted Events",
  sponsoredEvents: "Sponsored Events",
  totalBudget: "Total Budget",
  inPerson: "In-Person",
  month: "Month",
  no: "No",
  noSegment: "No Segment",
  noOptionsPlaceholder: "No Options Available",
  percentage: "Utilization: {percentage}",
  percentageDisabled: "Utilization: n/a",
  percentExpenditure: "% of Expenditure",
  projectedTotalCost: "Projected Total Cost",
  remainingBudgetAvailable: "Remaining Budget",
  selectOne: "Select One",
  share: "Share",
  tier: "Tier",
  tierWithNumber: "Tier {number}",
  type: "Type",
  totalHours: "Total Hours: {hours}",
  totalHoursDisabled: "Total Hours: n/a",
  virtual: "Virtual",
  webinar: "Webinar",
  yes: "Yes",

  // Event Type - for add/edit event form
  "eventType.hosted": "Hosted Event",
  "eventType.sponsored": "Sponsored Event",

  // Event Types - for landing form
  "EventTypesEnabledOption.hosted": "Hosted Events",
  "EventTypesEnabledOption.sponsored": "Sponsored Events",
  "EventTypesEnabledOption.both": "Hosted and Sponsored Events",

  // Locations
  "location.N_A": "N/A",
  "location.LAO": "Los Angeles",
  "location.LAO-offsite": "Los Angeles Offsite",
  "location.NYC": "New York",
  "location.DC": "Washington D.C.",
  "location.SFO": "San Francisco",
  "location.CHI": "Chicago",

  // Months
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",

  "actionRibbon.top.baseYearEventsTotal": "Base Year Events Total",
  "actionRibbon.top.adjustedEventsTotal": "Adjusted Events Total",
  "actionRibbon.top.showSponsoredEvents": "Show Sponsored Events",
  "actionRibbon.top.showHostedEvents": "Show Hosted Events",

  "config.loading": "Loading configuration",
  "config.errorLoading":
    "There was an error loading a new configuration. Some features may not be available.",

  // Event Fields
  "eventField.budgetSource": "Budget Source",
  "eventField.clientGroup": "Client Group",
  "eventField.clientSegment": "Client Segment",
  "eventField.dayCount": "Days",
  "eventField.effort": "Hours",
  "eventField.estimatedCost": "Cost",
  "eventField.eventCost": "Event Cost",
  "eventField.eventCostTotal": "Event Cost Total",
  "eventField.eventOnlyCost": "Event Only Cost",
  "eventField.format": "Format",
  "eventField.hasCustomVideo": "Custom Video",
  "eventField.hasGuestSpeaker": "Speaker",
  "eventField.hostedSponsored": "Hosted / Sponsored",
  "eventField.type": "Type",
  "eventField.locationId": "City",
  "eventField.date": "Date",
  "eventField.peopleCount": "Attendees",
  "eventField.year": "Year",
  "eventField.title": "Name",
  "eventField.tier": "Tier",
  "eventField.xAttendees": "{number} attendees",
  "eventField.xDayEvent": "{number} day event",
  "eventField.overrideCost": "Override cost methodology",
  "eventField.cost": "Cost",
  "eventField.costPerDay": "Cost per day",
  "eventField.costPerPerson": "Cost per person",
  "eventField.costProductionPerDay": "Production cost per day",
  "eventField.costSponsoredFlat": "Sponsored event flat cost",
  "eventField.overrideMonthlyResourceHours": "Override monthly resource hours",
  "eventField.hoursNeeded": "Hours needed",
  "eventField.monthOf": "Month of",
  "eventField.oneMonthOut": "1 month out",
  "eventField.xMonthsOut": "{number} months out",

  // Calendar items
  "calendarView.title": "Calendar View",
  "calendarView.description":
    "In the calendar view you can see the sequence of events over the year. This view highlights if you have selected too many events on any given month based on your allotted capacity of the NACG Events Team. If you have exceeded capacity a red dot will appear. Please click on an event to change to a month where there is more capacity.",

  // App Hero
  "hero.smallTitle": "CAPITAL GROUP ANNUAL EVENT PLANNER",
  "hero.largeTitle": "Building Annual Event Mix",

  // Landing items
  "landing.back": "BACK",
  "landing.menu.description":
    "The Annual event planner is an interactive web tool that empowers Client Group/Segment leadership to plan out the mix of events for the year and align it to their allocated budget.",

  "landing.menu.actions.title": "LOAD SAVED PLANS",
  "landing.menu.actions.instruction":
    "Would you like to load a previously saved budget or start a new annual budget?",
  "landing.menu.actions.load-previous-plan": "Load Previous Plan",
  "landing.menu.actions.start-new-plan": "Start New Plan",

  "landing.load-previous-plan.title": "LOAD PREVIOUSLY SAVED BUDGETS",
  "landing.load-previous-plan.instruction":
    "Select a saved budget plan you would like to restore",
  "landing.load-previous-plan.placeholder": "Select previously saved plan",

  "landing.new-plan.select-group-segment.title":
    "SELECT YOUR CLIENT GROUP OR CLIENT SEGMENT",
  "landing.new-plan.select-group-segment.instruction":
    "Select if you are planning for a Client Group or Client Segment using the dropdown below.",

  "landing.new-plan.select-event-type.title": "CHOOSE YOUR EVENT TYPE",
  "landing.new-plan.select-event-type.instruction":
    "Choose if you're planning for Hosted events, Sponsored events or both",

  "landing.new-plan.indicate-budget.title": "INDICATE YOUR ANNUAL BUDGET",
  "landing.new-plan.indicate-budget.instruction":
    "If your budget is not pre-populated amount, please enter your budget manually.",

  "landing.new-plan.select-how.title": "CHOOSE HOW YOU PLAN",
  "landing.new-plan.select-how.instruction":
    "Start planning your annual events from a previous year's event mix or start from scratch.",

  "landing.yearEventMix": "{year} Event Mix",
  "landing.newEventMix": "New Event Mix from Scratch",
  "landing.groupOptionLabel": "{group} Client Group",
  "landing.segmentOptionLabel": "{segment} Client Segment",

  // Planning View
  "planning.startOver": "Start Over",
  "planning.viewEventsByCalendar": "Calendar View",
  "planning.viewEventsByTier": "List View",
  "planning.viewSummary": "View Summary",

  // Event by Tier View
  "eventsByTier.noEvents": "No Tier {tierNumber} events",

  // Delete Confirmation
  "deleteModal.title": "CONFIRM DELETE EVENT",
  "deleteModal.description":
    "This will remove the event {eventTitle}. There is no undo.",
  "deleteModal.actions.cancel": "Keep Data",
  "deleteModal.actions.confirm": "Delete",

  // Event Modal
  "eventModal.title.add": "Add New Event Information",
  "eventModal.title.edit": "Edit Event Information",
  "eventModal.description": "Modify event characteristics below.",
  "eventModal.actions.cancel": "Cancel",
  "eventModal.actions.save": "Save",

  // Pressure Test Confirmation
  "pressureTestModal.Title": "Pressure Test Tier 1 Events",
  "pressureTestModal.Description":
    "Tier 1 events are the most expensive and time consuming events to host. All Tier 1 events must be “pressure tested” in order to ensure they meet the criteria for Tier 1 events. Consider whether the business objectives can be fulfilled with a more cost effective Tier 2 or Tier 3 that still achieves memorability and an elevated experience for attendees. If your event does not meet the majority of the criteria laid out then you should strongly consider decreasing the tier of your event.",
  "pressureTestModal.actions.cancel": "Cancel",
  "pressureTestModal.actions.confirm": "Proceed",

  // Pressure Test Confirmation
  "pressureTest.Title": "PRESSURE TEST TIER1 EVENTS",
  "pressureTest.AlertTitle": "Alert",
  "pressureTest.AlertMsg":
    "Your event does not meet the criteria for a Tier 1 event. Please confirm if you can accomplish your objectives leveraging existing technology and content for less cost",
  "pressureTest.actions.share": "Share",
  "pressureTest.actions.close": "Close",
  "pressureTest.actions.goBack": "Go Back",
  "pressureTest.column.HighlightedEvents": "Highlighted Events",
  "pressureTest.column.AlignmentLTS": "Alignment to Long-Term CG Strategy",
  "pressureTest.column.AlignmentWMPlan":
    "Alignment to the Client Group business plan / 3-5 year priorities",
  "pressureTest.column.BroadenReach": "Broaden Reach / CG Brand",
  "pressureTest.column.LargeSalesOpp": "Large Sales Opportunity",
  "pressureTest.column.AumRetention": "AUM Retention / Client Service",
  "pressureTest.column.StrategicPartnerships": "Strategic Partnerships",
  "pressureTest.column.CGExecLeadership": "Involves CG Executive Team",

  "segmentShare.hosted.costEstimate": "Hosted Events Cost Estimate",
  "segmentShare.hosted.percentExpenditure": "Hosted Events % of Expenditure",
  "segmentShare.sponsored.costEstimate": "Sponsored Events Cost Estimate",
  "segmentShare.sponsored.percentExpenditure": "Sponsored Events % of Expenditure",

  // Start Over Confirmation
  "startOverModal.title": "Start Over",
  "startOverModal.description":
    "Click 'Start Over' to erase existing event data and start from the beginning. Otherwise click 'Cancel'.",
  "startOverModal.actions.cancel": "Cancel",
  "startOverModal.actions.confirm": "Start Over",

  // Summary Modal
  "summaryModal.title": "Annual Event Summary (Printable Report)",
  "summaryModal.description":
    "Based on your selections in step one, the following is a starting point for planning your annual events for your Client Group/Segment. You can edit any field on this page. After completing this step you will be able to see a calendar view to highlight the sequence of events, finalize your event mix to optimize your budget, and create an output report.",
  "summaryModal.actions.cancel": "Close",
  "summaryModal.actions.confirm": "Print Report",
  "summaryModal.totalXCost": "Total {segmentLabel} Cost",
};
