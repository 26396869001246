import React, { ReactElement, useContext } from "react";
import {
  LandingWizardContainer,
  StyledLandingPage,
} from "./style";
import { store } from "../../store";
import LoadingConfiguration from "../../components/loading-configuration";
import LandingWizard from "../../components/landing-wizard";

const LandingView = (): ReactElement => {
  const { state } = useContext(store);

  return (
    <StyledLandingPage>
      <LoadingConfiguration isVisible={!!state?.isLoading}/>
      <LandingWizardContainer isVisible={!state?.isLoading}>
        <LandingWizard/>
      </LandingWizardContainer>
    </StyledLandingPage>
  );
};

export default LandingView;
