import React, { ReactElement, useContext, useEffect, useState } from "react";
import { StyledActionRibbonTop, StyledActions, StyledToggles } from "./style";
import { CGAPPlannerView } from "../../constants";
import { store } from "../../store";
import LabeledBox from "../labeled-box";
import { currencyFormatter } from "../../util/formatters";
import { useIntl } from "react-intl";
import { ToggleButton } from "../../styles/buttons";
import { AppActionType } from "../../store/types";
import { CGSwitch } from "../material/cg-switch";
import { FormControlLabel } from "@material-ui/core";
import { planningContext } from "../../views/planning/context";

type ActionRibbonTopProps = {
  prop?: unknown;
};

const defaultClientGroupLabel = "No Client Group Set";

const ActionRibbonTop = ({}: ActionRibbonTopProps): ReactElement => {
  const { state, dispatch } = useContext(store);
  const { viewFilters, updateViewFilters } = useContext(planningContext);
  const intl = useIntl();

  const [clientGroupLabel, setClientGroupLabel] = useState(
    defaultClientGroupLabel
  );

  useEffect(() => {
    if (state?.planOptions?.clientGroup) {
      const { segmentFilter, groupFilter } = state.planOptions.clientGroup;

      if (segmentFilter) {
        setClientGroupLabel(segmentFilter);
      } else {
        setClientGroupLabel(groupFilter);
      }
    } else {
      setClientGroupLabel(defaultClientGroupLabel);
    }
  }, [
    state?.planOptions?.clientGroup?.label,
    state?.planOptions?.clientGroup?.segmentFilter,
    state?.planOptions?.clientGroup?.groupFilter,
  ]);

  return (
    <StyledActionRibbonTop>
      <LabeledBox
        fullwidth
        label={intl.formatMessage({ id: "clientGroup" })}
        content={clientGroupLabel}
      />
      <LabeledBox
        fullwidth
        label={intl.formatMessage({
          id: "actionRibbon.top.baseYearEventsTotal",
        })}
        content={(state?.planOptions?.baseYearEventCount || 0).toString()}
      />
      <LabeledBox
        fullwidth
        label={intl.formatMessage({
          id: "actionRibbon.top.adjustedEventsTotal",
        })}
        content={(state?.eventsSummary.events.length ?? 0).toString()}
      />
      <StyledActions style={{ gridArea: "tabs" }}>
        <ToggleButton
          isActive={state?.planningView === CGAPPlannerView.EVENTS_BY_TIER}
          onClick={() => {
            dispatch({
              type: AppActionType.SET_PLANNING_VIEW,
              payload: CGAPPlannerView.EVENTS_BY_TIER,
            });
          }}
        >
          {intl.formatMessage({ id: "planning.viewEventsByTier" })}
        </ToggleButton>
        <ToggleButton
          isActive={state?.planningView === CGAPPlannerView.EVENTS_BY_CALENDAR}
          onClick={() => {
            dispatch({
              type: AppActionType.SET_PLANNING_VIEW,
              payload: CGAPPlannerView.EVENTS_BY_CALENDAR,
            });
          }}
        >
          {intl.formatMessage({ id: "planning.viewEventsByCalendar" })}
        </ToggleButton>
      </StyledActions>
      {viewFilters?.hosted && (
        <LabeledBox
          fullwidth
          label={intl.formatMessage({ id: "hostedEventsBudget" })}
          content={currencyFormatter.format(
            state?.planOptions.budget?.hosted ?? 0
          )}
        />
      )}
      {viewFilters?.sponsored && (
        <LabeledBox
          fullwidth
          label={intl.formatMessage({ id: "sponsoredEventsBudget" })}
          content={currencyFormatter.format(
            state?.planOptions.budget?.sponsored ?? 0
          )}
        />
      )}
      {viewFilters?.all && (
        <LabeledBox
          fullwidth
          label={intl.formatMessage({ id: "totalBudget" })}
          content={currencyFormatter.format(
            state?.planOptions?.budget?.total ?? 0
          )}
        />
      )}
      {!viewFilters?.all && (
        <>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
        </>
      )}
      <StyledToggles>
        <FormControlLabel
          disabled={!state?.planOptions.eventTypes.sponsored}
          control={
            <CGSwitch
              checked={viewFilters.sponsored}
              onChange={(event) => {
                updateViewFilters("sponsored", event.target.checked);
                console.info("cg switch sponsored", event.target.checked);
              }}
            />
          }
          label={intl.formatMessage({
            id: "actionRibbon.top.showSponsoredEvents",
          })}
        />
        <FormControlLabel
          disabled={!state?.planOptions.eventTypes.hosted}
          control={
            <CGSwitch
              checked={viewFilters.hosted}
              onChange={(event) => {
                updateViewFilters("hosted", event.target.checked);
                console.info("cg switch hosted", event.target.checked);
              }}
            />
          }
          label={intl.formatMessage({
            id: "actionRibbon.top.showHostedEvents",
          })}
        />
      </StyledToggles>
    </StyledActionRibbonTop>
  );
};

export default ActionRibbonTop;
