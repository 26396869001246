import React, {ReactElement} from "react";
import {CircularProgress, CircularProgressProps, createStyles, makeStyles} from "@material-ui/core";
import {ThemeColors} from "../../styles/theme";

const useStylesFacebook = makeStyles(() =>
    createStyles({
        root: {
            position: 'relative',
        },
        top: {
            color: ThemeColors.Ocean,
            animationDuration: '1s',
        },
        circle: {
            strokeLinecap: 'round',
        },
    }),
);

export const CGProgressSpinner = (props: CircularProgressProps): ReactElement => {
    const classes = useStylesFacebook();

    return (
            <CircularProgress
                disableShrink={props.variant === "indeterminate"}
                className={classes.top}
                classes={{
                    circle: classes.circle,
                }}
                size={40}
                thickness={4}
                {...props}
            />
    );
}
