import {createContext} from "react";
import {CGAPAppState, CGAPStore} from "./types";
import {getEventSummaryInitial} from "../types/annual-planner";
import {blankConfig, CGAPPlannerView} from "../constants";
import add from 'date-fns/add';

export const initialAppState: CGAPAppState = {
  planOptions: {
    baseYear: "",
    baseYearEventCount: 0,
    budget: {
      hosted: 0,
      sponsored: 0
    },
    clientGroup: {
      budget: {
        hosted: 0,
        sponsored: 0
      },
      capacityShare: 0,
      label: '',
      groupFilter: '',
      segmentFilter: null
    },
    eventTypes: {
      hosted: false,
      sponsored: false,
    },
  },
  planningView: CGAPPlannerView.EVENTS_BY_TIER,
  planningYear: add(new Date(), {years: 1}).getFullYear(),
  config: blankConfig,
  error: '',
  events: [],
  eventsSummary: getEventSummaryInitial(),
  isError: false,
  isLoading: true,
};

const store = createContext<CGAPStore>({
  state: initialAppState,
  dispatch: undefined,
});

const { Provider } = store;
export { store, Provider as StateProvider };
