import React, { ReactElement } from "react";
import { CenterModal } from "react-spring-modal";
import "react-spring-modal/dist/index.css";
import {StyledConfirmationActions, StyledConfirmationModal} from "./style";
import {
  CancelButtonWithIcon,
  ConfirmButtonWithIcon
} from "../../styles/buttons";
import {StyledModalDescription, StyledModalHeader} from "../../styles/modals";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

type ConfirmationModalProps = {
  title: string;
  bodyCopy: string;
  isOpen: boolean;
  closeModalWithDisposition: (disposition: boolean) => void; // true -> success, false -> cancelled
  confirmText?: string;
  cancelText?: string;
};

const ConfirmationModal = ({
  title,
  bodyCopy,
  confirmText = "PROCEED",
  cancelText = "Cancel",
  isOpen,
  closeModalWithDisposition,
}: ConfirmationModalProps): ReactElement => {
  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={() => {
        /* noop */
      }}
    >
      <StyledConfirmationModal>
        <StyledModalHeader>{title}</StyledModalHeader>
        <StyledModalDescription>{bodyCopy}</StyledModalDescription>
        <StyledConfirmationActions>
          <CancelButtonWithIcon
            onClick={() => {
              closeModalWithDisposition(false);
            }}
          >
            {cancelText}
            <ArrowForwardIcon/>
          </CancelButtonWithIcon>
          <ConfirmButtonWithIcon
            onClick={() => {
              closeModalWithDisposition(true);
            }}
          >
            {confirmText}
            <ArrowForwardIcon/>
          </ConfirmButtonWithIcon>
        </StyledConfirmationActions>
      </StyledConfirmationModal>
    </CenterModal>
  );
};

export default ConfirmationModal;
