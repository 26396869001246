import {css, FlattenSimpleInterpolation} from "styled-components";

export const baseTimingMS = 700;


export function makeTransition(propNames: string[] = ['all'], multiplier = 1, easing = 'ease-in-out'): FlattenSimpleInterpolation {

    const myTimingMS = baseTimingMS * multiplier;
    const transitions = propNames.map((propName) => `${propName} ${myTimingMS}ms ${easing}`).join(', ');

    return css`
        transition: ${transitions};
    `;
}
