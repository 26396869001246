import React, {ReactElement, useContext, useEffect, useReducer} from "react";
import WarningIcon from "@material-ui/icons/Warning";

import {
  PTAlert,
  PTAlertIconWrap,
  PTAlertMessage,
  PTAlertMessageWrap,
  PTFooter,
  PTFooterLeft,
  PTFooterRight,
  StyledCell,
  StyledGrid,
  StyledHeaderCell,
  StyledHeaderRow,
  StyledPressureTestModal,
  StyledPT,
  StyledRow,
} from "./style";
import {CenterModal} from "react-spring-modal";
import {store} from "../../store";
import {ptReducer} from "./reducer";
import {getInitialPTState, PTActionType, PTResponse} from "./types";
import {ConfirmButtonWithIcon, SecondaryButtonWithIcon,} from "../../styles/buttons";
import {StyledModalHeader} from "../../styles/modals";
import {useIntl} from "react-intl";
import {StyleH6Header} from "../../styles/theme";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import {CGSwitch} from "../material/cg-switch";
import {EventType} from "../../constants";

type PressureTestModalProps = {
  isOpen: boolean;
  onCtaGoBack: () => void;
  onCtaConfirm: () => void;
};

const columnTitleIds = [
  "pressureTest.column.HighlightedEvents",
  "pressureTest.column.AlignmentLTS",
  "pressureTest.column.AlignmentWMPlan",
  "pressureTest.column.BroadenReach",
  "pressureTest.column.LargeSalesOpp",
  "pressureTest.column.AumRetention",
  "pressureTest.column.StrategicPartnerships",
  "pressureTest.column.CGExecLeadership",
];

const PressureTestModal = ({
  isOpen,
  onCtaGoBack,
  onCtaConfirm,
}: PressureTestModalProps): ReactElement => {
  const { state } = useContext(store);
  const intl = useIntl();
  const [ptState, ptDispatch] = useReducer(ptReducer, getInitialPTState());

  useEffect(() => {
    if (isOpen) {
      let tier1EventTitles: string[] = [];

      if (state?.events?.length) {
        tier1EventTitles = state.events
          .filter((event) => event.tier === 1 && event.type !== EventType.sponsored)
          .map((event) => event.title);
      }

      ptDispatch({
        type: PTActionType.INIT_STATE,
        payload: {
          eventTitles: tier1EventTitles,
          columns: columnTitleIds.length,
        },
      });
    }
  }, [isOpen, state?.events]);

  const onResponseChanged = (checked: boolean, row: number, col: number) => {
    ptDispatch({
      type: PTActionType.SET_ROW_RESPONSE,
      payload: {
        row,
        col,
        response: checked ? PTResponse.YES : PTResponse.NO,
      },
    });
  };

  return (
    <CenterModal
      isOpen={isOpen}
      onRequestClose={() => {
        onCtaGoBack();
      }}
    >
      <StyledPressureTestModal>
        <StyledPT>
          <StyledModalHeader>
            {intl.formatMessage({ id: "pressureTest.Title" })}
          </StyledModalHeader>
          <StyledGrid>
            <StyledHeaderRow columns={columnTitleIds.length}>
              {columnTitleIds.map((titleId, colIdx) => (
                <StyledHeaderCell key={`title-${colIdx}`}>
                  {intl.formatMessage({ id: titleId })}
                </StyledHeaderCell>
              ))}
            </StyledHeaderRow>
            {ptState.rows.map((row, rowIdx) => (
              <StyledRow
                columns={columnTitleIds.length}
                inAlert={row.inAlert}
                key={`row-${rowIdx}`}
              >
                <StyledCell>{row.title}</StyledCell>
                {row.response.map((response, colIdx) => (
                  <StyledCell key={`col-${colIdx + 1}`}>
                    <CGSwitch
                      checked={response === PTResponse.YES}
                      onChange={(event) => {
                        onResponseChanged(event.target.checked, rowIdx, colIdx);
                      }}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </StyledCell>
                ))}
              </StyledRow>
            ))}
          </StyledGrid>
          <PTFooter>
            <PTFooterLeft>
              <PTAlert inAlert={ptState.inAlert}>
                <PTAlertIconWrap>
                  <WarningIcon fontSize="large" />
                </PTAlertIconWrap>
                <PTAlertMessageWrap>
                  <StyleH6Header>
                    {intl.formatMessage({ id: "pressureTest.AlertTitle" })}
                  </StyleH6Header>
                  <PTAlertMessage>
                    {intl.formatMessage({ id: "pressureTest.AlertMsg" })}
                  </PTAlertMessage>
                </PTAlertMessageWrap>
              </PTAlert>
            </PTFooterLeft>
            <PTFooterRight>
              <SecondaryButtonWithIcon
                onClick={() => {
                  onCtaGoBack();
                }}
              >
                {intl.formatMessage({ id: "pressureTest.actions.goBack" })}
                <ArrowForwardIcon/>
              </SecondaryButtonWithIcon>
              <ConfirmButtonWithIcon
                onClick={() => {
                  onCtaConfirm();
                }}
              >
                {intl.formatMessage({id: "annualBudget"})}
                <ArrowForwardIcon />
              </ConfirmButtonWithIcon>
            </PTFooterRight>
          </PTFooter>
        </StyledPT>
      </StyledPressureTestModal>
    </CenterModal>
  );
};

export default PressureTestModal;
