import {CGAPEvent, CGAPEventFieldType, CheckboxOption} from "../../types/annual-planner";
import {CGCAddonsConfig, CGCSelectOption, CGCTierConfig} from "../../types/common";
import {LocalizedListState} from "../../util/localized-options";

export interface CGAPEventModalState {
    isVisible: boolean;
    sourceEvent?: CGAPEvent;
    isAdding: boolean;
}

export function makeModalState(props: Record<string, boolean | CGAPEvent> = {}): CGAPEventModalState {
    return Object.assign({isVisible: false, isAdding: false}, props);
}

// Reducer & Actions ---------------------------------------

export interface CGAPEventFormState {
    event: CGAPEvent | undefined;
    tierConfig: CGCTierConfig | undefined;
    addonsConfig: CGCAddonsConfig | undefined;
    canSubmit: boolean;
    tierDisclaimer: string;
    clientSegmentOptions: CGCSelectOption[];
    cityOptions: CGCSelectOption[];
    addonOptions: CheckboxOption[];
}

export function getInitialEventFormState(): CGAPEventFormState {
    return {
        event: undefined,
        tierConfig: undefined,
        tierDisclaimer: '',
        addonsConfig: undefined,
        canSubmit: false,
        clientSegmentOptions: [],
        cityOptions: [],
        addonOptions: []

    }
}

export enum EventFormActionType {
    RESET = "RESET",
    UPDATE_EVENT = "UPDATE_EVENT",
    UPDATE_CLIENT_SEGMENT_OPTS = "UPDATE_CLIENT_SEGMENT_OPTS",
}

export interface EventFormResetAction {
    type: typeof EventFormActionType.RESET;
}

export interface EventFormUpdateEventAction {
    type: typeof EventFormActionType.UPDATE_EVENT;
    payload: CGAPEvent | undefined;
}


export interface EventFormUpdateClientSegmentOptsAction {
    type: typeof EventFormActionType.UPDATE_CLIENT_SEGMENT_OPTS;
    payload: CGCSelectOption[];
}

export type GCAPEventFormStateActions =
    | EventFormResetAction
    | EventFormUpdateClientSegmentOptsAction
    | EventFormUpdateEventAction;

export interface EventFormContextProps {
    eventFormState: CGAPEventFormState;
    eventFormDispatch: (dispatch: GCAPEventFormStateActions) => void;
    updateEventField: ( fieldName: string, newValue: CGAPEventFieldType) => void,
    localizedLists: LocalizedListState,
}

