import styled from "styled-components";
import { deviceWidths, StyleGridGap } from "../../styles/theme";

export const ActionRibbonBottomStyle = styled.div`
  display: grid;
  ${StyleGridGap}
  align-items: center;
  justify-items: end;
  justify-content: end;

  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "actions actions";
  margin: 20px 0;

  @media screen and ${deviceWidths.tablet} {
    grid-template-columns: repeat(2, 250px);
    grid-template-areas: "action1 action2";
    margin: 40px 0;
  }
`;
