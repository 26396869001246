import React, { ReactElement } from "react";
import { StyledAlertBar, StyledAlertMessage } from "./style";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";

export type CGAlertBarProps = {
  message: string;
  onDismiss: () => void;
};

const CGAlertBar = ({
  message,
  onDismiss,
}: CGAlertBarProps): ReactElement => {

  return (
    <StyledAlertBar isVisible={!!message?.length}>
      <WarningIcon fontSize="large" />
      <StyledAlertMessage>{message}</StyledAlertMessage>
      <CancelIcon
        onClick={() => {
          onDismiss();
        }}
      />
    </StyledAlertBar>
  );
};

export default CGAlertBar;
