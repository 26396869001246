import {createContext} from "react";
import {EventFormContextProps, getInitialEventFormState} from "./types";

export const budgetPlanningContext = createContext<EventFormContextProps>({
    eventFormState: getInitialEventFormState(),
    eventFormDispatch: () => void(0),
    updateEventField: () => void(0),
    localizedLists: {
        attendeesOptions: [],
        daysOptions: [],
        eventFormatOptions: [],
        eventTypeOptions: [],
        months: [],
        monthsOptions: [],
        tierNames: [],
        tierNamesOptions: [],
        updateDaysConfig: () => void(0),
        updateWebinarEnabled: () => void(0),
        updateAttendees: () => void(0),
    }
});

const {Provider} = budgetPlanningContext;
export {Provider as BudgetPlanningContext}
