import {
  CGAPConfig,
  CGAPEvent,
  CheckboxOption,
} from "../../types/annual-planner";
import { CGAPEventFormState } from "./types";
import _cloneDeep from "lodash.clonedeep";
import _get from "lodash.get";
import {
  getCommonTierConfig,
  getTierAddonsConfig,
  getTierCostsDisclaimer,
} from "../../util/config";
import { IntlShape } from "react-intl";
import {adjustAddonsForTier, updateEventCost} from "../../util/formulas";
import { CGCCostsDays } from "../../types/common";
import {EventFormat} from "../../constants";

export function calculateCanSubmit(event: CGAPEvent | undefined): boolean {
  return !!event?.title?.length;
}

/*
 * This is a function reducer for *just* updating events. The main reducer is in the event-modal FC
 */
export function updateEventReducer(
  config: CGAPConfig,
  formState: CGAPEventFormState,
  passedEvent: CGAPEvent | undefined | null,
  intl: IntlShape
): CGAPEventFormState {
  const newEventFormState = _cloneDeep(formState);

  if (passedEvent) {
    const newEventTier = _get(passedEvent, "tier");
    const prevEventTier = _get(formState, "event.tier");
    // const isChangingTier: boolean = newEventTier !== prevEventTier;
    newEventFormState.event = passedEvent;

    // for a currently unknown reason, the Date object gets converted to a string when passed
    if(typeof newEventFormState.event.date === "string") {
      newEventFormState.event.date = new Date(newEventFormState.event.date);
    }

    // newEventFormState.event = applyBusinessRulesToEvent(
    //   config,
    //   passedEvent,
    //   isChangingTier,
    //   isChangingTier
    // );
    // newEventTier = _get(newEventFormState, "event.tier"); // the tier may have changed above

    if (newEventFormState.event.tier !== 3 && newEventFormState.event.format === EventFormat.Webinar) {
      newEventFormState.event.format = EventFormat.Virtual;
    }

    const newEventType = _get(newEventFormState, "event.format");
    const tierChanged = newEventTier !== prevEventTier;
    const typeChanged =
      _get(formState, "type") !== newEventType;

    if (tierChanged) {
      newEventFormState.tierConfig = getCommonTierConfig(config, newEventTier);
    }

    if (tierChanged || typeChanged) {
      newEventFormState.addonsConfig = getTierAddonsConfig(
        config,
        newEventTier,
        newEventType
      );

      newEventFormState.tierDisclaimer = getTierCostsDisclaimer(
        config,
        newEventTier,
        newEventType
      );

      if (newEventFormState.addonsConfig && newEventFormState?.event) {
        const { addonsConfig } = newEventFormState;

        newEventFormState.addonOptions = Object.keys(addonsConfig).map(
          (addonKey): CheckboxOption => {
            //  https://stackoverflow.com/a/40350534 for the ! operator
            const addon = addonsConfig[addonKey];

            return {
              label: addon.label,
              value: addon.id,
              disclaimer: addon.disclaimer,
              isDisabled: addon.isDisabled,
            };
          }
        );

        newEventFormState.event.addons = adjustAddonsForTier(
          addonsConfig,
          newEventFormState.event.addons
        );
      } else {
        newEventFormState.addonOptions = [];
      }

      // TODO: the following assignment and block are replicated in a few places
      //       probably be good to move it off into its own helper fn
      const daysConfig: CGCCostsDays | undefined = newEventType
        ? newEventFormState?.tierConfig?.virtual.days
        : newEventFormState?.tierConfig?.inPerson.days;

      if (daysConfig) {
        const minDays = daysConfig.min ?? daysConfig.default;
        const maxDays = daysConfig.max ?? daysConfig.default;

        if (daysConfig.isFixed) {
          newEventFormState.event.dayCount = daysConfig.default;
        } else if (
          newEventFormState.event.dayCount < minDays ||
          newEventFormState.event.dayCount > maxDays
        ) {
          newEventFormState.event.dayCount = daysConfig.default;
        }
      }

      if (newEventType === EventFormat.InPerson) {
        const locationsConfig =
          newEventFormState?.tierConfig?.inPerson.locations;

        if (locationsConfig) {
          newEventFormState.cityOptions = Object.keys(locationsConfig).map(
            (location) => ({
              label: intl.formatMessage({ id: `location.${location}` }),
              value: location,
            })
          );
        }
      }
    }

    newEventFormState.event = updateEventCost(config, newEventFormState.event);
  } else {
    newEventFormState.event = undefined;
    newEventFormState.tierConfig = undefined;
    newEventFormState.addonsConfig = undefined;
    newEventFormState.addonOptions = [];
    newEventFormState.cityOptions = [];
  }

  newEventFormState.canSubmit = calculateCanSubmit(newEventFormState.event);
  return newEventFormState;
}
