import styled from "styled-components";
import { StyleFontRoman, StyleH2Header, ThemeColors } from "./theme";

export const StyledModal = styled.div`
  background-color: ${ThemeColors.White};
  padding: 3em;
`;

export const StyledModalHeader = styled(StyleH2Header)`
  padding: 1rem;
  color: ${ThemeColors.White};
  background-color: ${ThemeColors.LightOcean};
`;

export const StyledModalDescription = styled.div`
  ${StyleFontRoman}
  font-size: 17px;
  line-height: 1.3em;
  font-weight: normal;
  margin: 1.5rem 1rem;
  max-width: 700px;
`;

export const StyledModalActions = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin: 3em 0 0 0;
`;
