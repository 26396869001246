import styled from "styled-components";
import { StyledModal } from "../../styles/modals";
import {
  deviceWidths,
  StyleFontHeavy,
  StyleFontMedium,
  StyleFontRoman,
  ThemeColors,
} from "../../styles/theme";

export const columnPadding = 3;

export const StyledPT = styled.div`
  width: 80vw;
`;

export const StyledPTTitle = styled.div`
  padding: 1rem;
  background-color: rgba(4, 220, 255, 0.25);
  margin: 1rem 0;
`;

export interface StyledCellProps {
  columns: number;
  inAlert?: boolean;
}

export const StyledPressureTestModal = styled(StyledModal)`
  grid-template-rows: auto;
`;

export const StyledGrid = styled.div``;
export const StyledRow = styled.div<StyledCellProps>`
  margin-top: -2px; // Collapse the borders between the rows
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);

  &:nth-child(even) {
    background-color: ${ThemeColors.Black03};
  }

  border: 2px solid
    ${(props) => (props.inAlert ? ThemeColors.Sapphire : "transparent")};
`;
export const StyledHeaderRow = styled(StyledRow)`
  border-bottom: 2px solid black;
`;

export const StyledCell = styled.div`
  ${StyleFontMedium}

  color: ${ThemeColors.Black};
  display: grid;
  align-items: center;
  margin-bottom: 3px;
  padding: ${columnPadding}px;
  overflow: hidden;
  width: 55px;
  font-size: 12px;

  @media screen and ${deviceWidths.tablet} {
    width: 80px;
    padding: ${columnPadding * 2}px;
  }

  @media screen and ${deviceWidths.desktop} {
    width: 80px;
    font-size: 14px;
    letter-spacing: 0.35px;
    padding: ${columnPadding * 3}px;
  }

  @media screen and ${deviceWidths.max} {
    width: 110px;
    padding: ${columnPadding * 4}px;
  }
`;
export const StyledHeaderCell = styled(StyledCell)`
  align-items: start;
  margin: 0;
  ${StyleFontHeavy}
  text-transform: uppercase;
  color: ${ThemeColors.Black50};
  font-size: 12px;

  @media screen and ${deviceWidths.tablet} {
    
  }
  @media screen and ${deviceWidths.desktop} {
    font-size: 14px;
    letter-spacing: 0.35px;
   
  }

  @media screen and ${deviceWidths.max} {
    
  }
`;

export interface PTAlertProps {
  inAlert?: boolean;
}
export const PTAlert = styled.div<PTAlertProps>`
  opacity: ${(props) => (props.inAlert ? "1" : "0")};
  background-color: ${ThemeColors.Sapphire};
  color: ${ThemeColors.White};
  padding: 1rem;

  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  grid-gap: 15px;
`;

export const PTAlertIconWrap = styled.div``;
export const PTAlertMessageWrap = styled.div``;

export const PTAlertMessage = styled.div`
  ${StyleFontRoman}
  font-size: 10px;
  line-height: 16px;
  margin-top: 0.65em;

  @media screen and ${deviceWidths.max} {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const PTFooter = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 7fr 5fr;
  grid-gap: 10px;
`;

export const PTFooterLeft = styled.div`
  justify-self: start;
  align-self: end;
`;

export const PTFooterRight = styled.div`
  justify-self: end;
  align-self: end;
  display: grid;
  justify-content: end;
  grid-template-areas: "back confirm";
  grid-auto-columns: min-content;
  grid-gap: 15px;
  width: 100%;
`;
