import { useContext, useEffect, useState } from "react";
import { store } from "../store";
import { getAvailableClientSegments } from "./index";

export function useAvailableClientSegments(): string[] {
  const { state } = useContext(store);
  const [availableClientSegments, setAvailableClientSegments] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (state && state.config && state.planOptions?.clientGroup) {
      setAvailableClientSegments(
        getAvailableClientSegments(state.config, state.planOptions?.clientGroup)
      );
    }
  }, [state?.config, state?.planOptions?.clientGroup]);

  return availableClientSegments;
}
