import styled from "styled-components";

export const StyledPlanningView = styled.div``;
export const StyledScreenView = styled.div`
  @media print {
    display: none;
  }
`;
export const StyledPrintView = styled.div`
  @media screen {
    display: none;
  }
  @media print {
    width: 11in;
  }
`;
