import {
  CGAPClientGroupOption,
  CGAPConfig,
  CGAPEvent,
  CGAPEventsSummary, CGAPPlanOptions,
} from "../types/annual-planner";
import {CGAPPlannerView} from "../constants";

export enum AppActionType {
  ADD_EVENT = "ADD_EVENT",
  DELETE_EVENT = "DELETE_EVENT",
  IMPORT_STATE = "IMPORT_STATE",
  INIT_FROM_LANDING = "INIT_FROM_LANDING",
  SET_BUDGET = "SET_BUDGET",
  SET_CONFIG = "SET_CONFIG",
  SET_CLIENT_GROUP = "SET_CLIENT_GROUP",
  SET_LOADING = "SET_LOADING",
  RESET_ERROR = "RESET_ERROR",
  SET_ERROR = "SET_ERROR",
  RESET_EVENTS = "RESET_EVENTS",
  SET_EVENTS = "SET_EVENTS",
  SET_EVENT_SUMMARY = "SET_EVENT_SUMMARY",
  SET_PLANNING_VIEW = "SET_PLANNING_VIEW",
  UPDATE_EVENT = "UPDATE_EVENT",
}

// export interface EventInpersonBlindAddAction {
//   type: typeof AppActionType.EVENT_INPERSON_BLIND_ADD;
//   payload: number;
// }
//
// export interface EventInpersonBlindRemoveAction {
//   type: typeof AppActionType.EVENT_INPERSON_BLIND_REMOVE;
//   payload: number;
// }
//
// export interface EventVirtualBlindAddAction {
//   type: typeof AppActionType.EVENT_VIRTUAL_BLIND_ADD;
//   payload: number;
// }
//
// export interface EventVirtualBlindRemoveAction {
//   type: typeof AppActionType.EVENT_VIRTUAL_BLIND_REMOVE;
//   payload: number;
// }

export interface ImportStateAction {
  type: typeof AppActionType.IMPORT_STATE;
  payload: CGAPAppState;
}

export interface InitFromLandingAction {
  type: typeof AppActionType.INIT_FROM_LANDING;
  payload: {
    planOptions: CGAPPlanOptions;
    events: CGAPEvent[];
  };
}

export interface SetConfigAction {
  type: typeof AppActionType.SET_CONFIG;
  payload: CGAPConfig;
}

export interface SetLoadingAction {
  type: typeof AppActionType.SET_LOADING;
  payload: boolean;
}

export interface ResetErrorsAction {
  type: typeof AppActionType.RESET_ERROR;
}

export interface SetClientGroupAction {
  type: typeof AppActionType.SET_CLIENT_GROUP;
  payload: CGAPClientGroupOption;
}

export interface SetErrorsAction {
  type: typeof AppActionType.SET_ERROR;
  payload: string;
}

export interface ResetEventsAction {
  type: typeof AppActionType.RESET_EVENTS;
}

export interface SetBudgetAction {
  type: typeof AppActionType.SET_BUDGET;
  payload: number;
}

export interface SetEventsAction {
  type: typeof AppActionType.SET_EVENTS;
  payload: CGAPEvent[];
}

export interface SetEventsSummaryAction {
  type: typeof AppActionType.SET_EVENT_SUMMARY;
  payload: CGAPEventsSummary;
}

export interface SetPlanningViewAction {
  type: typeof AppActionType.SET_PLANNING_VIEW;
  payload: CGAPPlannerView;
}

export interface AddEventAction {
  type: typeof AppActionType.ADD_EVENT;
  payload: CGAPEvent;
}
export interface UpdateEventAction {
  type: typeof AppActionType.UPDATE_EVENT;
  payload: CGAPEvent;
}

export interface DeleteEventAction {
  type: typeof AppActionType.DELETE_EVENT;
  payload: CGAPEvent;
}

export type GCAPAppStateActions =
  | AddEventAction
  | DeleteEventAction
  | ImportStateAction
  | InitFromLandingAction
  | SetClientGroupAction
  | SetConfigAction
  | SetLoadingAction
  | ResetEventsAction
  | SetErrorsAction
  | ResetErrorsAction
  | SetBudgetAction
  | SetEventsAction
  | SetEventsSummaryAction
  | SetPlanningViewAction
  | UpdateEventAction;

export enum CGAPBudgetType {

}

export interface CGAPAppState {
  planOptions: CGAPPlanOptions;
  events: CGAPEvent[];
  config: CGAPConfig;
  planningView: CGAPPlannerView
  planningYear: number;
  error: string;
  eventsSummary: CGAPEventsSummary;
  isError: boolean;
  isLoading: boolean;
}

export interface CGAPStore {
  state?: CGAPAppState;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  dispatch: any;
}

export class CGAPEventError extends Error {
  _event: CGAPEvent;

  constructor(message: string, event: CGAPEvent) {
    super(message);
    this._event = event;
  }

  get event(): CGAPEvent {
    return this._event;
  }
}
