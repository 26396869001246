import styled from "styled-components";
import {StyleFontBook, StyleFontHeavy, ThemeColors} from "../../../../styles/theme";

interface StyledCalendarEventProps {
  tier: number;
}

export const StyledEventChip = styled.div`
  cursor: pointer;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 8px;
  padding: 4px;
  background-color: ${ThemeColors.White};
  @media print {
    border: 1px solid gray
  }
`;

export const StyledTierCell = styled.div<StyledCalendarEventProps>`
  width: 27px;
  height: 27px;
  color: ${ThemeColors.White};
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${(props) => {
    switch(props.tier) {
      case 1:
        return ThemeColors.Sapphire;
      case 2:
        return ThemeColors.Gray66;
      case 3:
        return ThemeColors.Mustard;
    }
  }};

  ${StyleFontHeavy};
  font-size: 10px;
  line-height: 10px;
`;
export const StyledContentCell = styled.div`
  ${StyleFontHeavy};
  font-size: 10px;
  line-height: 28px;
  color: ${ThemeColors.CGBlack};
`;

export const StyledEventDate = styled.div`
  ${StyleFontBook}
`;

export const StyledEventName = styled.div`
  ${StyleFontHeavy};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

