import React, { ReactElement, useContext } from "react";
import {
  HeroLeft,
  HeroRight,
  StyledBaseLayout,
  StyledCGHeader,
  StyledCGHeaderInner,
  StyledTitleHero,
  StyledTitleHeroInner,
} from "./style";
import { ReactComponent as SVGLogo } from "../../assets/logo.svg";
import {
  StyledH1Header,
  StyleH4Header,
  StyledSiteMaxWidth,
} from "../../styles/theme";
import { FormattedMessage } from "react-intl";
import { store } from "../../store";

type LayoutProps = {
  children: ReactElement | ReactElement[];
};

const BaseLayout = ({ children }: LayoutProps): ReactElement => {
  const { state } = useContext(store);

  return (
    <StyledBaseLayout>
      <StyledCGHeader>
        <StyledSiteMaxWidth>
          <StyledCGHeaderInner>
            <SVGLogo />
          </StyledCGHeaderInner>
        </StyledSiteMaxWidth>
      </StyledCGHeader>
      <StyledTitleHero>
        <StyledSiteMaxWidth>
          <StyledTitleHeroInner>
            <HeroLeft>
              <StyleH4Header>
                <FormattedMessage id="hero.smallTitle" />
              </StyleH4Header>
              <StyledH1Header>
                <FormattedMessage id="hero.largeTitle" />
              </StyledH1Header>
            </HeroLeft>
            <HeroRight>
              <StyleH4Header>
                {state?.planOptions?.baseYear && (
                  <FormattedMessage
                    id="baseYear"
                    values={{ baseYear: state.planOptions.baseYear }}
                  />
                )}
              </StyleH4Header>
            </HeroRight>
          </StyledTitleHeroInner>
        </StyledSiteMaxWidth>
      </StyledTitleHero>

      {children}
    </StyledBaseLayout>
  );
};

export default BaseLayout;
