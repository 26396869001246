import styled from "styled-components";
import {
  StyleFontMedium,
  StyleFontRoman,
  StyleGridHeaderUnderline,
  StyleRowAlternateColors,
  ThemeColors,
} from "../../../styles/theme";

export const cellPadding = 25;

export const StyledSegmentShareTable = styled.div`
  @media print {
    page-break-inside: avoid;
  }
`;

export const StyledSegmentShareRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  column-gap: 2rem;
  ${StyleRowAlternateColors};
  -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
  page-break-inside: avoid; /* Firefox */
  break-inside: avoid; /* IE 10+ */
`;
export const StyledSegmentShareHeaderRow = styled(StyledSegmentShareRow)`
  grid-template-areas:
    ". type1 type1 type2 type2"
    "groupLabel type1cost type1perc type2cost type2perc";
  grid-template-rows: auto;

  ${StyleGridHeaderUnderline}
  @media print {
    border: none;
  }
`;
export const StyledSegmentShareCell = styled.div`
  padding: ${cellPadding}px;
  ${StyleFontMedium};
  @media print {
    padding: 5px;
    border-top: 1px solid lightgray;
  }
`;
export const StyledSegmentShareHeaderCell = styled(StyledSegmentShareCell)`
  ${StyleFontRoman};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;

  padding: 1rem;
`;

export const StyledSegmentShareTypeHeaderCell = styled(
  StyledSegmentShareHeaderCell
)`
  text-align: center;

  line-height: 1.25rem;
  border-bottom: 1px solid ${ThemeColors.GrayE5};
`;
